import { useMemo } from 'react'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { ExportTemplate } from 'src/context/ExportContext/ExportTypes'
import { useGetProjectSystemTypeGroups } from 'src/query/systemTypeGroups'
import { ISystemTypeGroup } from 'src/service/OrgTypes'
import { addMetaDataInfo } from 'src/utility/exportUtils'
import { actionListExportTemplate } from './ActionExports'
import { deliveryOldExport } from './DeliveryExports'
import { keypointListExportTemplate } from './KeypointExports'
import { milestoneListExportTemplate } from './MilestoneExports'

const deleteColumns = (
  exportTemplate: ExportTemplate,
  columnsToDelete: {
    column: string
    row: string
  }[],
) => {
  const newTemplate = { ...exportTemplate }
  newTemplate.columns = newTemplate.columns.filter(
    (column) => !columnsToDelete.find((c) => c.column === column),
  )
  newTemplate.rows = newTemplate.rows.filter(
    (row) => !columnsToDelete.find((c) => c.row === row),
  )
  return newTemplate
}

const getMetaValues = (
  meta: ISystemTypeGroup[],
  name: string,
): IMetaField[] => {
  return meta.find((m) => m.domain === name)?.optional_fields || []
}

const getActionListExport = (meta: ISystemTypeGroup[]) => {
  return addMetaDataInfo(
    {
      ...actionListExportTemplate(),
      childProperty: 'tasks',
      parentProperty: 'record_id',
      parentName: 'deliveries',
      parentTableName: 'deliveries',
    } as ExportTemplate,
    getMetaValues(meta, 'Task'),
  )
}

const getDeliveryOldExport = (meta: ISystemTypeGroup[]) => {
  return addMetaDataInfo(
    {
      ...deleteColumns(deliveryOldExport as ExportTemplate, [
        {
          column: 'key_points',
          row: 'key_point.record_id||key_point.name',
        },
        {
          column: 'milestones',
          row: 'mile_stone.record_id||mile_stone.name',
        },
      ]),
      childTemplate: getActionListExport(meta),
      childProperty: 'deliveries',
      parentProperty: 'record_id',
      parentName: 'keypoints',
      parentTableName: 'Keypoints',
    } as ExportTemplate,
    getMetaValues(meta, 'Delivery'),
  )
}

const getKeypointListExport = (meta: ISystemTypeGroup[]) => {
  return addMetaDataInfo(
    {
      ...deleteColumns(keypointListExportTemplate as ExportTemplate, [
        {
          column: 'milestone',
          row: 'mile_stone.name',
        },
      ]),
      childTemplate: getDeliveryOldExport(meta),
      childProperty: 'key_points',
      parentProperty: 'record_id',
      parentName: 'milestone',
      parentTableName: 'Milestones',
    } as ExportTemplate,
    getMetaValues(meta, 'KeyPoint'),
  )
}

const getMilestoneListExport = (meta: ISystemTypeGroup[]) => {
  return addMetaDataInfo(
    {
      ...milestoneListExportTemplate,
      childTemplate: getKeypointListExport(meta),
    } as ExportTemplate,
    getMetaValues(meta, 'Milestone'),
  )
}

const planningMilestoneExport = (meta: ISystemTypeGroup[]) => {
  return {
    ...getMilestoneListExport(meta),
    geniusApiRequest: {
      url: '{organizationApiBaseUrl}projects/{projectId}/planning_export/milestone',
      method: 'POST',
    },
    runInBg: true,
    ignoreListColumns: true,
  }
}

const planningKeypointExport = (meta: ISystemTypeGroup[]) => {
  return {
    ...getKeypointListExport(meta),
    geniusApiRequest: {
      url: '{organizationApiBaseUrl}projects/{projectId}/planning_export/keypoint',
      method: 'POST',
    },
    runInBg: true,
    ignoreListColumns: true,
  }
}

const usePlanExport = () => {
  const { isLoading, data = [] } = useGetProjectSystemTypeGroups()

  const milestoneExport = useMemo(() => {
    return planningMilestoneExport(data)
  }, [data])

  const keypointExport = useMemo(() => {
    return planningKeypointExport(data)
  }, [data])

  return { isLoading, milestoneExport, keypointExport }
}

export default usePlanExport
