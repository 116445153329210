import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getDocumentHistory } from 'src/service/FileContainerService'
import FdvuSubcontractorService from 'src/subcontractor/service/SubcontractorService'

export const fileContainerHistoryKey = 'file-container-history'
export const useFileContainerHistory = (documentId: number) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [fileContainerHistoryKey, projectId, documentId],
    queryFn: () => getDocumentHistory(projectId, documentId),
  })
}

export const useFileContainerHistorySubContractor = (
  documentId: number,
  projectId: number,
  options: { enabled: boolean },
) => {
  return useQuery({
    ...options,
    queryKey: [fileContainerHistoryKey, projectId, documentId],
    queryFn: () =>
      FdvuSubcontractorService.getDocumentHistorySubcontractor(
        projectId,
        documentId,
      ),
  })
}
