import { createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { KeyboardEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from 'src/document/components/Folders/CreateFolderModal/FolderMetadataRow'
import SimpleTable from 'src/ui-elements/Table/SimpleTable'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import { filterType } from 'src/ui-elements/list/ListTypes'

const EnumMetaDataOptions = ({
  options,
  addEnumValue,
  removeEnumValue,
  bulkRemoveEnumValues,
}: {
  options: Option[]
  addEnumValue?: (value: string) => void
  removeEnumValue?: (option: Option) => void
  bulkRemoveEnumValues?: (ids: string[]) => void
}) => {
  const { t } = useTranslation()
  const columnHelper = createColumnHelper<Option>()
  const [inputState, setInputState] = useState('')

  const columns = [
    columnHelper.accessor('value', {
      id: 'value',
      size: 150,
      cell: (props) => <span className="px-1">{props.getValue()}</span>,
      header: (props) => (
        <TableColumn column={props.column} table={props.table} />
      ),
      meta: {
        field: 'options',
        filterType: filterType.TEXT,
        name: capitalize(t('name')),
      },
    }),
  ]
  const handleKeyDownEnumInput: KeyboardEventHandler<HTMLDivElement> = (
    event,
  ) => {
    if (!addEnumValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        addEnumValue(inputState)
        setInputState('')
        event.preventDefault()
    }
  }

  return (
    <>
      <SimpleTable
        data={options}
        columns={columns}
        name="EnumOptionTable"
        onDeleteClick={removeEnumValue}
        onBulkDelete={bulkRemoveEnumValues}
        selectable
      />
      {addEnumValue && (
        <div className="flex flex-row items-end">
          <Input
            label={t('add_option')}
            placeholder={''}
            type={'text'}
            block={true}
            onChange={(e) => e.target.value && setInputState(e.target.value)}
            value={inputState}
            onKeyDown={handleKeyDownEnumInput}
          />
          <Button
            disabled={inputState.length === 0}
            type={ButtonType.SECONDARY}
            className="h-1/2"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              addEnumValue(inputState)
              setInputState('')
            }}
          >
            {capitalize(t('add'))}
          </Button>
        </div>
      )}
    </>
  )
}

export default EnumMetaDataOptions
