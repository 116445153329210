import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import useProjectId from 'src/components/hooks/useProjectId'
import { savedTableConfigQueryKey } from 'src/query/tableConfig'

const channelName = 'invalidate-tab-config'

const useReloadTableConfig = (tableName: string) => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: savedTableConfigQueryKey(projectId, tableName),
    })
  }, [projectId, queryClient, tableName])

  const invalidateTableConfig = (key: string) => {
    const channel = new BroadcastChannel(channelName)
    channel.postMessage({ action: 'invalidate', key })
    channel.close()
  }

  useEffect(() => {
    const channel = new BroadcastChannel(channelName)
    channel.onmessage = (event) => {
      const { action, key: receivedKey } = event.data

      if (action === 'invalidate' && receivedKey === tableName) {
        invalidate()
      }
    }
    return () => channel.close()
  }, [invalidate, tableName])

  return invalidateTableConfig
}

export default useReloadTableConfig
