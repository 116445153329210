import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import DropdownArrowIcon from 'src/document/icons/DropdownArrowIcon'
import Spinner from 'src/ui-elements/loader/Spinner'
import { getRandomId } from 'src/utility/getRandomId'
import { capFirstLetter } from 'src/utility/utils'
import MaterialIcon from '../../icon/materialIcon'
import {
  IBaseDropDownItem,
  IDropdown,
  IInlineBaseProps,
  TDropDownType,
} from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineBorderComponent from './InlineBorderComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface SelectorInlineInputComponent<
  T extends TDropDownType,
  H extends IBaseDropDownItem<TDropDownType>,
> extends IInlineBaseProps {
  getItems?: () => Promise<H[]>
  getItemLabel?: (item?: H) => string | undefined
  getDropDownLabel?: (item?: H) => string | JSX.Element
  items?: H[]
  selectedId?: T
  onValueSubmitted?: (newValue?: T) => void
  validate?: (newValue?: T) => string | undefined
  initialItem?: H
  disableSearch?: boolean
  dependencies?: any[]
  cancelButton?: boolean
  nullable?: boolean
  listRender?: (item: IDropdown<T>) => JSX.Element
  inputColor?: (itemId?: T) => string
  inspectorPanel: boolean
  selectorHeight?: string
  placement?: 'top' | 'bottom'
  controlled?: boolean
  // If true, the dropdown items are rebuilt if the items prop is updated -> the internal items are overridden by new ones.
  buildDropdownItemsOnItemsChange?: boolean
  error?: string
  fullWidth?: boolean
}

const SelectorInlineInputComponent = <
  T extends TDropDownType = number,
  H extends IBaseDropDownItem<T> = { id?: T },
>({
  getItems,
  getItemLabel,
  getDropDownLabel,
  label,
  selectedId,
  validate,
  onValueSubmitted,
  labelWidth,
  inputWidth = 'w-96',
  initialItem,
  disabled = false,
  disableSearch = false,
  items = [],
  disableTooltip,
  dependencies = [],
  cancelButton,
  nullable = false,
  labelTextSize,
  listRender = undefined,
  inputColor = undefined,
  inspectorPanel,
  selectorHeight,
  placement = 'bottom',
  controlled = false,
  buildDropdownItemsOnItemsChange = false,
  error = '',
  fullWidth,
}: SelectorInlineInputComponent<T, H>) => {
  const { t } = useTranslation()
  const [allItems, setAllItems] = useState<IDropdown<T>[]>([])
  const [internalValue, setInternalValue] = useState(
    getItemLabel && initialItem ? getItemLabel(initialItem) : '',
  )
  const [internalSelectedId, setInternalSelectedId] = useState(selectedId)
  const [isFocusing, setIsFocusing] = useState(false)
  const [inputKey, setInputKey] = useState(getRandomId())
  const [errorMessage, setErrorMessage] = useState(error)
  const [internalItems, setInternalItems] = useState<IDropdown<T>[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [uniqueId] = useState(() => getRandomId())

  const divRef = useRef<HTMLDivElement>(null)
  const lastDeps = useRef<any[]>([])
  const [selectorPostion, setSelectorPosition] = useState(0)

  document.getElementById('metadatasection')?.addEventListener('scroll', () => {
    setIsFocusing(false)
  })

  document
    .getElementById('FixedPaneChildWrapper')
    ?.addEventListener('scroll', () => {
      setIsFocusing(false)
    })

  useEffect(() => setErrorMessage(error), [error])

  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const buildDropdownItems = useCallback(
    (items: H[]) => {
      if (!getItemLabel) return []

      const res: IDropdown<T>[] = []

      if (nullable) {
        res.push({
          id: undefined as T,
          name: `(${capFirstLetter(t('none'))})`,
          label: `(${capFirstLetter(t('none'))})`,
        })
      }

      for (const item of items) {
        const itemLabel = getItemLabel(item) ?? ''
        res.push({
          id: item.id as T,
          name: itemLabel,
          label: getDropDownLabel?.(item) ?? itemLabel,
        })
      }

      return res
    },
    [getItemLabel, nullable, t],
  )

  const getItemsInternal = useCallback(async () => {
    if (!getItems) return

    const items = await getItems()

    const res = buildDropdownItems(items)

    setAllItems(res)
    setInternalItems(res)
  }, [getItems, buildDropdownItems, ...dependencies])

  useEffect(() => {
    setInternalSelectedId(selectedId)
  }, [selectedId])

  useDidMountEffect(() => {
    if (getItemLabel) {
      setInternalValue(getItemLabel(initialItem) ?? '')
    }
  }, [initialItem])

  const scrollToElement = useCallback(
    (highlightedIndex: number) => {
      const divIdToShow = `${uniqueId}-${highlightedIndex}`
      const element = document.getElementById(divIdToShow)
      element?.scrollIntoView({
        block: 'nearest',
      })
    },
    [uniqueId],
  )

  useEffect(() => {
    if (isFocusing) {
      if (timeout.current) clearTimeout(timeout.current)

      const indexForSelectedItem = internalItems.findIndex(
        (element) => element.id === internalSelectedId,
      )
      setNewHiglightedIndex(indexForSelectedItem < 0 ? 0 : indexForSelectedItem)

      timeout.current = setTimeout(() => {
        scrollToElement(indexForSelectedItem)
      }, 25)
    }
  }, [isFocusing, internalItems, internalSelectedId, scrollToElement])

  useEffect(() => {
    const checkIfWeShouldReset = () => {
      if (dependencies.length === 0) return false

      if (dependencies.length !== lastDeps.current.length) {
        return false
      }

      for (let i = 0; i < dependencies.length; i++) {
        const oldDep = lastDeps.current[i]
        const newDep = dependencies[i]

        if (!oldDep) continue

        if (oldDep !== newDep) {
          return true
        }
      }
      return false
    }

    const reset = () => {
      setInternalSelectedId(undefined)
      setInternalValue(undefined)
      getItemsInternal()

      setInputKey(getRandomId())
    }

    if (checkIfWeShouldReset()) {
      reset()
    }

    lastDeps.current = [...dependencies]
  }, dependencies)

  const save = (newSelectedId?: T) => {
    setErrorMessage('')
    if (selectedId !== undefined && newSelectedId === selectedId) {
      setIsFocusing(false)
      return
    }
    if (!controlled) {
      setInternalSelectedId(newSelectedId)
    }
    if (validate) {
      const errorMessage = validate(newSelectedId)
      if (errorMessage?.length) {
        setErrorMessage(errorMessage)
        return
      }
    }
    setIsFocusing(false)
    setInputKey(getRandomId())
    if (newSelectedId !== selectedId) {
      if (onValueSubmitted) onValueSubmitted(newSelectedId)
    }
  }

  const filterMatchingItems = (filter?: string) => {
    const lowerCaseFilter = filter?.toLocaleLowerCase()
    setInternalValue(filter)
    const tmpItems = [...allItems]
    let filteredItems: IDropdown<T>[] = []
    if (lowerCaseFilter?.length) {
      filteredItems = tmpItems?.filter((value) =>
        value.name.toLowerCase().includes(lowerCaseFilter),
      )
      setInternalItems(filteredItems)
    } else {
      setInternalItems(tmpItems)
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHighlightedIndex(0)
    const newValue = event.target.value
    filterMatchingItems(newValue)
    setErrorMessage('')
  }

  const onSelectedItem = (id?: T, value?: string) => {
    if (!controlled) {
      setInternalValue(value)
      setInternalSelectedId(id)
      filterMatchingItems(value)
    } else {
      setInternalSelectedId(selectedId)
    }

    setIsFocusing(false)
    save(id)
    setInternalItems([...allItems])
  }

  useEffect(() => {
    scrollToElement(highlightedIndex)
  }, [scrollToElement, highlightedIndex])

  const setNewHiglightedIndex = (newIndex: number) => {
    setHighlightedIndex(newIndex)
  }

  const downButton = () => {
    if (!internalItems.length) return
    const newHiglighted = highlightedIndex + 1
    if (newHiglighted < internalItems.length) {
      setNewHiglightedIndex(newHiglighted)
    }
  }

  const upButton = () => {
    if (!internalItems.length) return
    const newHiglighted = highlightedIndex - 1
    if (newHiglighted > -1) {
      setNewHiglightedIndex(newHiglighted)
    }
  }

  const enterButton = () => {
    if (!isFocusing) {
      openMenu()
      return
    }

    if (!getItemLabel) return

    if (highlightedIndex === undefined) {
      setInternalValue(getItemLabel(initialItem))
      setInternalSelectedId(undefined)
      return
    }
    const higlightedItem = internalItems[highlightedIndex]
    if (!higlightedItem) {
      setInternalValue(getItemLabel(initialItem))
      setInternalSelectedId(undefined)
      return
    }
    onSelectedItem(higlightedItem.id, higlightedItem.name)
    setIsFocusing(false)
  }

  const openMenu = async (e?: any, adjustForDropDownIcon?: boolean) => {
    const target = e?.target.getBoundingClientRect()
    setSelectorPosition(target?.top - (adjustForDropDownIcon ? 5 : 0))
    setIsFocusing(true)
    if (internalItems.length && !buildDropdownItemsOnItemsChange) {
      return
    }
    if (items.length) {
      const builtItems = buildDropdownItems(items)
      setInternalItems(builtItems)
      setAllItems(builtItems)
      return
    } else if (buildDropdownItemsOnItemsChange) {
      setAllItems([])
      setInternalItems([])
    }
    setIsLoading(true)
    await getItemsInternal()
    setIsLoading(false)
  }

  const onFocus = (e: any) => {
    if (!disabled) openMenu(e)
  }

  const onToggleFocusArrow = (e: any) => {
    if (!disabled) {
      isFocusing ? setIsFocusing(false) : openMenu(e, true)
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      downButton()
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      upButton()
    } else if (e.key === 'Escape') {
      setIsFocusing(false)
    }
  }

  const onKeyPressCapture = (
    e: KeyboardEvent<HTMLInputElement | HTMLDivElement>,
  ) => {
    if (e.key === 'Enter') {
      enterButton()
    }
  }

  return (
    <InlineBaseComponent>
      {label && (
        <InlineInputLabelComponent
          label={label}
          labelWidth={labelWidth}
          labelTextSize={labelTextSize}
          disableTooltip={disableTooltip}
        />
      )}
      <InlineErrorMessageComponent errorMessage={errorMessage} />
      <InlineBorderComponent
        isFocusing={isFocusing}
        errorMessage={errorMessage}
        cursor={disableSearch ? 'cursor-pointer' : 'cursor-text'}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        <CloseClickOutside
          onClose={() => {
            setIsFocusing(false)
          }}
        >
          <div
            className={`${inputWidth} relative`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="px-1 flex justify-between items-center">
              {disableSearch ? (
                <div
                  className={`${inputWidth} overflow-hidden text-ellipsis whitespace-nowrap h-6 ${
                    disabled ? 'bg-white cursor-not-allowed' : ''
                  }`}
                  ref={divRef}
                  tabIndex={disabled ? -1 : 0}
                  onFocus={(e) => openMenu(e)}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    if (document.activeElement !== divRef.current)
                      divRef.current?.focus()
                    else openMenu(e)
                  }}
                  onKeyDown={onKeyDown}
                  onKeyPressCapture={onKeyPressCapture}
                >
                  {!internalValue?.length ? (
                    <span className="text-[#9DA3AE]">____</span>
                  ) : (
                    internalValue
                  )}
                </div>
              ) : (
                <input
                  className={`${
                    inputColor ? inputColor(internalSelectedId) : ''
                  } overflow-hidden text-ellipsis whitespace-nowrap w-full disabled:bg-white disabled:cursor-not-allowed`}
                  disabled={disabled}
                  key={inputKey}
                  value={internalValue}
                  placeholder="____"
                  onFocus={onFocus}
                  onKeyDown={onKeyDown}
                  onKeyPressCapture={onKeyPressCapture}
                  onChange={onChange}
                />
              )}
              <div className="group-hover:flex items-center hidden">
                {cancelButton && !disabled && internalSelectedId ? (
                  <MaterialIcon
                    icon={'cancel'}
                    className={
                      'cursor-pointer text-red-500 text-sm ml-0.5 mt-0.5'
                    }
                    onClick={() => onSelectedItem()}
                  />
                ) : null}
                <DropdownArrowIcon
                  isOpen={isFocusing}
                  onClick={disableSearch ? undefined : onToggleFocusArrow}
                />
              </div>
            </div>
            {isFocusing && internalItems.length > 0 && (
              <div
                style={{
                  top: inspectorPanel
                    ? `${`${selectorPostion + 22}px`}`
                    : undefined,
                }}
                className={`${inspectorPanel ? `fixed` : 'absolute'} ${
                  selectorHeight ? selectorHeight : 'max-h-48'
                } ${
                  placement === 'top' ? 'top-auto bottom-full' : 'top-6'
                } z-40 bg-white shadow-xl ${inputWidth} p-1 rounded max-h-96 overflow-y-auto border border-gray-300`}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  internalItems.map((item, index) => (
                    <div
                      id={`${uniqueId}-${index}`}
                      key={`${uniqueId}-${index}`}
                    >
                      <div
                        className={`whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer hover:bg-gray-100 p-1 mt-1 rounded ${
                          internalSelectedId === item.id ? 'bg-blue-100' : ''
                        } ${
                          index === highlightedIndex
                            ? ' border-gray-300'
                            : 'border-transparent'
                        } border`}
                        onClick={(e) => {
                          onSelectedItem(item.id, item.name)
                          e.nativeEvent.stopImmediatePropagation()
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                        onKeyPressCapture={onKeyPressCapture}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {listRender ? listRender(item) : item.label}
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </CloseClickOutside>
      </InlineBorderComponent>
    </InlineBaseComponent>
  )
}

export default SelectorInlineInputComponent
