import { FdvuSubcontractorRequest } from 'src/service/service-utils'
import { getFdvuBaseUrl } from 'src/utility/utils'
import { getAccessToken } from '../utils/AccessToken'

export class GenericSubcontractorService {
  protected getUrl = (...paths: (string | number)[]) => {
    const baseUrl = `${getFdvuBaseUrl()}subcontractor`
    return `${baseUrl}/${paths.join('/')}`
  }

  protected doRequest = <T = any>(
    {
      url,
      method,
      body,
      text,
      blob,
    }: {
      url?: string
      method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
      body?: any
      text?: boolean
      blob?: boolean
    } = { method: 'GET' },
  ) => {
    let tmpUrl = url
    if (!tmpUrl) tmpUrl = this.getUrl()
    const accessToken = getAccessToken()
    return FdvuSubcontractorRequest<T>(tmpUrl, accessToken, {
      method,
      body,
      text,
      blob,
    })
  }
}
