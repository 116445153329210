import { FC } from 'react'
import { useSystemSetting } from 'src/query/systems/systemSetting'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { syntaxLevelColor } from 'src/utility/syntaxUtils'

interface ISystemSyntaxHierarchyButtons {
  systemSettingId: number
  appliesToLevels: number[]
  setSelectedLevels?: (levels: number[]) => void
  small?: boolean
  disabled?: boolean
  disableLevels?: number[]
}

const SystemSyntaxHierarchyButtons: FC<ISystemSyntaxHierarchyButtons> = ({
  systemSettingId,
  appliesToLevels,
  setSelectedLevels,
  small,
  disabled,
  disableLevels,
}) => {
  const { data: systemSyntax } = useSystemSetting(systemSettingId)

  const onCategorySelect = (levelId: number, active: boolean) => {
    if (!appliesToLevels || !setSelectedLevels) return
    const levels = appliesToLevels
    if (active) {
      const index = levels.indexOf(levelId)
      levels.splice(index, 1)
    } else {
      levels.push(levelId)
    }
    setSelectedLevels(levels)
  }

  return (
    <div className={'flex'}>
      {systemSyntax
        ?.filter(
          (a) => a.syntax_string && (!small || appliesToLevels?.includes(a.id)),
        )
        ?.map((level, index) => {
          const active = appliesToLevels?.includes(level.id)
          const color = syntaxLevelColor(level.level)
          return (
            <div key={level.id} className="flex flex-row items-center">
              {index !== 0 && (
                <div className="text-d-fontgrey">{level.spacer}</div>
              )}
              <Button
                type={active ? ButtonType.PRIMARY : ButtonType.CUSTOM}
                className={small ? 'h-4' : 'h-8'}
                onClick={(e) => {
                  e.preventDefault()
                  onCategorySelect(Number(level.id), active)
                }}
                customColor={color}
                disabled={
                  (!active && disabled) ||
                  (disableLevels && disableLevels.includes(level.level ?? 0))
                }
              >
                {level.syntax_string}
              </Button>
            </div>
          )
        })}
    </div>
  )
}

export default SystemSyntaxHierarchyButtons
