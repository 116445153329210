import Add from '@icons/add.svg'
import Edit from '@icons/edit.svg'
import Upload from '@icons/upload.svg'
import DoNotDisturbOn from '@material-symbols/svg-500/rounded/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TestSystemGroupForm from 'src/components/system/test-system-groups/TestSystemGroupForm'
import TestSystemGroupInspectorPanel from 'src/components/system/test-system-groups/TestSystemGroupInspectorPanel'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { testSystemGroupExportTemplate } from 'src/export-templates/TestSystemGroupExport'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { useFilteredTestSystemGroups } from 'src/query/systems/testSystemGroups'
import { ITestSystemGroup } from 'src/service/OrgTypes.js'
import {
  deleteTestSystemGroup,
  editTestSystemGroup,
  massTestSystemGroup,
  testSystemGroupBulkDelete,
  testSystemGroupImportFromJson,
} from 'src/service/TestSystemGroupService.js'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { classNames } from 'src/utility/utils'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from '../../../document/components/FileUpload/selectors/DataSelectors'
import { testSystemGroupImport } from '../../../export-templates/TestSystemGroupImport'
import { IMPORT_TYPE } from '../../notifications/import/ImportNotificationItem'
import ImportModal from '../../upload-item/ImportModal'
import { useTestSystemGroupColumns } from './useTestSystemGroupColumns'

export const testSystemGroupsListImportColumns = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('title', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.defineDataField('planned_start', DATA_TYPES.date),
  DataSelectors.defineDataField('planned_end', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_start', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_end', DATA_TYPES.date),
  DataSelectors.getDataField(ImportFiledTypes.TEST_SYSTEM_GROUP_STATUSES),
  DataSelectors.getDataField(ImportFiledTypes.TEST_WORK_GROUP),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINE),
  DataSelectors.getDataField(ImportFiledTypes.RESPONSIBLE),
]

interface ITestSystemGroupsListProps {
  projectId: number
  workGroupId?: number
  statusId?: number
  onTestSystemChange?: () => void
  updateSelectedTestSystemGroups?: (ids: number[]) => void
  workableList?: boolean
  disciplineId?: number
}

const TestSystemGroupList = ({
  projectId,
  workGroupId,
  statusId,
  onTestSystemChange,
  updateSelectedTestSystemGroups,
  workableList = true,
  disciplineId,
}: ITestSystemGroupsListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }
  const userContext = useContext(UserContext)
  const writeAccess =
    userContext.actions.hasWriteAccess('object') && workableList

  const { t } = useTranslation()

  const [statusFilter, setStatusFilter] = useState<TableFilter>()
  const [disciplineFilter, setDisciplineFilter] = useState<TableFilter>()
  const [workGroupIdFilter, setWorkGroupId] = useState<TableFilter>()

  const [selectedTestSystemGroup, setSelectedTestSystemGroup] =
    useState<ITestSystemGroup>()
  const [showCreateModal, setShowCreateModal] = useState(false)

  const [showInspector, setShowInspector] = useState(false)
  const [selectedTestSystemGroupIds, setSelectedTestSystemGroupIds] = useState<
    number[]
  >([])
  const [showMassEditModal, setShowMassEditModal] = useState(false)
  const [showImportModal, setImportModal] = useState(false)

  const history = useHistory()
  const { addAlert } = useAlert()
  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const { confirmDelete } = useDeleteModal()

  const queryClient = useQueryClient()

  const reload = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['testSystemGroupsFiltered'] })
  }, [queryClient])

  useEffect(() => {
    if (!statusId) return
    setStatusFilter({ test_system_group_status: [statusId] })
  }, [statusId])

  useEffect(() => {
    if (!disciplineId) return
    setDisciplineFilter({ discipline: [disciplineId] })
  }, [disciplineId])

  useEffect(() => {
    if (!workGroupId) return
    setWorkGroupId({ test_work_group: [workGroupId] })
  }, [workGroupId])

  const { data: systemTypeGroup } =
    useSystemTypeGroupForDomain('TestSystemGroup')

  const onRowClick = (systemGroup: ITestSystemGroup) => {
    workGroupId
      ? history.push(
          `/systems/test_work_groups?testWorkGroupId=${workGroupId}&testSystemGroupId=${systemGroup.id}`,
        )
      : history.push(
          `/systems/test_system_groups?testSystemGroupId=${systemGroup.id}`,
        )
    onTestSystemChange?.()
  }

  const deleteSystemGroup = (systemGroup: ITestSystemGroup) => {
    deleteTestSystemGroup(systemGroup.id).then(() => {
      reload()
      onTestSystemChange?.()
    })
  }

  const onRemoveItemClick = (systemGroup: ITestSystemGroup) => {
    if (workGroupId) {
      editTestSystemGroup({
        id: systemGroup.id,
        test_work_group_id: null,
      }).then(() => {
        reload()
        onTestSystemChange?.()
      })
    } else {
      deleteSystemGroup(systemGroup)
    }
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const onDeleteItemClick = async (row: ITestSystemGroup) => {
    const itemIdnType = `${row.record_id} (${t('test_system_group')})`
    const itemName = `${row.record_id} - ${row.title}`
    const deleteConfirmed = await confirmDelete({ itemIdnType, itemName })
    if (deleteConfirmed) {
      onRemoveItemClick(row)
    }
  }

  const handlePreviewClick = (data: ITestSystemGroup) => {
    setSelectedTestSystemGroup(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedTestSystemGroup(undefined)
  }

  const onBulkDelete = (selectedItems: ITestSystemGroup[]) => {
    testSystemGroupBulkDelete(
      projectId,
      selectedItems.map((i) => i.id),
    ).then(() => {
      showAlert(
        'success',
        t('successfully_deleted'),
        t('selected_test_system_group_are_deleted'),
      )
      setSelectedTestSystemGroupIds([])
      reload()
      onTestSystemChange?.()
    })
  }

  const openMassEditModal = (selectedItems: ITestSystemGroup[]) => {
    if (selectedItems.length === 0) {
      showAlert('info', t('items_not_selected'), t('select_item_to_edit'))
      return
    }
    setSelectedTestSystemGroupIds(selectedItems.map((i) => i.id))
    setShowMassEditModal(true)
  }

  const closeMassEditModal = () => {
    setShowMassEditModal(false)
    setSelectedTestSystemGroupIds([])
    reload()
  }

  const { userDefinedAttributesColumns, columns } = useTestSystemGroupColumns({
    writeAccess: writeAccess,
    reload,
  })

  const bulkRemove = (selected: ITestSystemGroup[]) => {
    if (!workGroupId) return
    massTestSystemGroup(
      projectId,
      selected.map((i) => i.id),
      {
        test_work_group_id: null,
      },
    ).then(() => {
      reload()
      onTestSystemChange?.()
    })
  }

  return (
    <div className={styleClass.root}>
      <Table
        name={'testWorkGroupsList'}
        columns={columns}
        legacyColumns={userDefinedAttributesColumns}
        initialFilter={{
          ...statusFilter,
          ...disciplineFilter,
          ...workGroupIdFilter,
        }}
        onRowClick={onRowClick}
        useDataQuery={useFilteredTestSystemGroups}
        onPreviewClick={handlePreviewClick}
        setSelectedRowsParent={(select) =>
          updateSelectedTestSystemGroups?.(
            Object.keys(select).map((row) => +row),
          )
        }
        deleteIcon={!!workGroupId ? DoNotDisturbOn : undefined}
        onDeleteClick={writeAccess ? onDeleteItemClick : undefined}
        tableButtons={(selectedItems) => ({
          onBulkDelete:
            writeAccess && !workGroupId
              ? () => onBulkDelete(selectedItems)
              : undefined,
          exportData: testSystemGroupExportTemplate,
          customButtons:
            writeAccess && workableList
              ? [
                  <Button
                    key={'create-button'}
                    visible={!workGroupId}
                    onClick={() => setShowCreateModal(true)}
                    type={Button.ButtonType.PRIMARY}
                  >
                    <Add className="fill-white text-xl" />
                    {t('new_test_system_group')}
                  </Button>,
                  <Button
                    key={'import-button'}
                    title={t('import')}
                    visible={!workGroupId}
                    type={Button.ButtonType.SECONDARY}
                    className={'p-1 w-8 h-8'}
                    onClick={() => setImportModal(true)}
                  >
                    <Upload className="text-xl" />
                  </Button>,
                  <Button
                    key={'edit-button'}
                    title={t('edit')}
                    type={Button.ButtonType.SECONDARY}
                    disabled={!selectedItems.length}
                    onClick={() => openMassEditModal(selectedItems)}
                    className={'p-1 w-8 h-8'}
                  >
                    <Edit className={'text-xl'} />
                  </Button>,
                  <Button
                    key={'remove-button'}
                    visible={!!workGroupId}
                    type={Button.ButtonType.SECONDARY}
                    disabled={!workGroupId || !selectedItems.length}
                    onClick={() => bulkRemove(selectedItems)}
                    className={'p-1 w-8 h-8'}
                    title={t('remove_test_system_group')}
                  >
                    <DoNotDisturbOn className="fill-red text-xl" />
                  </Button>,
                ]
              : undefined,
        })}
      />

      <Modal
        show={showMassEditModal}
        closeModal={closeMassEditModal}
        title={t('edit_multiple_test_system_groups')}
        maxWidth={800}
      >
        <TestSystemGroupForm
          editingMode={true}
          projectId={projectId}
          testSystemGroupIds={selectedTestSystemGroupIds}
          closeModal={() => {
            closeMassEditModal()
            onTestSystemChange?.()
          }}
        />
      </Modal>

      {selectedTestSystemGroup?.id && showInspector && (
        <TestSystemGroupInspectorPanel
          projectId={projectId}
          testSystemGroupId={selectedTestSystemGroup?.id}
          open={showInspector}
          onClose={onCloseInspector}
          key={selectedTestSystemGroup?.id}
          onUpdate={() => {
            reload()
            onTestSystemChange?.()
          }}
        />
      )}
      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_object_group')}
        maxWidth={800}
      >
        <TestSystemGroupForm
          editingMode={false}
          projectId={projectId}
          closeModal={() => {
            closeCreateModal()
            onTestSystemChange?.()
          }}
        />
      </Modal>
      {showImportModal && (
        <ImportModal
          columns={testSystemGroupsListImportColumns}
          import_type={IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT}
          show={showImportModal}
          close={() => {
            setImportModal(false)
            reload()
          }}
          useDefinedField={systemTypeGroup?.optional_fields ?? []}
          importApi={testSystemGroupImportFromJson}
          modal_title={'upload_test_system_groups'}
          template={testSystemGroupImport}
          uploadUrl={`{ORG_URL}test_system_groups/validate_for_import_job`}
        />
      )}
    </div>
  )
}

export default TestSystemGroupList
