import Error from '@icons/error-fill.svg'
import { upperFirst } from 'lodash'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from 'src/utility/utils'

interface ConfirmModalBodyProps {
  confirmMessage?: string | JSX.Element
  iconColor?: string
}

interface IConfirmModalProps {
  show: boolean
  closeModal: () => void
  onSubmit: () => void
  maxWidth?: number
  title?: string | JSX.Element
  confirmMessage?: string | JSX.Element
  iconColor?: string
  danger?: boolean
}

export const ConfirmModalBody = ({
  confirmMessage,
  iconColor,
  children,
}: PropsWithChildren<ConfirmModalBodyProps>) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="m-4 flex flex-row items-center text-black text-sm">
        <Error
          className={classNames(
            'flex flex-none mr-2 text-2xl',
            iconColor || 'fill-red-danger',
          )}
        />
        {confirmMessage ?? upperFirst(t('confirm_change'))}
      </div>
      {children}
    </>
  )
}

/**
 * @deprecated Use useConfirmModal hook instead
 *
 * example:
 *
 *  import { useConfirmModal } from 'src/hooks/useConfirmModal')
 *
 *  const { confirm } = useConfirmModal()
 *
 *  const confirmed = await confirm({
      title,
      confirmMessage
    })

    if (confirmed) {
      // do action
    }
 */
const ConfirmModal = ({
  show,
  closeModal,
  maxWidth,
  title,
  confirmMessage,
  onSubmit,
  children,
  danger = false,
  iconColor,
}: PropsWithChildren<IConfirmModalProps>) => {
  const { t } = useTranslation()
  return (
    <Modal
      show={show}
      title={title ?? t('confirm')}
      closeModal={closeModal}
      size="w-1/3"
      maxWidth={maxWidth ?? 600}
    >
      <ConfirmModalBody confirmMessage={confirmMessage} iconColor={iconColor}>
        {children}
      </ConfirmModalBody>
      <ModalFooter>
        <Button onClick={closeModal} width={70}>
          {t('cancel')}
        </Button>
        <Button
          type={danger ? Button.ButtonType.DANGER : Button.ButtonType.PRIMARY}
          onClick={() => {
            onSubmit()
            closeModal()
          }}
          width={70}
        >
          {danger ? t('delete') : t('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal
