import {
  IDocumentHistory,
  IFileContainer,
} from 'src/document/types/IFileContainer'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import { FdvuSubcontractorRequest } from 'src/service/service-utils'
import { GenericSubcontractorService } from 'src/subcontractor/service/GenericSubcontractorService'
import { StatusEnum } from 'src/utility/statusEnums'
import { getOrganizationBaseUrl } from 'src/utility/utils'
import { getAccessToken } from '../utils/AccessToken'

class SubcontractorService extends GenericSubcontractorService {
  readonly authenticate = () => {
    return this.doRequest<boolean>({
      url: this.getUrl('authenticate'),
      method: 'GET',
    })
  }

  readonly getCurrentSubcontractor = () => {
    return this.doRequest<ISubcontractor>({
      url: this.getUrl('current'),
      method: 'GET',
    })
  }

  readonly getFileContainers = (hideInReview: boolean) => {
    const url =
      this.getUrl('file-container/assigned') + `?hideInReview=${hideInReview}`
    return this.doRequest<IFdvuFileContainer[]>({
      url,
      method: 'GET',
    })
  }

  readonly getFileContainer = (id: number) => {
    return this.doRequest<IFdvuFileContainer>({
      url: this.getUrl('file-container/file-container-id', id),
      method: 'GET',
    })
  }

  readonly downloadFile = (projectFileId: number) => {
    return this.doRequest({
      url: this.getUrl(`files/download/project-file-id/${projectFileId}`),
      method: 'GET',
      blob: true,
    })
  }

  readonly deleteFile = (projectFileId: number) => {
    return this.doRequest({
      url: this.getUrl(`files/delete/project-file-id/${projectFileId}`),
      method: 'DELETE',
      text: true,
    })
  }

  readonly updateDocumentStatus = (
    documentId: number,
    status: StatusEnum.OPEN | StatusEnum.DELIVERED,
  ): Promise<IFileContainer> => {
    const token = `Bearer ${getAccessToken()}`
    const mappedStatus = status === StatusEnum.DELIVERED ? 6 : 3
    return FdvuSubcontractorRequest(
      `${getOrganizationBaseUrl()}/file_containers/${documentId}/subcontractor`,
      token,
      { method: 'POST', body: { status: mappedStatus } },
    )
  }

  readonly getDocumentHistorySubcontractor = (
    projectId: number,
    documentId: number,
  ): Promise<IDocumentHistory[]> => {
    const token = `Bearer ${getAccessToken()}`

    return FdvuSubcontractorRequest(
      `${getOrganizationBaseUrl()}projects/${projectId}/subcontractor/file_containers/${documentId}/history`,
      token,
      { method: 'GET' },
    ).then((res) => res.history)
  }
}
export default new SubcontractorService()
