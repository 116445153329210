import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import useSocket from 'src/hooks/useSocket'
import BackgroundLoader from 'src/ui-elements/loader/BackgroundLoader'
import useAlert from 'src/ui-elements/toast/useAlert'

const BackgroundTaskLoader = ({
  jobName,
  successMessage,
  loadingMessage,
  onComplete,
}: {
  jobName: string
  successMessage?: string
  loadingMessage?: string
  onComplete?: () => void
}) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const [updating, setUpdating] = useState(false)
  const { addAlert } = useAlert()

  useSocket({
    channel: 'BackgroundJobChannel',
    topic: jobName,
    received: (data) => {
      if (data === 'started') setUpdating(true)
      else if (data === 'completed') {
        setUpdating(false)
        onComplete?.()
        if (successMessage)
          addAlert({
            type: 'success',
            title: t('success'),
            description: successMessage,
          })
      }
    },
    deps: [projectId],
  })

  return (
    <BackgroundLoader
      active={updating}
      loadingText={loadingMessage ? loadingMessage : t('loading')}
    />
  )
}

export default BackgroundTaskLoader
