import { capitalize } from 'lodash'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import { classNames } from 'src/utility/utils'

interface IInspectorSections {
  sections: IInspectorSection[]
  defaultIndex?: number
  noMainContent?: boolean
}

export interface IInspectorSection {
  name: string | JSX.Element
  icon: string | JSX.Element
  activeIcon?: string | JSX.Element
  content?: JSX.Element | React.ReactElement
  onAddItem?: () => void
  onClick?: () => void | Promise<void>
  isMaterialIcon?: boolean
  hidden?: boolean
  overflowVisible?: boolean
  doNotCapitalize?: boolean
}

const IInspectorSections = ({
  sections,
  defaultIndex,
  noMainContent,
}: IInspectorSections) => {
  const inspectorSectionHeight = 54
  const { height } = useWindowDimensions()
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const toggleExpand = (index: number, onClick?: () => void) => {
    const sectionIsAlreadyOpen = index === selectedIndex
    setSelectedIndex(sectionIsAlreadyOpen ? -1 : index)
    if (!sectionIsAlreadyOpen && onClick) {
      onClick()
    }
  }

  const loadSelected = useCallback(() => {
    if (
      defaultIndex !== undefined &&
      !isNaN(defaultIndex) &&
      defaultIndex < sections.length &&
      !sections[defaultIndex].hidden
    ) {
      const hiddenItems = sections
        .slice(0, defaultIndex)
        .filter((section) => section.hidden)
      const _selected = defaultIndex - hiddenItems.length
      setSelectedIndex(_selected)
      sections[_selected]?.onClick?.()
    }
  }, [defaultIndex])

  useEffect(() => {
    loadSelected()
  }, [loadSelected])

  useEffect(() => {
    if (selectedIndex !== -1) {
      const element = document.getElementById('metadatasection')
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }, [selectedIndex])

  const styleClass = {
    root: (
      first: boolean,
      selected: boolean,
      last: boolean,
      overflowVisible?: boolean,
    ) =>
      classNames(
        first && 'border-t',
        'border-b',
        'border-d-darkgrey',
        'flex',
        'flex-col',
        !selected && 'flex-none',
        selected && overflowVisible && 'overflow-visible',
        last && '-mb-1',
        'selectorScroll',
      ),
    greyText: (black?: boolean) =>
      classNames(
        black ? 'text-black' : 'text-d-fontgrey',
        'leading-snug',
        'inline-flex',
      ),
  }

  const getStyle = (index: number) => {
    if (!noMainContent || selectedIndex !== index) {
      return {}
    }

    return {
      maxHeight:
        height - ((sections.length - 1) * inspectorSectionHeight + 150),
    }
  }

  const SectionIcon = ({
    icon,
    isMaterialIcon,
  }: {
    icon: string | JSX.Element
    isMaterialIcon?: boolean
  }) => {
    if (typeof icon === 'string') {
      if (isMaterialIcon) {
        return <MaterialIcon icon={icon} className="mr-4 w-5 h-5" />
      } else {
        return <Icon icon={icon as Icons} className="mr-4 w-5 h-5" />
      }
    } else {
      return (
        <icon.type
          {...icon.props}
          className={`mr-4 w-5 h-5 fill-current ${icon.props.className || ''}`}
        />
      )
    }
  }

  return (
    <div className={`flex flex-col flex-1 ${noMainContent ? '-mt-4' : ''}`}>
      {sections
        .filter((section) => !section.hidden)
        .map((row, i) => (
          <div
            key={i}
            className={styleClass.root(
              i === 0,
              selectedIndex === i,
              sections.length - 1 === i,
              row.overflowVisible,
            )}
            style={{ ...getStyle(i) }}
            id={selectedIndex === i ? `metadatasection` : ''}
          >
            <div
              onClick={() => toggleExpand(i, row.onClick)}
              className={`flex items-center justify-between px-5 py-4 bg-white left-0 cursor-pointer sticky`}
            >
              <div className={styleClass.greyText(selectedIndex === i)}>
                <SectionIcon
                  icon={
                    selectedIndex === i ? row.activeIcon || row.icon : row.icon
                  }
                  isMaterialIcon={row.isMaterialIcon}
                />
                {row.doNotCapitalize || !(typeof row.name === 'string')
                  ? row.name
                  : capitalize(row.name)}
                {row.onAddItem && (
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      row?.onAddItem?.()
                    }}
                    className={
                      'bg-d-lightblue rounded-md text-xs flex items-center ml-2  px-2 cursor-pointer text-blue-root'
                    }
                  >
                    {t('add')}
                  </div>
                )}
              </div>

              <Icon
                className={`w-5 h-5 flex ${
                  selectedIndex === i ? 'transform -rotate-90' : ''
                }`}
                icon={Icons.ARROW_LEFT}
              />
            </div>
            {selectedIndex === i && (
              <div className={'max-h-full overflow-y-auto pb-4 px-5 '}>
                {row.content}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default IInspectorSections
