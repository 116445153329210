import { useRef, createContext, useState, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ShowConfirmationProps {
  title: string
  body: string | JSX.Element
  confirmText?: string
  cancelText?: string
  confirmButtonType?: ButtonType
  cancelButtonType?: ButtonType
  hideConfirmButton?: boolean
  disableConfirmButton?: boolean
  controlClose?: (arg: () => void) => void
  validateFormCallback?: () => Promise<boolean>
}

type ConfirmModalContextState = {
  showConfirmation: (props: ShowConfirmationProps) => Promise<boolean>
  isOpen: boolean
}

const ConfirmModalContext = createContext<ConfirmModalContextState>({
  showConfirmation: () => Promise.resolve(false),
  isOpen: false,
})

export const ConfirmModalContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  const [title, setTitle] = useState<string>()
  const [body, setBody] = useState<string | JSX.Element>()
  const [confirmText, setconfirmText] = useState<string>()
  const [cancelText, setCancelText] = useState<string>()
  const [confirmButtonType, setconfirmButtonType] = useState<ButtonType>()
  const [cancelButtonType, setCancelButtonType] = useState<ButtonType>()
  const [hideConfirmButton, setHideConfirmButton] = useState<boolean>()
  const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>()
  const [confirmCallback, setConfirmCallback] =
    useState<() => Promise<boolean>>()

  const [confirmClicked, setConfirmClicked] = useState(false)

  const resolver = useRef<(value: boolean) => void>()
  const controlCloseRef = useRef<(arg: () => void) => void>()

  const showConfirmation = ({
    title,
    body,
    confirmText = t('confirm'),
    cancelText = t('cancel'),
    confirmButtonType = ButtonType.PRIMARY,
    cancelButtonType = ButtonType.DEFAULT,
    hideConfirmButton = false,
    disableConfirmButton = false,
    controlClose,
    validateFormCallback,
  }: ShowConfirmationProps): Promise<boolean> => {
    setConfirmClicked(false)
    setShow(true)
    setTitle(title)
    setBody(body)
    setconfirmText(confirmText)
    setCancelText(cancelText)
    setconfirmButtonType(confirmButtonType)
    setCancelButtonType(cancelButtonType)
    setHideConfirmButton(hideConfirmButton)
    setDisableConfirmButton(disableConfirmButton)
    setConfirmCallback(() => validateFormCallback)
    controlCloseRef.current = controlClose
    return new Promise(function (resolve) {
      resolver.current = resolve
    })
  }

  const handleConfirm = async () => {
    if (confirmCallback) {
      const confirmed = await confirmCallback()
      if (!confirmed) return
    }
    setConfirmClicked(true)
    resolver.current?.(true)
    if (!controlCloseRef.current) setShow(false)
    else controlCloseRef.current(() => setShow(false))
  }

  const handleCancel = () => {
    resolver.current && resolver.current(false)
    setShow(false)
  }

  const state = {
    showConfirmation,
    isOpen: show,
  }
  return (
    <ConfirmModalContext.Provider value={state}>
      {children}
      <Modal
        show={show}
        closeModal={handleCancel}
        title={title || ''}
        size={'w-1/3'}
        maxWidth={600}
        noHorizontalScroll={true}
      >
        {typeof body === 'string' ? <p className="m-4">{body}</p> : body}
        <ModalFooter>
          <Button
            className={'min-w-70'}
            type={cancelButtonType}
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
          {!hideConfirmButton && (
            <Button
              className={'min-w-70'}
              type={confirmButtonType}
              onClick={handleConfirm}
              disabled={disableConfirmButton}
            >
              {confirmClicked ? <Spinner /> : confirmText}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </ConfirmModalContext.Provider>
  )
}

export default ConfirmModalContext
