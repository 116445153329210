import { SavedTableConfig } from '../ui-elements/Table/useTable'
import { formatQueryParams, getDomain } from '../utility/utils'
import { tableKeeperRequest } from './service-utils'

const domain = getDomain()

type Options = {
  // Get project-level shared configuration
  projectLevel: boolean
}

const sessionName = (projectId: number, tableName: string) =>
  `${projectId}-${tableName}`

export const getTableConfigFromSession = <T = unknown>(
  projectId: number,
  tableName: string,
): T | null => {
  const data = sessionStorage.getItem(sessionName(projectId, tableName))
  return data ? JSON.parse(data).data : null
}

export const getTable = <T = unknown>(
  projectId: number,
  tableName: string,
  projectLevel: boolean,
): Promise<T> => {
  const tableType = projectLevel ? 'project-table' : 'table'
  return tableKeeperRequest(
    `/${tableType}/domain/${domain}/projects/${projectId}/tables/${tableName}`,
    'GET',
  )
}

export const getCurrentTableConfig = <T = unknown>(
  projectId: number,
  tableName: string,
  projectLevel: boolean,
): Promise<T> => {
  const sessionConfig = getTableConfigFromSession<T>(projectId, tableName)
  if (sessionConfig) {
    return Promise.resolve(sessionConfig)
  }
  return getTable(projectId, tableName, projectLevel)
}

export const getSavedTableConfigs = <T = unknown>(
  projectId: number,
  tableName: string,
): Promise<T> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/tables/${tableName}/savedTableConfigs`,
    'GET',
  )
}

export const setTableConfig = <T = unknown>(
  projectId: number,
  tableName: string,
  data: T,
  options?: Options,
): Promise<T> => {
  const tableType = options?.projectLevel ? 'project-table' : 'table'
  return tableKeeperRequest(
    `/${tableType}/domain/${domain}/projects/${projectId}/tables/${tableName}`,
    'POST',
    data,
  )
}

export const canOverrideSessionConfig = (
  projectId: number,
  tableName: string,
): boolean => {
  const name = sessionName(projectId, tableName) + 'override'
  const value = !!sessionStorage.getItem(name)
  sessionStorage.removeItem(name)
  return value
}

export const setTableConfigInSession = <T = unknown>(
  projectId: number,
  tableName: string,
  data: T,
  options?: Options,
): Promise<T> => {
  if (canOverrideSessionConfig(projectId, tableName)) {
    return setTableConfig(projectId, tableName, data, options)
  }
  sessionStorage.setItem(
    sessionName(projectId, tableName),
    JSON.stringify(data),
  )
  return Promise.resolve(data)
}

const clearSessionTableConfig = (projectId: number, tableName: string) => {
  sessionStorage.removeItem(sessionName(projectId, tableName))
}

export const overrideSessionTableConfig = (
  projectId: number,
  tableName: string,
) => {
  clearSessionTableConfig(projectId, tableName)
  sessionStorage.setItem(sessionName(projectId, tableName) + 'override', 'true')
}

export const setSavedTableConfig = <T = unknown>(
  projectId: number,
  tableName: string,
  data: T,
): Promise<T> => {
  const savedConfig = data as SavedTableConfig
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/tables/${tableName}/savedTableConfigs`,
    'POST',
    { data: savedConfig },
  )
}

export const deleteSavedTableConfig = <configName = string>(
  projectId: number,
  tableName: string,
  configName: string,
): Promise<void> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/tables/${tableName}/savedTableConfigs`,
    'DELETE',
    { data: configName },
  )
}

export const clearTableConfigForTable = (
  projectId: number,
  tableName: string,
  projectLevel?: boolean,
): Promise<void> => {
  const tableType = projectLevel ? 'project-table' : 'table'
  return tableKeeperRequest(
    `/${tableType}/domain/${domain}/projects/${projectId}/tables/${tableName}`,
    'DELETE',
    {},
    true,
  )
}

export const setGanttConfig = <T = unknown>(
  projectId: number,
  data: T,
): Promise<T> => {
  return tableKeeperRequest(
    `/gannt/domain/${domain}/projects/${projectId}/gannt`,
    'POST',
    data,
  )
}

export const getGanttConfig = <T = unknown>(projectId: number): Promise<T> => {
  return tableKeeperRequest(
    `/gannt/domain/${domain}/projects/${projectId}/gannt`,
    'GET',
  )
}

export const clearTables = (): Promise<void> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/clearAll`,
    'DELETE',
    {},
    true,
  )
}

export const getFilters = <T = unknown>(
  projectId: number,
  filterName: string,
): Promise<T> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/filters/${filterName}`,
    'GET',
  )
}

export const setFilters = <T = unknown>(
  projectId: number,
  filterName: string,
  data: T,
): Promise<T> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/filters/${filterName}`,
    'POST',
    data,
  )
}

export const dataDump = (
  key: string,
  data: unknown,
  projectId?: number,
): Promise<void> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'POST',
    data,
  )
}

export const dataRetrieve = <T>(
  key: string,
  projectId?: number,
): Promise<T> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'GET',
  )
}

export const dataDelete = (key: string, projectId?: number): Promise<void> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'DELETE',
    {},
    true,
  )
}
