import { PropsWithChildren } from 'react'
import * as React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'

interface DeleteConfirmInputModalBodyProps {
  itemName: string
  control: Control<{ itemName: string }>
  triggerValidate: () => Promise<boolean>
  label: string
}

export const ConfirmInputDeleteModal = ({
  itemName,
  label,
  triggerValidate,
  control,
}: PropsWithChildren<DeleteConfirmInputModalBodyProps>) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={'flex flex-row items-center w-full px-3 pt-4 mb-5'}>
        <Icon
          className={'flex flex-none h-10 w-10 mr-4'}
          styleClass={'w-full'}
          icon={Icons.WARNING_RED}
        />
        <p className={'text-sm text-gray-800'}>
          {t('confirm_deleting_by_typing_item_name', {
            label: label.toLowerCase(),
          })}
        </p>
      </div>
      <form onSubmit={triggerValidate}>
        <Controller
          control={control}
          rules={{
            validate: (value) => {
              if (!value) return t('required')
              if (value !== itemName)
                return t('item_name_mismatch', {
                  label: label,
                })
              return true
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              block
              required
              label={t('folder_name')}
              value={value}
              onChange={onChange}
              errorMessage={error?.message}
            />
          )}
          name={'itemName'}
        />
      </form>
    </>
  )
}
