import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import SystemTypeFieldsList from 'src/components/system/system-type-fields/SystemTypeFieldsList'
import SystemTypeGroupList from 'src/components/system/system-type-group/SystemTypeGroupList'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'

const MilestoneMetadataConfigPage: FC = () => {
  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const { id } = projectContext.state.currentProject

  const tabs: ITabs = {
    user_defined_fields: {
      title: t('user_defined_fields'),
      show: true,
      content: <SystemTypeFieldsList projectId={id} domain={'Milestone'} />,
    },
    user_defined_field_groups: {
      title: t('user_defined_field_groups'),
      show: true,
      content: (
        <SystemTypeGroupList
          projectId={id}
          domain={'Milestone'}
          subDomain={'Milestone'}
          canCreatingMultiple={false}
        />
      ),
      path: 'user_defined_field_groups',
    },
  }

  return <TabView level={2} tabs={tabs} />
}

export default MilestoneMetadataConfigPage
