import Downloading from '@icons/downloading.svg'
import ProgressBar from '@ramonak/react-progress-bar'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportContext } from 'src/context/ExportContext/ExportContext'
import { ExportStatus } from 'src/context/ExportContext/ExportTypes'

const ExportToast = () => {
  const { state } = useContext(ExportContext)
  const { t } = useTranslation()

  return (
    <div className="absolute flex flex-col bottom-20 z-50 left-20 w-auto gap-2">
      {state.pendingExports.map((pending) => {
        const task = state.exportTasks.find((t) => t.uuId === pending)
        if (!task) return
        const content = () => {
          switch (task.status) {
            default:
            case ExportStatus.Initialized:
              return t('exporting')
            case ExportStatus.Processing:
              if (task.progress && task.totalCount)
                return `${t('extracting_data')} (${task.progress}/${task.totalCount})`
              return t('extracting_data')
            case ExportStatus.Success:
            case ExportStatus.Finalizing:
              return t('generating_excel')
          }
        }

        return (
          <div
            key={task.uuId}
            className="flex items-center w-80 p-4 text-gray-500 bg-white space-x-4 rounded-lg shadow-lg"
            role="alert"
          >
            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-blue-root rounded-full">
              <Downloading className={'fill-white text-xl'} />
            </div>
            <div className="flex flex-col pl-2 w-full gap-1">
              <div className="text-sm font-medium text-gray-700">
                {content()}
              </div>
              <ProgressBar
                completed={task.progress ?? 0}
                maxCompleted={task.totalCount}
                animateOnRender
                isLabelVisible={false}
                bgColor={'#43BC4F'}
                height={'10px'}
                isIndeterminate={
                  task.status !== ExportStatus.Processing || !task.progress
                }
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ExportToast
