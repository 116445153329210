import { TreeNodeInArray } from 'react-simple-tree-menu'
import { IPaginateResult, ITestSystemGroup } from './OrgTypes'
import { request } from './service-utils'

export const createTestSystemGroup = (
  testSystemGroup: Omit<ITestSystemGroup, 'id'>,
  projectId: number,
): Promise<ITestSystemGroup> => {
  return request(
    `projects/${projectId}/test_system_groups`,
    'POST',
    testSystemGroup,
  )
}

export const editTestSystemGroup = (
  testSystemGroup: NullablePartial<ITestSystemGroup>,
): Promise<ITestSystemGroup> => {
  return request(
    `test_system_groups/${testSystemGroup.id}`,
    'PUT',
    testSystemGroup,
  )
}

export const massTestSystemGroup = (
  projectId: number,
  testSystemGroupIds: number[],
  testSystemGroup: NullablePartial<ITestSystemGroup>,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_system_groups/mass_update`,
    'POST',
    {
      ...testSystemGroup,
      test_system_group_ids: testSystemGroupIds,
    },
  )
}

export const getProjectTestSystemGroup = (
  projectId: number,
  page: number,
  filter: any,
  itemsPerPage: number,
): Promise<IPaginateResult<ITestSystemGroup>> => {
  return request(
    `projects/${projectId}/test_system_groups/${page}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getProjectTestSystemGroupOnly = (
  projectId: number,
): Promise<ITestSystemGroup[]> => {
  return request(`projects/${projectId}/test_system_groups_only`, 'GET')
}

export const getSingleTestSystemGroup = (
  id: number,
): Promise<ITestSystemGroup> => {
  return request(`test_system_groups/${id}`, 'GET')
}

export const deleteTestSystemGroup = (testSystemGroupId: number) => {
  return request(`/test_system_groups/${testSystemGroupId}`, 'DELETE', {}, true)
}

export const testSystemGroupImportFromJson = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_system_groups/validate_for_import_job`,
    'POST',
    data,
  )
}

export const testSystemGroupsTree = (
  projectId: number,
  reload?: boolean,
): Promise<any> => {
  return request(`/projects/${projectId}/test_system_groups/tree`, 'POST', {
    reload: reload,
  })
}

export const getTestSystemGroupsTree = (
  projectId: number,
  openNodes: string[],
  reload?: boolean,
): Promise<{ [key: string]: TreeNodeInArray }> => {
  return request(`projects/${projectId}/test_system_groups/tree`, 'POST', {
    reload: reload,
    open_nodes: openNodes,
  })
}

export const testSystemGroupBulkDelete = (
  projectId: number,
  ids: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_system_groups/remove_multiple`,
    'POST',
    {
      ids,
    },
  )
}
