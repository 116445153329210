import { useContext, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { TableInputContext } from 'src/context/TableInputContext/TableInputContext'
import { styleClass } from 'src/ui-elements/Table/Columns'
import Input from 'src/ui-elements/input/Input'
import { openFileExternal } from 'src/utility/utils'

interface ITableTextEditorProps {
  value: string
  onSubmit: (val: string) => void
  clickToEdit?: boolean
  disableEdit?: boolean
  columnId?: string
  id?: number
  onFocus?: () => void
  type?: string
  url?: string
}

const TableTextEditor = ({
  value,
  onSubmit,
  clickToEdit,
  disableEdit,
  type,
  columnId,
  id,
  onFocus,
  url,
}: ITableTextEditorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>(value)
  const tableInputContext = useContext(TableInputContext)

  const textChange = useRef(false)

  useEffect(() => {
    setTextValue(value)
  }, [value])

  useDidMountEffect(() => {
    const { key, filed: contextFiled } = tableInputContext.state
    if (key === id && contextFiled === columnId) {
      setOpen(true)
    } else {
      closeEditor()
    }
  }, [tableInputContext.state])

  const valueSubmitted = (e: any) => {
    if (e.key === 'Enter') {
      closeEditor()
    }
  }

  const onToggle = (e: any) => {
    if (clickToEdit && !disableEdit) {
      e.stopPropagation()
      setOpen((n) => !n)
      onFocus?.()
    }
  }

  const focusInput = (e: any) => {
    e.stopPropagation()
  }

  const closeEditor = () => {
    setOpen(false)
    if (textChange.current) {
      onSubmit(textValue)
      textChange.current = false
    }
  }

  return (
    <div className={'h-full w-full'}>
      {!open ? (
        <div
          onClick={(e) => onToggle(e)}
          className={twMerge(
            !disableEdit ? styleClass.editableCell : styleClass.cell,
            'whitespace-nowrap truncate h-full flex items-center',
          )}
        >
          {url ? (
            <a
              onClick={openFileExternal(url)}
              className="cursor-pointer underline text-blue-one mr-2 w-mn-content"
              rel="noreferrer"
            >
              {textValue}
            </a>
          ) : (
            <a>{textValue}</a>
          )}
        </div>
      ) : (
        <>
          <div
            className={'fixed inset-x-0 top-0 z-10'}
            style={{ bottom: 0, width: '100%' }}
            onClick={(e) => onToggle(e)}
          />
          <div className={'z-20 relative'}>
            <Input
              hidden={false}
              disabled={false}
              block={true}
              value={textValue}
              onKeyDown={valueSubmitted}
              onChange={(event) => {
                textChange.current = true
                setTextValue(event.target.value)
              }}
              onBlur={() => closeEditor()}
              focus={true}
              noBorder={true}
              type={type}
              onClick={focusInput}
              noPadding={true}
              inLine={true}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default TableTextEditor
