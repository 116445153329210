import CheckCircle from '@icons/check_circle-fill.svg'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import Loader from 'src/ui-elements/loader/Loader'
import { ImportDataFiled } from '../selectors/DataSelectors'
import { IDocValidationResponse, IFormatData } from '../types'
import DocValidationList from './DocValidationList'

export interface DcoUploadValidationProps {
  response?: IDocValidationResponse
  parentId: number
  title: string
  onChange: (
    key: IFormatData,
    header: string,
    index: number,
    value: string | string[] | null,
  ) => Promise<void>
  reload: boolean
  sendData: (data: any[]) => void
  type: string
  columns?: ImportDataFiled[]
  removeItem: (index: number) => void
  useDefinedFiled?: IMetaField[]
  validateLoading?: boolean
  validateMultiple?: boolean
}

const UploadValidation: FC<DcoUploadValidationProps> = ({
  response,
  title,
  onChange,
  reload,
  parentId,
  columns,
  type,
  removeItem,
  useDefinedFiled,
  validateLoading,
  validateMultiple,
}) => {
  const [onChangeLoading, setOnChangeLoading] = useState(
    validateLoading ?? false,
  )
  const { t } = useTranslation()

  const _onChange = useCallback(
    async (
      key: IFormatData,
      header: string,
      index: number,
      value: string | string[] | null,
    ) => {
      setOnChangeLoading(!validateMultiple)
      await onChange(key, header, index, value)
      setOnChangeLoading(false)
    },
    [onChange, validateMultiple],
  )

  const successCount =
    (response?.new_records?.length ?? 0) +
    (response?.updated_records?.length ?? 0)

  return (
    <div className="min-h-70vh mx-4 my-2 flex-col">
      {!response || !response?.[type] ? (
        <Loader />
      ) : (
        <>
          <div className="font-normal text-sm left-0 bg-white w-full">
            <div className="mb-4 relative">
              {successCount > 0 && (
                <p className={'flex gap-2 text-green'}>
                  <CheckCircle className={'text-xl fill-green'} />
                  {t('documents_uploaded_successfully', {
                    type: title.toLowerCase(),
                    count: successCount,
                  })}
                </p>
              )}
              {onChangeLoading && (
                <div className="absolute -top-1 -right-1 w-3 h-3 bg-orange-one rounded-full animate-ping" />
              )}
            </div>
            <div className="mb-4">
              <p className={'text-red-danger font-bold'}>
                {t('correct_remaining_errors_before_finalizing', {
                  type: title.toLowerCase(),
                  count: response[type].length,
                  errors: response?.error_count ?? 0,
                })}
              </p>
              <p>{t('hover_over_error_icons')}</p>
            </div>
          </div>
          <div key={reload ? '1' : '2'} className="">
            <DocValidationList
              parentId={parentId}
              onChange={_onChange}
              data={response[type]}
              columns={columns ?? []}
              removeItem={removeItem}
              useDefinedFiled={useDefinedFiled}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default UploadValidation
