import { FormEvent, PropsWithChildren, useContext } from 'react'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmInputDeleteModal } from 'src/components/delete-modal/ConfirmInputDeleteModal'
import { DeleteModalBody } from 'src/components/delete-modal/DeleteModal'
import ConfirmDialogContext from 'src/context/ConfirmModalContext'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface DeleteModalProps {
  itemName?: string
  itemIdnType?: string
  customTitle?: string
  hideDeleteBtn?: boolean
  disableDeleteBtn?: boolean
  isBulk?: boolean
}

export const useDeleteModal = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()

  const confirmDelete = ({
    itemName,
    itemIdnType,
    customTitle,
    hideDeleteBtn,
    disableDeleteBtn,
    isBulk,
    children,
  }: PropsWithChildren<DeleteModalProps> = {}) => {
    const deleteModalBody = (
      <DeleteModalBody itemName={itemName} isBulk={isBulk}>
        {children}
      </DeleteModalBody>
    )

    return showConfirmation({
      title: customTitle
        ? customTitle
        : t('confirm_deletion_of', {
            item: `${itemIdnType ? itemIdnType : ''}`,
          }),
      body: deleteModalBody,
      confirmText: t('delete'),
      confirmButtonType: ButtonType.DANGER,
      hideConfirmButton: hideDeleteBtn,
      disableConfirmButton: disableDeleteBtn,
    })
  }

  return { confirmDelete }
}

interface ConfirmInputDeleteModalProps extends DeleteModalProps {
  label: string
  itemName: string
}

export const useConfirmWithInputDeleteModal = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()
  const { control, trigger } = useForm<{ itemName: string }>()

  const triggerValidateForm = async (e?: FormEvent) => {
    e?.preventDefault()
    return await trigger(['itemName'])
  }

  const confirmWithInputDeleteModal = ({
    itemName,
    itemIdnType,
    customTitle,
    hideDeleteBtn,
    disableDeleteBtn,
    label,
  }: PropsWithChildren<ConfirmInputDeleteModalProps>) => {
    const deleteModalBody = (
      <ConfirmInputDeleteModal
        itemName={itemName}
        control={control}
        triggerValidate={triggerValidateForm}
        label={label}
      />
    )

    return showConfirmation({
      title: customTitle
        ? customTitle
        : t('confirm_deletion_of', {
            item: `${itemIdnType ? itemIdnType : ''}`,
          }),
      body: deleteModalBody,
      confirmText: t('delete'),
      confirmButtonType: ButtonType.DANGER,
      hideConfirmButton: hideDeleteBtn,
      disableConfirmButton: disableDeleteBtn,
      validateFormCallback: triggerValidateForm,
    })
  }

  return { confirmWithInputDeleteModal }
}
