import { t } from 'i18next'
import { lowerCase } from 'lodash'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ITableConfigProps } from 'src/ui-elements/Table/TableConfigButtons'
import { SavedTableConfig } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

export interface ITableConfigModalProps {
  showModal: boolean
  closeModal: () => void
  onSave: SubmitHandler<ITableConfigProps>
  tableConfigs: SavedTableConfig[]
}
const TableConfigFormModal = ({
  showModal,
  closeModal,
  onSave,
  tableConfigs,
}: ITableConfigModalProps) => {
  const { control, handleSubmit } = useForm<ITableConfigProps>()

  const tableConfigNames = tableConfigs.map((c) => lowerCase(c.name))

  const validateConfigurationName = (name: string) => {
    if (!/^[A-Za-z0-9_ æøåÆØÅ]*$/.test(name)) {
      return t('name_can_not_contain_special_characters')
    } else if (tableConfigNames.includes(lowerCase(name))) {
      return t('duplicate_value_not_allowed')
    }
    return true
  }
  return (
    <Modal
      title={`${t('save')} ${t('table_configuration')}`}
      show={showModal}
      closeModal={closeModal}
      size={'w-1/3'}
      maxWidth={600}
    >
      <form
        onSubmit={handleSubmit(onSave)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit(onSave)()
          }
        }}
      >
        <Controller
          control={control}
          rules={{
            validate: (value) => {
              if (!value) return t('required')
              return validateConfigurationName(value)
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              block
              required
              label={t('name')}
              value={value}
              onChange={onChange}
              errorMessage={error?.message}
            />
          )}
          name={'name'}
        />

        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit(onSave)} type={ButtonType.PRIMARY}>
            {t('save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default TableConfigFormModal
