export const milestoneListExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/mile_stones/pages/0/0',
    method: 'POST',
  },
  name: 'Milestones',
  displayName: 'milestones',
  columns: [
    'id',
    'title',
    'mainprocess',
    'theme',
    'type',
    'status',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'keypoints',
    'responsible',
    'discipline',
    'contract',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'name',
    'main_process.name',
    'team.name',
    'tag.name',
    'status',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'done_children||open_children||expired_children',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'created_at',
    'updated_at',
  ],
}
