import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Comments from 'src/components/comment/Comments'
import { IFileContainer } from 'src/document/types/IFileContainer'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { classNames } from 'src/utility/utils'
import { useFileContainerHistory } from '../../../../query/documents/fileContainerHistory'
import HistoryFiles from '../../HistoryFiles'
import BlueBadge from '../../base/BlueBadge'

interface IRevisionInspector {
  document: IFileContainer
}

const styleClass = {
  label: classNames('font-medium', 'text-sm', 'leading-5', 'text-gray-700'),
}

const RevisionInspector: React.FC<IRevisionInspector> = ({ document }) => {
  const { t } = useTranslation()
  const documentId = document.id
  const { data: history, isPending: loading } =
    useFileContainerHistory(documentId)

  return (
    <div className={'flex flex-col'}>
      {loading ? (
        <SpinnerMobile className="scale-25 flex self-center" />
      ) : document && history && history?.history.length > 0 ? (
        history.history[0].revisions.map((revision, key) => (
          <div
            className={`border-d-grey-light border-dashed ${
              key === 0 ? 'border-0 pb-2' : 'border-t py-2'
            }`}
            key={key}
          >
            <p className={'flex gap-x-3 items-center'}>
              <span className={styleClass.label}>{t('revision_name')}</span>
              <span className={'font-medium text-sm leading-5 text-black'}>
                {revision.name}
              </span>
              {key === 0 && (
                <BlueBadge className={'ml-auto'} text={t('latest')} />
              )}
            </p>
            <p className="my-1 flex gap-x-5 items-center text-sm">
              <span className={'font-medium text-d-fontgrey min-w-20'}>
                {t('time')}
              </span>
              <span className={'font-medium text-d-fontgrey min-w-18'}>
                {t('issued')}
              </span>
              <span className={'font-medium text-d-fontgrey min-w-18'}>
                {t('returned')}
              </span>
              <span className={'font-medium text-d-fontgrey'}>
                {t('duration')}
              </span>
            </p>
            <p className="my-1 flex gap-x-5 items-center text-sm">
              <span className={'font-medium text-d-fontgrey min-w-20'}>
                {t('planned')}
              </span>
              <span className={'font-medium text-gray-700 min-w-18'}>
                {revision.planned_start_time
                  ? moment(revision.planned_start_time).format('L')
                  : ''}
              </span>
              <span className={'font-medium text-gray-700 min-w-18'}>
                {revision.planned_duedate
                  ? moment(revision.planned_duedate).format('L')
                  : ''}
              </span>
              <span className={'font-medium text-gray-700'}>
                {Number.isInteger(revision.planned_duration) &&
                revision.planned_duration >= 0
                  ? `${revision?.planned_duration} ${t('days')}`
                  : '-'}
              </span>
            </p>

            <p className="my-1 flex gap-x-5 items-center text-sm">
              <span className={'font-medium text-d-fontgrey min-w-20'}>
                {t('actual')}
              </span>
              <span className={'font-medium text-gray-700 min-w-18'}>
                {revision.start_time
                  ? moment(revision.start_time).format('L')
                  : '-'}
              </span>
              <span className={'font-medium text-gray-700 min-w-18'}>
                {revision.closed_date
                  ? moment(revision.closed_date).format('L')
                  : '-'}
              </span>
              <span className={'font-medium text-gray-700'}>
                {Number.isInteger(revision.actual_duration) &&
                revision.actual_duration >= 0
                  ? `${revision?.actual_duration} ${t('days')}`
                  : '-'}
              </span>
            </p>
            <div className="flex flex-col">
              <div className={`font-semibold text-sm text-d-fontgrey pr-4`}>
                {t('uploads')}
              </div>
              <HistoryFiles
                fileContainerId={document?.id}
                folderId={document.folder_id}
                files={revision.project_files}
                block
                className={'mt-0.5'}
              />
            </div>
            <Comments
              parentId={revision.id}
              parentType={'Revision'}
              className="my-2"
            />
          </div>
        ))
      ) : (
        <p className={'text-d-fontgrey mx-auto w-full font-light text-sm'}>
          {t('no_data_found')}
        </p>
      )}
    </div>
  )
}

export default RevisionInspector
