import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SystemTypeForm from 'src/components/system/SystemTypesList/SystemTypeForm'
import { objectTypeExportTemplate } from 'src/export-templates/ObjectTypeExports'
import { systemTypeImports } from 'src/export-templates/SystemTypeImport'
import { IPaginatedSystemTypes, ISystemType } from 'src/service/OrgTypes'
import {
  addSystemTypesToCategory,
  deleteSystemType,
  getPaginatedSystemsTypes,
  getProjectCategorySystemTypes,
  removeSystemTypesFromSyntaxCategory,
  systemTypesBulkDelete,
} from 'src/service/SystemService'
import BackgroundTaskLoader from 'src/ui-elements/loader/BackgroundTaskLoader'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import { UserContext } from '../../../context/UserContextProvider/UserContext'
import DataSelectors, {
  DATA_TYPES,
} from '../../../document/components/FileUpload/selectors/DataSelectors'
import { systemTypeValidateFromJson } from '../../../service/SystemTypeFieldService'
import Button from '../../../ui-elements/button/Button'
import MaterialIcon from '../../../ui-elements/icon/materialIcon'
import List from '../../../ui-elements/list/List'
import { IListFilter } from '../../../ui-elements/list/ListContextProvider'
import { ISorting } from '../../../ui-elements/list/ListTypes'
import {
  capFirstLetter,
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../../utility/utils'

import DeleteModal from '../../delete-modal/DeleteModal'
import { IMPORT_TYPE } from '../../notifications/import/ImportNotificationItem'
import ImportModal from '../../upload-item/ImportModal'
import { columns } from './ListColumns'
import SystemsTypesSelector from './SystemTypesSelector'

export const systemTypeImportColumns = [
  DataSelectors.defineDataField('name', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.defineDataField('type_code', DATA_TYPES.string),
]

interface ISystemTypeList {
  systemSettingId: number
  categoryId?: number
  categoryCode?: string
  categoryName?: string
  systemTypeCodeRegex?: string
}

const SystemsTypesList: React.FC<ISystemTypeList> = ({
  systemSettingId,
  categoryId,
  categoryCode,
  categoryName,
  systemTypeCodeRegex,
}) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'pl-2', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }
  const pageSize = 30
  const isCategoryList = !!(categoryId || categoryCode)
  const { state } = useContext(ProjectContext)
  const userContext = useContext(UserContext)

  const projectId = state.currentProject.id
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const projectAdmin = userContext.actions.hasAdminAccess('object')

  const [pages, setPages] = useState<number>(1)
  const [systemTypes, setSystemTypes] = useState<ISystemType[]>([])
  const [selectedSystemType, setSelectedSystemType] = useState<ISystemType>()
  const [reload, setReload] = useState(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showMassEditModal, setShowMassEditModal] = useState<boolean>(false)
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [selectedSystemTypes, setSelectedSystemTypes] = useState<number[]>([])
  const [showImportModal, setImportModal] = useState(false)

  const showAlart = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  useEffect(() => {
    setReload((n) => !n)
  }, [projectId, systemSettingId, categoryId])

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting

    filterSystems(activeFilters, page)
  }

  const filterSystems = (activeFilters: IActiveFilter, page: number) => {
    if (categoryId) {
      getProjectCategorySystemTypes(
        projectId,
        categoryId,
        page,
        pageSize,
        activeFilters,
      ).then((res: IPaginatedSystemTypes) => {
        setSystemTypes(res.system_types)
        setPages(res.pages)
      })
    } else {
      getPaginatedSystemsTypes(
        projectId,
        systemSettingId,
        page,
        pageSize,
        activeFilters,
      ).then((res: IPaginatedSystemTypes) => {
        setSystemTypes(res.system_types)
        setPages(res.pages)
      })
    }
  }

  const onEditItemClick = (systemType: ISystemType) => {
    setShowEditModal(true)
    setSelectedSystemType(systemType)
  }

  const onDeletItemClick = (systemType: ISystemType) => {
    setShowDeleteModal(true)
    setSelectedSystemType(systemType)
  }

  const closeSelectModal = () => {
    setShowSelectModal(false)
    setReload((n) => !n)
  }

  const closeEditModal = () => {
    setShowEditModal(false)
    setReload((n) => !n)
    setSelectedSystemType(undefined)
  }

  const closeMassEditModal = () => {
    setShowMassEditModal(false)
    setReload((n) => !n)
    setSelectedSystemTypes([])
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedSystemType(undefined)
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
    setReload((n) => !n)
  }

  const deleteSingleSystemType = () => {
    if (selectedSystemType?.id) {
      const deleteApi = categoryId
        ? removeSystemTypesFromSyntaxCategory(projectId, categoryId, [
            selectedSystemType?.id,
          ])
        : deleteSystemType(selectedSystemType?.id)
      deleteApi
        .then(() => {
          setReload((n) => !n)
          setShowDeleteModal(false)
          setSelectedSystemType(undefined)
        })
        .catch((err) => console.error(err))
    }
  }

  const showAlert = (type: IAlertType, title: string) => {
    addAlert({ type, title, description: '' })
  }
  const onBulkDelete = () => {
    if (selectedSystemTypes.length === 0) {
      showAlart('info', t('items_not_selected'), t('select_item_to_edit'))
      return
    }

    const removeApi = categoryId
      ? removeSystemTypesFromSyntaxCategory(
          projectId,
          categoryId,
          selectedSystemTypes,
        )
      : systemTypesBulkDelete(projectId, selectedSystemTypes)
    removeApi.then(() => {
      showAlert('success', t('successfully_deleted'))
      setReload((n) => !n)
      setSelectedSystemTypes([])
    })
  }

  const openMassEditModal = () => {
    if (selectedSystemTypes.length === 0) {
      showAlart('info', t('items_not_selected'), t('select_item_to_edit'))
      return
    }
    setShowMassEditModal(true)
  }

  const addSystemTypesToCatoegory = (ids: number[]) => {
    if (categoryId) {
      addSystemTypesToCategory(projectId, categoryId, ids).then(() => {
        setShowSelectModal(false)
        setReload((n) => !n)
      })
    }
  }

  const actionButton = (
    <span className="flex items-center">
      <Button onClick={openCreateModal} type={Button.ButtonType.BOUNDARY_WHITE}>
        {t('new_system_type')}
      </Button>
      <Button
        type={Button.ButtonType.BOUNDARY_WHITE}
        onClick={openMassEditModal}
      >
        <MaterialIcon className="pr-1 pt-0.5 text-sm" icon="edit" />
        {capFirstLetter(t('edit'))}
      </Button>
      {isCategoryList && (
        <Button
          type={Button.ButtonType.BOUNDARY_WHITE}
          onClick={() => setShowSelectModal(true)}
        >
          <MaterialIcon className="pr-1 pt-0.5 text-sm" icon="check_box" />
          {capFirstLetter(t('select_from_existing_object_types'))}
        </Button>
      )}
      {projectAdmin && (
        <Button
          type={Button.ButtonType.BOUNDARY_WHITE}
          onClick={() => setImportModal(true)}
        >
          <MaterialIcon className="pr-1 pt-0.5 text-sm" icon="publish" />
          {t('import')}
        </Button>
      )}
    </span>
  )

  return (
    <>
      <div className={styleClass.root}>
        <List
          exportTemplate={objectTypeExportTemplate(systemSettingId, categoryId)}
          actionButton={actionButton}
          columns={columns()}
          data={Array.isArray(systemTypes) ? systemTypes : []}
          tableName={`systemsTypesList-${projectId}-${systemSettingId}-${
            categoryId ?? ''
          }`}
          onRowClick={onEditItemClick}
          itemsPerPage={pageSize}
          pagination={true}
          totalPages={pages}
          onBulkDelete={onBulkDelete}
          bulkDelete={true}
          selectedRows={selectedSystemTypes}
          onSelectRow={(data) => setSelectedSystemTypes(data)}
          isRowSelectable={true}
          reload={reload}
          sortBackend={true}
          filterResetOption={true}
          getFilteredData={getFilteredData}
          actionMenu={[
            {
              name: t('delete'),
              action: onDeletItemClick,
            },
          ]}
        />
        {selectedSystemType && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={closeDeleteModal}
            onDelete={deleteSingleSystemType}
            itemIdnType={
              categoryId
                ? `${selectedSystemType.name} (${t('system_type')}) ${t(
                    'from',
                  )} ${categoryName} (${categoryCode})`
                : `${selectedSystemType.name} (${t('system_type')})`
            }
            itemName={
              categoryId
                ? `${selectedSystemType.name} (${t('system_type')}) ${t(
                    'from',
                  )} ${categoryName} (${categoryCode})`
                : `${selectedSystemType.name} (${t('system_type')})`
            }
          />
        )}

        {showCreateModal && (
          <SystemTypeForm
            systemSettingId={systemSettingId}
            show={showCreateModal}
            projectId={projectId}
            categoryId={categoryId}
            categoryCode={categoryCode}
            systemTypeCodeRegex={systemTypeCodeRegex}
            closeModal={closeCreateModal}
            onSubmitSelect={addSystemTypesToCatoegory}
          />
        )}

        {showMassEditModal && selectedSystemTypes.length === 1 && (
          <SystemTypeForm
            systemSettingId={systemSettingId}
            show={showMassEditModal}
            systemTypeId={selectedSystemTypes[0]}
            projectId={projectId}
            closeModal={closeMassEditModal}
            systemTypeCodeRegex={systemTypeCodeRegex}
          />
        )}

        {showMassEditModal && selectedSystemTypes.length > 1 && (
          <SystemTypeForm
            systemSettingId={systemSettingId}
            show={showMassEditModal}
            projectId={projectId}
            systemTypeIds={selectedSystemTypes}
            closeModal={closeMassEditModal}
          />
        )}

        {showEditModal && selectedSystemType && (
          <SystemTypeForm
            systemSettingId={systemSettingId}
            show={showEditModal}
            systemTypeId={selectedSystemType.id}
            projectId={projectId}
            closeModal={closeEditModal}
            systemTypeCodeRegex={systemTypeCodeRegex}
          />
        )}

        {showSelectModal && categoryId && categoryCode && categoryName && (
          <SystemsTypesSelector
            showModal={showSelectModal}
            categoryId={categoryId}
            categoryCode={categoryCode}
            categoryName={categoryName}
            closeModal={closeSelectModal}
            onSubmit={addSystemTypesToCatoegory}
          />
        )}
        {showImportModal && (
          <ImportModal
            columns={systemTypeImportColumns}
            import_type={IMPORT_TYPE.SYSTEM_TYPE_IMPORT}
            show={showImportModal}
            close={() => {
              setImportModal(false)
              setReload((n) => !n)
            }}
            useDefinedField={[]}
            importApi={(projectId, data) =>
              systemTypeValidateFromJson(
                projectId,
                systemSettingId,
                data,
                categoryId,
              )
            }
            modal_title={'upload_objects_types'}
            template={systemTypeImports}
            uploadUrl={`{ORG_URL}system_settings/${systemSettingId}/system_types/validate_for_import_job/${
              categoryId ?? ''
            }`}
          />
        )}
      </div>
      <BackgroundTaskLoader
        jobName={'apply_group_default_values'}
        loadingMessage={t('applying_default_value_to_all_connected_items')}
      />
    </>
  )
}

export default SystemsTypesList
