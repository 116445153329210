import * as React from 'react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SystemTypeForm from 'src/components/system/SystemTypesList/SystemTypeForm'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import {
  deleteColumn,
  userDefinedColumns as metaDataColumns,
} from '../../../../components/TableColumns/Columns'
import { IMetaField } from '../../../../components/system/system-type-fields/SystemTypeFieldsForm'
import { ImportDataFiled } from '../selectors/DataSelectors'
import { IFormatData } from '../types'
import DocValidationCell from './DocValidationCell'

interface IDocValidationListProps {
  parentId: number
  onChange: (
    key: IFormatData,
    header: string,
    index: number,
    value: string | string[] | null,
  ) => void
  data: IFormatData[]
  columns: ImportDataFiled[]
  removeItem: (index: number) => void
  useDefinedFiled?: IMetaField[]
}

const userDefinedValues = 'user_defined_values'
const optionalFields = 'optional_fields'

const DocValidationList: FC<IDocValidationListProps> = ({
  parentId,
  data,
  columns,
  onChange,
  removeItem,
  useDefinedFiled,
}) => {
  const { t } = useTranslation()
  const [showSystemTypeForm, setShowSystemTypeForm] = useState<
    | {
        categoryId: number
        typeCode: string
        onClose: () => void
      }
    | undefined
  >(undefined)

  const onDeleteItemClick = (_: unknown, i: number) => {
    removeItem(i)
  }

  const updateMeta = (index?: number, optionalFiles?: any) => {
    if (index === undefined) return
    const _data = data[index] as { [key: string]: any }
    _data[userDefinedValues] = optionalFiles
    onChange(_data, optionalFields, index, optionalFiles)
  }

  const getUserDefinedColumns = (): IListColumns[] => {
    const _userDefinedColumns = metaDataColumns(
      useDefinedFiled ?? [],
      (_, __, i, optionalFiles) => updateMeta(i, optionalFiles),
      userDefinedValues,
      false,
      true,
    )
    return _userDefinedColumns
  }

  const getColumn = (): IListColumns[] => {
    const _headers = columns.map((column) => {
      const _header = column.header
      return {
        size: '250',
        id: _header,
        name: _header,
        dataField: `${_header}.value||${_header}.errors`,
        cell: (__: unknown, _: number, index: number) => {
          const columnData = data[index]?.[_header]
          return (
            <>
              <DocValidationCell
                parentId={parentId}
                onChange={(v) => onChange(data[index], _header, index, v)}
                dataFiled={column}
                openSystemTypeForm={() => {
                  setShowSystemTypeForm({
                    categoryId:
                      +data[index]?.['system_syntax_category_id']?.value,
                    typeCode: `${columnData?.errors.flat()[1]}`,
                    onClose: () =>
                      (columnData.errors = columnData.errors.filter(
                        (error) =>
                          !(
                            error[0] === 'system_type_not_configured_for_syntax'
                          ),
                      )),
                  })
                }}
                {...columnData}
              />
            </>
          )
        },
      }
    })
    return [
      deleteColumn(onDeleteItemClick),
      ..._headers,
      ...getUserDefinedColumns(),
    ]
  }

  return (
    <>
      <List
        columns={getColumn()}
        tableName={'documentsValidationList'}
        data={data}
        disableColumnSelector={true}
        disableConfigStore={true}
        itemsPerPage={0}
        actionMenu={[
          {
            name: t('delete'),
            action: onDeleteItemClick,
          },
        ]}
      />
      {showSystemTypeForm !== undefined && (
        <SystemTypeForm
          systemSettingId={parentId}
          categoryId={showSystemTypeForm.categoryId}
          closeModal={(success) => {
            success && showSystemTypeForm.onClose()
            setShowSystemTypeForm(undefined)
          }}
          typeCode={showSystemTypeForm.typeCode}
          show={true}
        />
      )}
    </>
  )
}

export default DocValidationList
