import * as React from 'react'
import { useEffect, BaseSyntheticEvent } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BooleanColumn } from 'src/components/TableColumns/Columns'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { queryClient } from 'src/query/client'
import { useProjectUserDefinedFields } from 'src/query/metadata'
import { useSystemTypeGroup } from 'src/query/systemTypeGroups'
import { ISystemTypeGroup } from 'src/service/OrgTypes'
import {
  createSystemTypeGroup,
  editSystemTypeGroup,
} from 'src/service/SystemTypeGroupService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISystemTypeGroupProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  systemTypeGroupId?: number
  domain: string
  subDomain: string
}

export interface ISystemGroupFields {
  id?: number
  name: string
  project_id: number
  required_fields: number[]
  optional_fields: number[]
  domain: string
}

const SystemTypeGroupForm: React.FC<ISystemTypeGroupProps> = ({
  projectId,
  closeModal,
  editingMode,
  systemTypeGroupId,
  domain,
  subDomain,
}) => {
  const { t } = useTranslation()

  const { data: systemTypeGroup } = useSystemTypeGroup(systemTypeGroupId ?? 0, {
    enabled: !!systemTypeGroupId,
  })
  const { data: fields = [], isFetching } = useProjectUserDefinedFields()

  const { control, reset, handleSubmit, watch, setValue } =
    useForm<ISystemTypeGroup>({
      defaultValues: { optional_fields: [], required_fields: [] },
    })

  useEffect(() => {
    if (!systemTypeGroup) return
    reset({ ...systemTypeGroup })
  }, [systemTypeGroup])

  const submitSystemTypeGroup = async (
    formData: ISystemTypeGroup,
    e: BaseSyntheticEvent,
  ) => {
    e.preventDefault()
    const data: ISystemGroupFields = {
      name: formData.name,
      project_id: projectId,
      required_fields: formData.required_fields.map((field) => field.id!),
      optional_fields: formData.optional_fields.map((field) => field.id!),
      domain: subDomain,
    }

    if (systemTypeGroupId) {
      const updateData: ISystemGroupFields = {
        ...data,
        id: systemTypeGroupId,
      }
      await editSystemTypeGroup(updateData)
    } else {
      await createSystemTypeGroup(projectId, data)
    }
    await queryClient.invalidateQueries({
      queryKey: ['systemTypeGroups', projectId, domain],
    })
    closeModal()
  }

  const possibleValues = (dataType: string, pattern: string[]) => {
    switch (dataType) {
      case 'boolean':
        return t('boolean')
      case 'string':
        return t('string')
      case 'date':
        return t('date')
      case 'time':
        return t('date')
      case 'enum':
        return pattern.join(', ')
      case 'multi_enum':
        return pattern.join(', ')
      default:
        return ''
    }
  }

  const toggleIsRequired = (
    updatedField: IMetaField,
    setRequired: boolean,
    type: 'optional_fields' | 'required_fields',
  ) => {
    const fieldsForType = watch(type).filter(
      (field: IMetaField) => setRequired || field.id !== updatedField.id!,
    )
    setValue(type, [...fieldsForType, ...(setRequired ? [updatedField] : [])])
  }

  const updateRequiredFields = (id: number, value: boolean) => {
    const oldField = fields.find((field) => field.id === id)
    if (!oldField) return
    const updatedField = {
      ...oldField,
      is_required: value,
    }
    toggleIsRequired(updatedField, !value, 'optional_fields')
    toggleIsRequired(updatedField, value, 'required_fields')
  }

  const columns: IListColumns[] = [
    {
      name: 'field_name',
      size: '300',
      id: 'name',
      dataField: 'name',
    },
    BooleanColumn(
      'is_required',
      'is_required',
      '100',
      updateRequiredFields,
      false,
      domain !== 'FileContainer',
      true,
    ),
    {
      name: 'data_type',
      size: '150',
      id: 'data_type',
      dataField: 'data_type',
      cell: (data_type: string) => <span>{t(data_type)}</span>,
    },
    {
      name: 'values',
      size: '300',
      id: 'possible_values',
      dataField: 'pattern||data_type',
      cell: (data: { pattern: string[]; data_type: string }) => (
        <span className="truncate">
          {possibleValues(data.data_type, data.pattern)}
        </span>
      ),
    },
    {
      name: 'default_value',
      size: '300',
      id: 'default_value',
      dataField: 'default_value||default_value_list||data_type',
      cell: (data: {
        data_type: string
        default_value: string
        default_value_list: string
      }) => {
        return (
          <span className="truncate">
            {data.data_type === 'boolean'
              ? data.default_value === 'true'
                ? t('yes')
                : data.default_value === 'false'
                  ? t('no')
                  : ''
              : data.data_type === 'multi_enum'
                ? `${data.default_value_list}`
                : data.default_value
                  ? `${data.default_value}`
                  : ''}
          </span>
        )
      },
    },
  ]

  const swapSequence = (
    index: number,
    seq: number,
    fieldType: 'optional_fields' | 'required_fields',
  ) => {
    const selectedFields = watch(fieldType)
    const selectedField = selectedFields?.[index]
    selectedFields.splice(index, 1)
    selectedFields.splice(seq, 0, selectedField)
    setValue(fieldType, selectedFields)
  }

  const userDefinedFieldsList = (
    fieldType: 'optional_fields' | 'required_fields',
  ) => {
    return (
      <div className="py-2">
        <p className="pl-2 block font-medium text-sm leading-5 text-gray-700 my-2 font-roboto">
          {t(fieldType)}
        </p>
        <div className="pl-2">
          <List
            key={isFetching ? 0 : 1}
            data={watch(fieldType)}
            columns={columns}
            tableName={fieldType}
            itemsPerPage={0}
            disableConfigStore
            disableColumnSelector
            isRowDraggable
            swapOrder={(index, seq) => swapSequence(index, seq, fieldType)}
          />
        </div>
      </div>
    )
  }

  const userDefinedFieldIds = () => {
    const optionalFields = watch('optional_fields')
    const requiredFields = watch('required_fields')
    return [...(optionalFields || []), ...(requiredFields || [])].map(
      (field) => field?.id,
    )
  }

  const addField = (ids: number[]) => {
    const newFields = fields.filter(
      (field) =>
        ids.includes(field.id!) && !userDefinedFieldIds().includes(field.id),
    )
    setValue('optional_fields', [...watch('optional_fields'), ...newFields])
  }

  const removeField = (
    fieldType: 'optional_fields' | 'required_fields',
    ids: number[],
  ) => {
    setValue(
      fieldType,
      watch(fieldType).filter((field: IMetaField) => ids.includes(field.id!)),
    )
  }

  return (
    <form
      className={'text-left flex flex-col'}
      style={{ minHeight: '100px' }}
      onSubmit={handleSubmit(submitSystemTypeGroup)}
    >
      <Controller
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            block={true}
            label={t('name')}
            onChange={onChange}
            value={value}
            required
            errorMessage={error?.message}
          />
        )}
        control={control}
        rules={{ required: t('fill_out_with_param', { param: t('title') }) }}
        name={'name'}
      />

      <div className={'w-full flex px-2'}>
        <MultiSelector
          items={fields}
          onSelect={(ids) => {
            if (ids.length > userDefinedFieldIds().length)
              addField(ids as number[])
            else {
              removeField('optional_fields', ids as number[])
              removeField('required_fields', ids as number[])
            }
          }}
          label={t('user_defined_fields')}
          selectedItems={userDefinedFieldIds()}
          dataFields={['name']}
          fontWeight={'bold'}
          noBorder={true}
          scroll={false}
          bgColor={'white'}
        />
      </div>
      {domain === 'FileContainer' && userDefinedFieldsList('required_fields')}
      {userDefinedFieldsList('optional_fields')}

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY}>{t('update')}</Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY}>{t('add')}</Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default SystemTypeGroupForm
