import { ICategory } from 'src/components/grid/Grid/types'
import {
  ISystem,
  ISystemConfig,
  ISystemResponse,
} from 'src/components/grid/Grid/types/ISystem'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import {
  INewRequirement,
  INewRequirementMultipleFromDocument,
  IRequirement,
  IRequirementFiltered,
  IRequirementResponse,
  IStandardRequirement,
  IStandardRequirementFiltered,
  IUpdatedRequirement,
  ISystemGroups,
} from 'src/fdvu/types/IRequirement'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import { ISorting } from 'src/ui-elements/list/ListTypes'
import { IExport, IPaginateResult, IUserData } from '../../service/OrgTypes'
import { cloneObj } from '../../utility/utils'
import { ISystemFilter } from '../store/SystemFilterStore'
import { IFdvuFileContainer } from '../types/IFdvuFileContainer'
import {
  IFdvuFlow,
  IFdvuFlowDetails,
  IFdvuStepData,
  INewFdvuStep,
} from '../types/IFdvuFlow'
import { FlowStepStatusEnum } from '../types/IRequirementHistory'
import { GenericFdvuService } from './GenericFdvuService'

class FdvuService extends GenericFdvuService {
  readonly getAllSystemsForProject = () => {
    const url = this.getUrl('system/list')
    return this.doRequest<ISystem[]>({
      url: url,
      method: 'GET',
    })
  }

  readonly getSystemsAndSystemsConfigWithFilter = (
    systemFilter: ISystemFilter['filters'],
    page: number,
    pageSize: number,
  ) => {
    const url = this.getUrl(`system/pages/${page}/${pageSize}`)
    return this.doRequest<ISystemResponse>({
      url: url,
      method: 'POST',
      body: systemFilter,
    })
  }

  readonly getSystemsAndSystemsConfigCollapsed = (
    parentSystemId: number | null = null,
    expandedSystemIds: number[] = [],
  ) => {
    const urlParams = new URLSearchParams()
    if (parentSystemId) {
      urlParams.append('parentSystemId', parentSystemId.toString())
    }
    if (expandedSystemIds.length > 0) {
      this.urlParamsFromList('expandedSystemId', expandedSystemIds, urlParams)
    }
    const url = this.getUrl('system/collapsed?') + urlParams
    return this.doRequest<ISystemResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly getSystemsAndSystemsConfigByIds = (systemIds: number[]) => {
    const urlParams = this.urlParamsFromList('systemId', systemIds)
    const url = this.getUrl('system/system-ids?') + urlParams
    return this.doRequest<ISystemResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly getSystemsConfigByIds = (systemIds: number[]) => {
    const urlParams = this.urlParamsFromList('systemId', systemIds)
    const url = this.getUrl('system/config/system-ids?') + urlParams
    return this.doRequest<Record<number, ISystemConfig>>({
      url: url,
      method: 'GET',
    })
  }

  readonly getSystemsConfigForStandardRequirement = (
    requirementInfoId: string,
  ) => {
    const url = this.getUrl(
      `system/config/standard-requirement/${requirementInfoId}`,
    )
    return this.doRequest<Record<number, ISystemConfig>>({
      url: url,
      method: 'GET',
    })
  }

  readonly getAllRequirementCategories = () => {
    const url = this.getUrl('requirements/categories')
    return this.doRequest<ICategory[]>({
      url: url,
      method: 'GET',
    })
  }

  readonly createRequirementCategory = (name: string) => {
    const url = this.getUrl('requirements/categories')
    return this.doRequest<ICategory>({
      url: url,
      method: 'POST',
      body: { name },
    })
  }

  readonly updateRequirementCategory = (categoryId: string, name: string) => {
    const url = this.getUrl(`requirements/categories/category-id/${categoryId}`)
    return this.doRequest<ICategory>({
      url: url,
      method: 'PATCH',
      body: { name },
    })
  }

  readonly deleteRequirementCategory = (categoryId: string) => {
    const url = this.getUrl(`requirements/categories/category-id/${categoryId}`)
    return this.doRequest({
      url: url,
      method: 'DELETE',
      text: true,
    })
  }

  readonly getAllRequirementsWithPageAndFilter = (
    page: number,
    pageSize: number,
    filters: Record<string, unknown>,
    sorting?: ISorting,
  ) => {
    const url = this.getOrgUrl(`requirements/pages/${page}/${pageSize}`)

    return this.doRequest<IPaginateResult<IRequirementFiltered>>({
      url: url,
      method: 'POST',
      body: {
        sort: sorting,
        descending: sorting ? String(sorting.direction) : undefined,
        ...filters,
      },
    })
  }

  readonly getSingleRequirement = (requirementId: number) => {
    const url = this.getUrl(`requirements/requirement-id/${requirementId}`)
    return this.doRequest<IRequirement>({
      url: url,
      method: 'GET',
    })
  }

  readonly getRequirementsForSystem = (systemId: number) => {
    const url = this.getUrl(`requirements/system-id/${systemId}`)
    return this.doRequest<IRequirementResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly getRequirementsForSystemAndCategory = (
    systemId: number,
    categoryId: string,
  ) => {
    const url = this.getUrl(
      `requirements/system-id/${systemId}/category-id/${categoryId}`,
    )
    return this.doRequest<IRequirement[]>({
      url: url,
      method: 'GET',
    })
  }

  readonly getRequirementsForMultipleSystemsAndMultipleCategories = (
    systemIds: number[],
    categoryIds?: string[],
  ) => {
    const urlParams = this.urlParamsFromList('systemId', systemIds)
    if (categoryIds) {
      this.urlParamsFromList('categoryId', categoryIds, urlParams)
    }
    const url = this.getUrl(`requirements/multiple-systems?${urlParams}`)
    return this.doRequest<IRequirementResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly getAllStandardRequirements = () => {
    const url = this.getUrl('standard-requirements')
    return this.doRequest<IStandardRequirementFiltered[]>({
      url: url,
      method: 'GET',
    })
  }

  readonly getAllStandardRequirementsPaginated = (
    page: number,
    pageSize: number,
    filters: Record<string, string[]>,
    sorting: ISorting,
  ) => {
    const url = this.getUrl(`standard-requirements/pages/${page}/${pageSize}`)
    return this.doRequest<IPaginateResult<IStandardRequirementFiltered>>({
      url: url,
      method: 'POST',
      body: {
        sort: sorting.field.length ? sorting.field : undefined,
        descending: String(sorting.direction),
        ...filters,
      },
    })
  }

  readonly getRequirementsByRequirementIds = (requirementIds: string[]) => {
    const urlParams = this.urlParamsFromList('requirementId', requirementIds)
    const url = this.getUrl(`requirements/requirement-ids?` + urlParams)
    return this.doRequest<IRequirementResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly getRequirementsForStandardRequirement = (
    standardRequirementId: number,
  ) => {
    const url = this.getUrl(
      `standard-requirements/standard-requirement-id/${standardRequirementId}/requirements/grid`,
    )
    return this.doRequest<IRequirementResponse>({
      url: url,
      method: 'GET',
    })
  }

  readonly createRequirement = (newRequirement: INewRequirement) => {
    const url = this.getUrl(
      `requirements?createSingleRequirement=${newRequirement.createSingleRequirement ?? false}`,
    )
    return this.doRequest<IRequirement[]>({
      url: url,
      method: 'POST',
      body: newRequirement,
    })
  }

  readonly duplicateRequirement = (requirementId: number) => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}/duplicate`,
    )
    return this.doRequest<IRequirement[]>({
      url: url,
      method: 'POST',
    })
  }

  getStandardRequirement = (standardRequirementId: number) => {
    const url = this.getUrl(
      `standard-requirements/standard-requirement-id/${standardRequirementId}`,
    )
    return this.doRequest<IStandardRequirement>({
      url,
      method: 'GET',
    })
  }

  createStandardRequirement = (
    standardRequirement: Omit<INewRequirement, 'systemIds'>,
    systemGroups: ISystemGroups,
    systemSyntaxFilters: IFilterObject[],
  ) => {
    const url = this.getUrl('standard-requirements')
    return this.doRequest<IStandardRequirement>({
      url,
      method: 'POST',
      body: { standardRequirement, systemGroups, systemSyntaxFilters },
    })
  }

  readonly updateRequirement = (
    requirementId: IRequirement['id'],
    updatedRequirement: Partial<IUpdatedRequirement>,
  ) => {
    const url = this.getUrl(`requirements/requirement-id/${requirementId}`)
    return this.doRequest<IRequirement>({
      url: url,
      method: 'PATCH',
      body: updatedRequirement,
    })
  }

  readonly updateStandardRequirement = (
    standardRequirementId: number,
    updatedRequirement: Partial<IUpdatedRequirement>,
  ) => {
    const url = this.getUrl(
      `standard-requirements/standard-requirement-id/${standardRequirementId}`,
    )
    return this.doRequest<IStandardRequirement>({
      url: url,
      method: 'PATCH',
      body: updatedRequirement,
    })
  }

  readonly deleteRequirement = (
    requirementId: number,
    removeFileContainerSystemConnection: boolean = false,
  ) => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}?removeFileContainerSystemConnection=${removeFileContainerSystemConnection}`,
    )
    return this.doRequest({
      url: url,
      method: 'DELETE',
    })
  }

  readonly deleteMultipleRequirements = (
    requirementIds: number[],
    removeFileContainerSystemConnection: boolean = false,
  ) => {
    const url = this.getUrl(
      `requirements/multiple?removeFileContainerSystemConnection=${removeFileContainerSystemConnection}`,
    )
    return this.doRequest({
      url: url,
      method: 'DELETE',
      body: requirementIds,
    })
  }

  readonly deleteStandardRequirement = (
    standardRequirementId: number,
    removeFileContainerSystemConnection: boolean = false,
  ) => {
    const url = this.getUrl(
      `standard-requirements/standard-requirement-id/${standardRequirementId}?removeFileContainerSystemConnection=${removeFileContainerSystemConnection}`,
    )
    return this.doRequest({
      url: url,
      method: 'DELETE',
    })
  }

  readonly deleteRequirementsForStandardRequirement = (
    standardRequirementId: number,
    systemids: number[],
  ) => {
    const url = this.getUrl(
      `standard-requirements/standard-requirement-id/${standardRequirementId}/requirements`,
    )
    return this.doRequest({
      url: url,
      method: 'DELETE',
      body: { systemids },
    })
  }

  readonly getFdvuFlows = () => {
    const url = this.getUrl('flows')
    return this.doRequest<IFdvuFlow[]>({ url, method: 'GET' })
  }

  readonly getFdvuFlow = (flowId: number) => {
    const url = this.getUrl(`flows/flow-id/${flowId}`)
    return this.doRequest<IFdvuFlowDetails>({ url, method: 'GET' })
  }

  readonly createFdvuFlow = (
    name: string,
    submissionStep: INewFdvuStep,
    reviewSteps: INewFdvuStep[],
    approvalStep: INewFdvuStep,
  ) => {
    const url = this.getUrl('flows')
    return this.doRequest<IFdvuFlowDetails>({
      url,
      method: 'POST',
      body: {
        name,
        submissionStep,
        reviewSteps,
        approvalStep,
      },
    })
  }

  readonly deleteFdvuFlow = (flowId: number) => {
    const url = this.getUrl(`flows/flow-id/${flowId}`)
    return this.doRequest({
      url,
      method: 'DELETE',
      text: true,
    })
  }

  readonly assignUserToFlowStep = (
    userId: number,
    stepId: number,
    contractIds: number[],
  ) => {
    const url = this.getUrl(`flows/steps/step-id/${stepId}/assign-user`)
    return this.doRequest<IFdvuStepData>({
      url,
      method: 'POST',
      body: { userId, contractIds },
    })
  }

  readonly assignMultipleUsersToFlowStep = (
    userIds: number[],
    removedUserIds: number[],
    stepId: number,
    contractIds: number[],
    removePreviouslyAssignedUsers: boolean,
  ) => {
    const url = this.getUrl(
      `flows/steps/step-id/${stepId}/assign-multiple-users`,
    )
    return this.doRequest<IFdvuStepData>({
      url,
      method: 'POST',
      body: {
        userIds,
        removedUserIds,
        contractIds,
        removePreviouslyAssignedUsers,
      },
    })
  }

  readonly assignAllUsersToFlowStep = (
    stepId: number,
    userMap: Record<number, IUserData[]>,
  ) => {
    const url = this.getUrl(`flows/steps/step-id/${stepId}/assign-all-users`)
    return this.doRequest<Record<number, IUserData[]>>({
      url,
      method: 'POST',
      body: { userMap },
    })
  }

  readonly removeAssignedUserForFlowStep = (
    userId: number,
    stepId: number,
    contractIds: number[],
  ) => {
    const urlParams = this.urlParamsFromList('contractId', contractIds)
    const url = this.getUrl(
      `flows/steps/step-id/${stepId}/user-id/${userId}?` + urlParams,
    )
    return this.doRequest<IFdvuStepData>({
      url,
      method: 'DELETE',
    })
  }

  readonly getFileContainer = (fileContainerId: number) => {
    const url = this.getUrl(
      `file-container/file-container-id/${fileContainerId}`,
    )
    return this.doRequest<IFdvuFileContainer>({ url, method: 'GET' })
  }

  readonly handleStep = (
    requirementId: number,
    stepId: number,
    status: FlowStepStatusEnum,
    reason?: string,
  ) => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}/step-id/${stepId}`,
    )
    return this.doRequest<IRequirement>({
      url,
      method: 'PATCH',
      body: { status, reason },
    })
  }

  readonly undoStep = (requirementId: number, stepId: number) => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}/step-id/${stepId}/undo`,
    )
    return this.doRequest<IRequirement>({
      url,
      method: 'PATCH',
    })
  }

  readonly createRequirementFromDocument = (
    newRequirement: INewRequirement,
    documentId: number,
  ) => {
    const url = this.getUrl(`requirements/file-container/${documentId}`)
    return this.doRequest<INewRequirement>({
      url,
      method: 'POST',
      body: newRequirement,
    })
  }

  readonly createMultipleRequirementsFromDocuments = (
    newRequirements: INewRequirementMultipleFromDocument[],
  ) => {
    const url = this.getUrl(`requirements/multiple/file-containers`)
    return this.doRequest<IRequirement[]>({
      url,
      method: 'POST',
      body: newRequirements,
    })
  }

  readonly connectRequirementsToFileContainer = (
    fileContainerId: number,
    requirementIds: number[],
  ) => {
    const url = this.getUrl(
      `file-container/file-container-id/${fileContainerId}/connect-requirements`,
    )
    return this.doRequest<IFdvuFileContainer>({
      url,
      method: 'POST',
      body: { requirementIds },
    })
  }

  readonly getSubcontractor = (subcontractorId: string) => {
    const url = this.getUrl(`subcontractors/${subcontractorId}`)
    return this.doRequest<ISubcontractor>({
      url,
      method: 'GET',
    })
  }

  readonly getProjectSubcontractors = () => {
    const url = this.getUrl(`subcontractors`)
    return this.doRequest<ISubcontractor[]>({
      url,
      method: 'GET',
    })
  }

  readonly createSubcontractor = (
    name: string,
    email: string,
    accessExpire: Date,
  ) => {
    const url = this.getUrl(`subcontractors`)
    return this.doRequest<ISubcontractor>({
      url,
      method: 'POST',
      body: { name, email, accessExpire },
    })
  }

  readonly updateSubcontractor = (
    subcontractorId: string,
    subcontractor: Partial<ISubcontractor>,
  ) => {
    const url = this.getUrl(`subcontractors/${subcontractorId}`)
    return this.doRequest<ISubcontractor>({
      url,
      method: 'PATCH',
      body: subcontractor,
    })
  }
  readonly deleteSubcontractor = (subcontractorId: string) => {
    const url = this.getUrl(`subcontractors/${subcontractorId}`)
    return this.doRequest({
      url,
      method: 'DELETE',
      text: true,
    })
  }

  readonly setSubcontractorsForFilecontainer = (
    filecontainerId: number,
    subcontractorIds: string[] | null,
  ) => {
    const url = this.getUrl(
      `subcontractors/file-container-id/${filecontainerId}`,
    )
    return this.doRequest<void>({
      url,
      method: 'PATCH',
      body: { subcontractorIds },
    })
  }

  readonly removeFileContainersFromSubcontractor = (
    subcontractorId: string,
    fileContainerIds: number[],
  ) => {
    const url = this.getUrl(
      `subcontractors/subcontractor-id/${subcontractorId}/remove`,
    )
    return this.doRequest({
      url,
      method: 'PATCH',
      body: { fileContainerIds },
    })
  }

  readonly assignFileContainersToSubcontractor = (
    subcontractorId: string,
    fileContainerIds: number[],
  ) => {
    const url = this.getUrl(
      `subcontractors/subcontractor-id/${subcontractorId}`,
    )
    return this.doRequest({
      url,
      method: 'PATCH',
      body: { fileContainerIds },
    })
  }

  readonly getSubcontractorAccessToken = (subcontractorId: string) => {
    const url = this.getUrl(
      `subcontractors/subcontractor-id/${subcontractorId}/access-token`,
    )
    return this.doRequest({
      url,
      method: 'GET',
      text: true,
    })
  }

  readonly updateFdvuFlowName = (flowId: number, name: string) => {
    const url = this.getUrl(`flows/flow-id/${flowId}/name`)
    return this.doRequest<IFdvuFlowDetails>({
      url,
      method: 'PATCH',
      body: { name },
    })
  }

  readonly getFdvuExportTemplate = (fdvuExport: IExport) => {
    const template = cloneObj(fdvuExport)
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{ApiUrl}',
      this.getUrl(),
    )
    return template
  }

  readonly getProjectFdvuFolderId = (projectId: number) => {
    const url = this.getUrl(`project/${projectId}/fdvu-folder-id`)
    return this.doRequest<string>({
      url,
      method: 'GET',
    })
  }

  readonly setProjectFdvuFolder = (
    projectId: number,
    folderId: number | null,
  ) => {
    const url = this.getUrl(`project/fdvu-folder-id`)
    return this.doRequest({
      url,
      method: 'PATCH',
      body: { folderId },
    })
  }

  readonly removeSystemsFromRequirement = (
    requirementId: number,
    systemIds: number[],
    removeFileContainersFromSystems: boolean = false,
  ): Promise<IRequirement> => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}/remove-systems?removeFileContainersFromSystems=${removeFileContainersFromSystems}`,
    )
    return this.doRequest({
      url,
      method: 'PATCH',
      body: systemIds,
    })
  }

  readonly addSystemsToRequirement = (
    requirementId: number,
    systemIds: number[],
  ): Promise<IRequirement> => {
    const url = this.getUrl(
      `requirements/requirement-id/${requirementId}/add-systems`,
    )
    return this.doRequest({
      url,
      method: 'PATCH',
      body: systemIds,
    })
  }
}

export default new FdvuService()
