import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import { getProjectContracts } from 'src/service/ContractService'
import {
  getControlAreaGroupsControlAreas,
  getProjectControlAreaGroups,
  getProjectControlAreas,
} from 'src/service/ControlAreaService'
import { getProjectContractDisciplines } from 'src/service/DisciplineService'
import {
  IContract,
  IControlArea,
  IControlAreaGroup,
  IDiscipline,
  IMainProcess,
  ITag,
  ITeam,
  FloatingFilterTableKeeperTypes,
} from 'src/service/OrgTypes'
import { getMainProcesses } from 'src/service/ProcessService'
import { getProjectTags } from 'src/service/TagService'
import { getProjectTeams } from 'src/service/TeamService'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import { renderDayContents } from 'src/utility/Utility'
import { capFirstLetter } from '../../utility/utils'
import {
  IFiltersData,
  ISelectedFilters,
  FloatingFilterStyles,
} from '../KPI/KPIUtils'
import { FloatingFilterstyleClass } from '../canvas-filter/CanvasFloatingFilter'
import FilterIcon from '../canvas-header/FilterIcon'
import HelperIcons from '../canvas-header/HelperIcons'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import useDidMountEffect from '../hooks/UseDidMountEffect'
import MultiSelector, { Direction } from '../multi-selector/MultiSelector'

interface IFloatingFilterProps {
  filter: IFiltersData
  filterName: FloatingFilterTableKeeperTypes
  projectId: number
  extendedFilter?: boolean
  hideProjectSelector?: boolean
  inConstruction?: boolean
  onDataChange?: (d: ISelectedFilters) => void
  onFilterClear?: () => void
  onFiltersChange?: () => void
}

const styleClass = FloatingFilterstyleClass()
const filterStyles = FloatingFilterStyles

const FloatingFilterTableKeeper: React.FC<IFloatingFilterProps> = ({
  projectId,
  filter,
  onFiltersChange,
  onDataChange,
  onFilterClear,
  filterName,
}: IFloatingFilterProps) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedView,
    setSelectedView,
    selectedDisciplines,
    setSelectedDisciplines,
    selectedContracts,
    setSelectedContracts,
    selectedControlAreaGroups,
    setSelectedControlAreaGroups,
    selectedControlAreas,
    setSelectedControlAreas,
    selectedProcesses,
    setSelectedProcesses,
    selectedThemes,
    setSelectedThemes,
    selectedTags,
    setSelectedTags,
  } = filter
  const [fInput, setFInput] = useState<'startDate' | 'endDate' | null>(null)

  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [contracts, setContracts] = useState<IContract[]>([])
  const [isdisciplineFirst, setIsDisciplineFirst] = useState<boolean>(true)
  const [isControlAreaGroupFirst, setIsControlAreaGroupFirst] =
    useState<boolean>(true)
  const [isControlAreaFirst, setIsControlAreaFirst] = useState<boolean>(true)
  const [isProcessFirst, setIsProcessFirst] = useState<boolean>(true)
  const [isThemesFirst, setIsThemesFirst] = useState<boolean>(true)
  const [isTagsFirst, setIsTagsFirst] = useState<boolean>(true)

  const [controlAreaGroups, setControlAreaGroups] = useState<
    IControlAreaGroup[]
  >([])
  const [controlAreas, setControlAreas] = useState<IControlArea[]>([])

  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [controlAreaGroupLoading, setControlAreaGroupLoading] =
    useState<boolean>(false)
  const [controlAreaLoading, setcontrolAreaLoading] = useState<boolean>(false)
  const [processLoading, setProcessLoading] = useState<boolean>(false)
  const [themesLoading, setThemesLoading] = useState<boolean>(false)
  const [tagsLoading, setTagsLoading] = useState<boolean>(false)

  const [processes, setProcesses] = useState<IMainProcess[]>([])
  const [themes, setThemes] = useState<ITeam[]>([])
  const [tags, setTags] = useState<ITag[]>([])
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const [fetchedFromTableKeeper, setFetchedFromTableKeeper] =
    useState<boolean>(false)
  const initState = useRef({
    startDate: startDate,
    endDate: endDate,
    selectedContractsIds: [],
    selectedDisplineIds: [],
    selectedViewId: selectedView,
    selectedProcessIds: [],
    selectedThemaIds: [],
    selectedTagIds: [],
    selectedControlAreaIds: [],
  })

  const resetValues = () => {
    setIsDisciplineFirst(true)
    setIsControlAreaGroupFirst(true)
    setIsControlAreaFirst(true)
    setIsProcessFirst(true)
    setIsThemesFirst(true)
    setIsTagsFirst(true)
    setSelectedContracts?.([])
    setSelectedDisciplines?.([])
    setSelectedControlAreaGroups?.([])
    setSelectedControlAreas?.([])
    setSelectedProcesses?.([])
    setSelectedThemes?.([])
    setSelectedTags?.([])
  }

  const handleFiltersChange = () => onFiltersChange && onFiltersChange()

  useEffect(() => {
    resetValues()
    getContracts()
    loadStoredFilter()
  }, [projectId])

  useEffect(() => {
    if (fetchedFromTableKeeper) {
      handleFiltersChange()
      setFilterToTableKeeper({ selectedViewId: selectedView })
    }
  }, [fetchedFromTableKeeper, selectedView])

  useDidMountEffect(() => {
    if (onDataChange) {
      onDataChange({
        contractNums: getContractNums(),
        disciplineNames: getDisciplineNames(),
      })
    }
  }, [
    contracts,
    disciplines,
    selectedDisciplines,
    selectedContracts,
    selectedControlAreaGroups,
    selectedControlAreas,
    selectedProcesses,
    selectedThemes,
    selectedView,
  ])

  const setFilterToTableKeeper = (filterData: any) => {
    const currentFilterValue = {
      startDate: startDate,
      endDate: endDate,
      selectedViewId: selectedView,
      selectedProcessIds: selectedProcesses,
      selectedControlAreaIds: selectedControlAreas,
      selectedContractsIds: selectedContracts,
      selectedDisplineIds: selectedDisciplines,
      selectedThemaIds: selectedThemes,
      selectedTagIds: selectedTags,
    }
    setFilterValues(projectId, filterName, {
      ...currentFilterValue,
      ...filterData,
    })
    if (!filterData.selectedViewId) {
      setFiltersApplied(true)
    }
  }

  const onClear = () => {
    resetValues()
    setFiltersApplied(false)
    setStartDate?.(moment(initState.current.startDate))
    setEndDate?.(moment(initState.current.endDate))
    if (onFilterClear) {
      onFilterClear()
    }

    handleFiltersChange()

    setFilterValues(projectId, filterName, {
      ...initState.current,
      selectedViewId: selectedView,
    })
  }

  const loadStoredFilter = async () => {
    const storedFilter = await getFilterValues(projectId, filterName)
    const filter =
      storedFilter && !Array.isArray(storedFilter)
        ? storedFilter
        : initState.current

    setStartDate(
      moment(filter.startDate ? filter.startDate : initState.current.startDate),
    )
    setEndDate(
      moment(filter.endDate ? filter.endDate : initState.current.endDate),
    )
    setSelectedView?.(
      filter.selectedViewId
        ? filter.selectedViewId
        : initState.current.selectedViewId,
    )
    setSelectedDisciplines?.(
      filter.selectedDisplineIds
        ? filter.selectedDisplineIds
        : initState.current.selectedDisplineIds,
    )
    setSelectedProcesses?.(
      filter.selectedProcessIds
        ? filter.selectedProcessIds
        : initState.current.selectedProcessIds,
    )
    setSelectedThemes?.(
      filter.selectedThemaIds
        ? filter.selectedThemaIds
        : initState.current.selectedThemaIds,
    )
    setSelectedTags?.(
      filter.selectedTagIds
        ? filter.selectedTagIds
        : initState.current.selectedTagIds,
    )
    setSelectedControlAreas?.(
      filter.selectedControlAreaIds
        ? filter.selectedControlAreaIds
        : initState.current.selectedControlAreaIds,
    )
    setSelectedContracts?.(
      filter.selectedContractsIds
        ? filter.selectedContractsIds
        : initState.current.selectedContractsIds,
    )

    if (filter?.selectedDisplineIds?.length > 0) {
      getDisciplines(filter.selectedContractsIds)
    }

    if (
      !moment(startDate).isSame(filter.startDate, 'day') ||
      !moment(endDate).isSame(filter.endDate, 'day') ||
      filter.selectedContractsIds?.length > 0 ||
      filter.selectedDisplineIds?.length > 0 ||
      filter.selectedProcessIds > 0 ||
      filter.selectedThemaIds?.length > 0 ||
      filter.selectedTagIds?.length > 0
    ) {
      setFiltersApplied(true)
    }
    setFetchedFromTableKeeper(true)
  }

  const getContracts = async () => {
    const allContracts = await getProjectContracts(projectId)
    setContracts(allContracts)
  }

  const getDisciplines = async (contractIds: number[]) => {
    const allDisciplines = await getProjectContractDisciplines(projectId, {
      contract: contractIds,
    })
    setDisciplines(allDisciplines)
    setIsDisciplineFirst(false)
  }

  const getContractNums = () => {
    if (selectedContracts) {
      const selectedContractObjs: IContract[] = contracts.filter(
        (contract) => selectedContracts.indexOf(contract.id) >= 0,
      )
      if (selectedContractObjs.length === contracts.length) {
        return 'all'
      }
      return contracts
        .filter((contract) => selectedContracts.indexOf(contract.id) >= 0)
        .map((contract) => contract.contractNumber)
        .join(', ')
    }
    return ''
  }

  const getDisciplineNames = () => {
    if (selectedDisciplines) {
      const selectedDisciplineObjs: IDiscipline[] = disciplines.filter(
        (disp) => selectedDisciplines.indexOf(disp.id) >= 0,
      )
      if (selectedDisciplineObjs.length === disciplines.length) {
        return 'all'
      }

      if (selectedDisciplineObjs.length > 3) {
        return (
          [...selectedDisciplineObjs]
            .slice(0, 3)
            .map((disp) => disp.shortName)
            .join(', ') + ',...'
        )
      }
      return [...selectedDisciplineObjs]
        .map((disp) => disp.shortName)
        .join(', ')
    }
    return ''
  }

  const onChangeDate = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    handleFiltersChange()
    setFilterToTableKeeper({
      startDate: startDate ? startDate : startDate,
      endDate: endDate ? endDate : endDate,
    })
    if (startDate) setStartDate(startDate)
    if (endDate) setEndDate(endDate)
  }

  const onOpenDiscipline = () => {
    return new Promise<void>(async (resolve) => {
      if (isdisciplineFirst && selectedContracts) {
        setDisciplineLoading(true)
        const allDisciplines = await getProjectContractDisciplines(projectId, {
          contract: selectedContracts,
        })
        setDisciplines(allDisciplines)
        setDisciplineLoading(false)
        setIsDisciplineFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenControlAreaGroup = () => {
    return new Promise<void>(async (resolve) => {
      if (isControlAreaGroupFirst) {
        setControlAreaGroupLoading(true)
        const allControlAreaGroups =
          await getProjectControlAreaGroups(projectId)
        setControlAreaGroups(allControlAreaGroups)
        setControlAreaGroupLoading(false)
        setIsControlAreaGroupFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenControlArea = () => {
    return new Promise<void>(async (resolve) => {
      if (isControlAreaFirst) {
        setcontrolAreaLoading(true)
        let matchedControlAreas: IControlArea[] = []
        if (selectedControlAreaGroups?.length === 0) {
          matchedControlAreas = await getProjectControlAreas(projectId)
        } else if (selectedControlAreaGroups) {
          matchedControlAreas = await getControlAreaGroupsControlAreas(
            projectId,
            { control_area_group: selectedControlAreaGroups },
          )
        }
        if (controlAreaGroups.length === 0) {
          const allControlAreaGroups =
            await getProjectControlAreaGroups(projectId)
          setControlAreaGroups(allControlAreaGroups)
        }
        setControlAreas(matchedControlAreas)
        setcontrolAreaLoading(false)
        setIsControlAreaFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenProcess = () => {
    return new Promise<void>(async (resolve) => {
      if (isProcessFirst) {
        setProcessLoading(true)
        const allProcesses = await getMainProcesses(projectId)
        setProcesses(allProcesses)
        setProcessLoading(false)
        setIsProcessFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenThemes = () => {
    return new Promise<void>(async (resolve) => {
      if (isThemesFirst) {
        setThemesLoading(true)
        const allThemes = await getProjectTeams(projectId)
        setThemes(allThemes)
        setThemesLoading(false)
        setIsThemesFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenTags = () => {
    return new Promise<void>(async (resolve) => {
      if (isTagsFirst) {
        setTagsLoading(true)
        const allTags = await getProjectTags(projectId)
        setTags(allTags)
        setTagsLoading(false)
        setIsTagsFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onChangeContract = async (selectedids: number[]) => {
    setSelectedContracts?.(selectedids)
    setDisciplineLoading(true)
    const allDisciplines = await getProjectContractDisciplines(projectId, {
      contract: selectedids,
    })
    setDisciplines(allDisciplines)
    if (selectedids.length > 0) {
      const newSelectedDiscipline = allDisciplines.map(
        (discipline: any) => discipline.id,
      )
      setSelectedDisciplines?.(newSelectedDiscipline)
      handleFiltersChange()
      setFilterToTableKeeper({
        selectedContracts: selectedids,
        selectedDisciplines: newSelectedDiscipline,
      })
    } else {
      setSelectedDisciplines?.([])
      handleFiltersChange()
      setFilterToTableKeeper({
        selectedContracts: selectedids,
        selectedDisciplines: [],
      })
    }
    setDisciplineLoading(false)
  }

  const onChangeDiscipline = async (selectedids: number[]) => {
    setSelectedDisciplines?.(selectedids)
    handleFiltersChange()
    if (selectedids.length === 0) {
      setSelectedContracts?.([])
      const allDisciplines = await getProjectContractDisciplines(projectId, {
        contract: [],
      })
      setDisciplineLoading(true)
      setDisciplines(allDisciplines)
      setDisciplineLoading(false)
      return
    }
    const selectedContractIds = new Set<number>()
    const selectedDisciplineObj = disciplines.filter(
      (discipline) => selectedids.indexOf(discipline.id) !== -1,
    )
    contracts.filter((contract) => {
      selectedDisciplineObj.map((disp) => {
        if (disp.contract_id === contract.id) {
          selectedContractIds.add(contract.id)
        }
      })
    })
    const contractsByDiscipline = contracts
      .filter((contract: IContract) => selectedContractIds.has(contract.id))
      .map((contr) => contr.id)
    setSelectedContracts?.(contractsByDiscipline)
    setFilterToTableKeeper({
      selectedContracts: contractsByDiscipline,
      selectedDisciplines: selectedids,
    })
  }

  const onChangeControlAreaGroup = async (selectedids: number[]) => {
    setSelectedControlAreaGroups?.(selectedids)
    setcontrolAreaLoading(true)
    if (selectedids.length > 0) {
      const matchedControlAreas = await getControlAreaGroupsControlAreas(
        projectId,
        { control_area_group: selectedids },
      )
      setControlAreas(matchedControlAreas)
      const newSelectedControlArea = matchedControlAreas.map(
        (controlArea: any) => controlArea.id,
      )
      setSelectedControlAreas?.(newSelectedControlArea)
      handleFiltersChange()
      setFilterToTableKeeper({ selectedControlAreas: newSelectedControlArea })
    } else {
      setSelectedControlAreas?.([])
      setIsControlAreaFirst(true)
      handleFiltersChange()
      setFilterToTableKeeper({ selectedControlAreas: [] })
    }
    setcontrolAreaLoading(false)
  }

  const onChangeControlAreas = async (selectedids: number[]) => {
    setSelectedControlAreas?.(selectedids)
    handleFiltersChange()
    setFilterToTableKeeper({ selectedControlAreas: selectedids })
    if (selectedids.length === 0) {
      setSelectedControlAreaGroups?.([])
      const allControlAreas = await getControlAreaGroupsControlAreas(
        projectId,
        { control_area_group: [] },
      )
      setcontrolAreaLoading(true)
      setControlAreas(allControlAreas)
      setcontrolAreaLoading(false)
      setIsControlAreaFirst(true)
      return
    }
    const selectedContolAreaIds = new Set<number>()
    const selectedControlAreaObj = controlAreas.filter((item) => {
      return item.id ? selectedids.indexOf(item.id) !== -1 : []
    })
    controlAreaGroups.filter((controlAreaGroup) => {
      selectedControlAreaObj.map((controlArea) => {
        if (
          controlArea.control_area_group_id === controlAreaGroup.id &&
          controlAreaGroup.id
        ) {
          selectedContolAreaIds.add(controlAreaGroup.id)
        }
      })
    })
    const controlAreaGroupsByContractAreas = controlAreaGroups
      .filter((controlAreaGroup: IControlAreaGroup) =>
        controlAreaGroup.id
          ? selectedContolAreaIds.has(controlAreaGroup.id)
          : [],
      )
      .map((item) => (item.id ? item.id : 0))
    setSelectedControlAreaGroups?.(controlAreaGroupsByContractAreas)
  }

  const onChangeProcesses = (selectedIds: number[]) => {
    setSelectedProcesses?.(selectedIds)
    handleFiltersChange()
    setFilterToTableKeeper({ selectedProcesses: selectedIds })
  }

  const onChangeThemes = (selectedIds: number[]) => {
    setSelectedThemes?.(selectedIds)
    handleFiltersChange()
    setFilterToTableKeeper({ selectedThemes: selectedIds })
  }

  const onChangeTags = (selectedIds: number[]) => {
    setSelectedTags?.(selectedIds)
    handleFiltersChange()
    setFilterToTableKeeper({ selectedTags: selectedIds })
  }

  return (
    <CloseClickOutside onClose={() => setOpen(false)}>
      <HelperIcons className="top-16 right-5">
        <div className={`${styleClass.root()}`}>
          <FilterIcon
            open={open}
            setOpen={setOpen}
            filtersApplied={filtersApplied}
          />
          {open && (
            <div className={styleClass.filter}>
              <div className={styleClass.rootFilter}>
                <div className={'pl-5 pt-4 pb-5'}>
                  <div className={`${styleClass.filterSelector}`}>
                    <p className={filterStyles.filtersRow.label}>
                      {capFirstLetter(t('date_range'))}
                    </p>
                    <DateRangePicker
                      firstDayOfWeek={1}
                      startDate={startDate}
                      startDateId="date-start"
                      endDate={endDate}
                      endDateId="date-end"
                      onDatesChange={onChangeDate}
                      renderDayContents={renderDayContents}
                      focusedInput={fInput}
                      onFocusChange={setFInput}
                      block={true}
                      displayFormat={() =>
                        moment.localeData('no').postformat('DD.MM.YY')
                      }
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={true}
                      isOutsideRange={() => false}
                    />
                  </div>
                  {selectedControlAreaGroups && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={controlAreaGroups}
                        onOpenList={onOpenControlAreaGroup}
                        label={t('control_area_groups')}
                        hidelabel={false}
                        dataFields={['record_id', 'title']}
                        selectedItems={selectedControlAreaGroups}
                        fontWeight={'bold'}
                        onSelect={onChangeControlAreaGroup}
                        loading={controlAreaGroupLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                      />
                    </div>
                  )}
                  {selectedControlAreas && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={controlAreas}
                        onOpenList={onOpenControlArea}
                        label={t('control_area')}
                        hidelabel={false}
                        dataFields={['record_id', 'title']}
                        selectedItems={selectedControlAreas}
                        fontWeight={'bold'}
                        onSelect={onChangeControlAreas}
                        loading={controlAreaLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                      />
                    </div>
                  )}
                  {selectedContracts && (
                    <div className={styleClass.filterSelector}>
                      <div className={'w-full'}>
                        <MultiSelector
                          items={contracts}
                          onSelect={onChangeContract}
                          label={t('contracts')}
                          hidelabel={false}
                          selectedItems={selectedContracts}
                          dataFields={['contractNumber', 'contractName']}
                          fontWeight={'bold'}
                          scroll={true}
                          noBorder={true}
                          bgColor={'white'}
                        />
                      </div>
                    </div>
                  )}
                  {selectedDisciplines && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={disciplines}
                        onOpenList={onOpenDiscipline}
                        label={t('disciplines')}
                        hidelabel={false}
                        dataFields={['shortName', 'name']}
                        selectedItems={selectedDisciplines}
                        fontWeight={'bold'}
                        onSelect={onChangeDiscipline}
                        loading={disciplineLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                      />
                    </div>
                  )}
                  {selectedProcesses && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={processes}
                        onOpenList={onOpenProcess}
                        label={t('main_processes')}
                        dataFields={['name']}
                        selectedItems={selectedProcesses}
                        fontWeight={'bold'}
                        onSelect={onChangeProcesses}
                        loading={processLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                        hidelabel={false}
                      />
                    </div>
                  )}
                  {selectedThemes && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={themes}
                        onOpenList={onOpenThemes}
                        label={t('teams')}
                        dataFields={['name']}
                        selectedItems={selectedThemes}
                        fontWeight={'bold'}
                        onSelect={onChangeThemes}
                        loading={themesLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                        hidelabel={false}
                      />
                    </div>
                  )}
                  {selectedTags && (
                    <div className={styleClass.filterSelector}>
                      <MultiSelector
                        items={tags}
                        onOpenList={onOpenTags}
                        label={'Type'}
                        dataFields={['name']}
                        selectedItems={selectedTags}
                        fontWeight={'bold'}
                        onSelect={onChangeTags}
                        loading={tagsLoading}
                        scroll={true}
                        noBorder={true}
                        bgColor={'white'}
                        hidelabel={false}
                        direction={Direction.UP}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={'w-full flex justify-end px-4 py-2 bg-gray-100'}
                >
                  <Button
                    onClick={onClear}
                    size={ButtonSize.SMALL}
                    noTextWrap={true}
                  >
                    <Icon
                      icon={Icon.IconType.CLOSE_GRAY}
                      className={'mr-2 w-4 h-4 flex'}
                    />
                    {capFirstLetter(t('reset'))}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </HelperIcons>
    </CloseClickOutside>
  )
}

export default FloatingFilterTableKeeper
