import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import {
  systemsBySyntaxFilter,
  searchForSystemStrings,
} from 'src/service/SystemService'

export const useSearchForSystemStrings = (
  searchTerm: string,
  systemSyntaxCategoryId: number,
  systemSettingId: number,
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [
      'searchForSystemStrings',
      searchTerm,
      systemSyntaxCategoryId,
      systemSettingId,
      projectId,
    ],
    queryFn: () =>
      searchForSystemStrings(
        projectId,
        searchTerm,
        systemSyntaxCategoryId,
        systemSettingId,
      ),
  })
}

export const useFilteredSystems = (filterObjects: IFilterObject[]) => {
  const projectId = useProjectId()
  return useQuery({
    enabled:
      !!filterObjects.length &&
      !!filterObjects.flatMap((object) =>
        Object.values(object.syntaxSearchFilters),
      ).length &&
      !!filterObjects.flatMap((object) => Object.values(object.appliesToLevels))
        .length,
    queryKey: ['filteredSystems', projectId, ...filterObjects],
    queryFn: () => systemsBySyntaxFilter(projectId, filterObjects),
  })
}
