import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportStatus, ExportTask } from 'src/context/ExportContext/ExportTypes'
import Spinner from 'src/document/components/base/Spinner'
import ExportService from 'src/service/ExportService'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Icon from 'src/ui-elements/icon/Icon'
import { formatDateRelativeToNow } from 'src/utility/dateUtils'
import { classNames } from 'src/utility/utils'

interface ExportNotificationItemProps {
  item: ExportTask
}

const styleClass = {
  commonBtn: {
    root: classNames(
      'mx-2',
      'w-8',
      'h-8',
      'p-0.5',
      'flex',
      'justify-center',
      'items-center',
      'border-gray-300',
      'border',
      'rounded-full',
      'hover:cursor-pointer',
    ),
    icon: classNames('flex', 'items-center', 'w-5p', 'h-5'),
  },
  fileName: (status: ExportStatus) =>
    classNames(
      'text-xs underline font-medium text-gray-900',
      status === ExportStatus.Success
        ? 'text-blue-root hover:scale-105'
        : 'text-gray-500',
    ),
}

const IconType = {
  docx: Icon.IconType.DOCX,
  pdf: Icon.IconType.PDF,
  xlsx: Icon.IconType.EXCEL,
}

const ExportNotificationItem: FC<ExportNotificationItemProps> = ({ item }) => {
  const { t, i18n } = useTranslation()

  const extension = item.fileName.split('.').pop() ?? 'docx'

  const getStatusText = (status: ExportStatus) => {
    switch (status) {
      case ExportStatus.Failed:
        return t('export_failed')
      case ExportStatus.Success:
        return t('export_success')
      default:
      case ExportStatus.Processing:
        return t('export_in_progress')
    }
  }

  const badgeColor = (status: ExportStatus) => {
    switch (status) {
      case ExportStatus.Failed:
        return BadgeColor.RED
      case ExportStatus.Success:
        return BadgeColor.GREEN
      default:
      case ExportStatus.Processing:
        return BadgeColor.BLUE
    }
  }

  const downloadFile = () => {
    if (item.status === ExportStatus.Success && item.result) {
      ExportService.downloadExportFile(item.result, item.fileName, extension)
    }
  }

  return (
    <div className="flex gap-4 justify-start border-b border-gray-100 pb-2 items-center">
      <span className={styleClass.commonBtn.root}>
        <a>
          <Icon
            className={styleClass.commonBtn.icon}
            icon={IconType[extension]}
          />
        </a>
      </span>
      <div className="flex items-center w-full justify-between">
        <div className="flex flex-col justify-start gap-2 ">
          <a
            onClick={downloadFile}
            className={styleClass.fileName(item.status)}
          >
            {item.fileName}
          </a>
          <Badge
            color={badgeColor(item.status)}
            text={getStatusText(item.status)}
          />
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className={'text-xxs text-gray-500 -mt-px whitespace-no-wrap'}>
            {formatDateRelativeToNow(item.createdAt, i18n.language)}
          </div>
          {item.status === ExportStatus.Processing && <Spinner />}
        </div>
      </div>
    </div>
  )
}

export default ExportNotificationItem
