import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { userDefinedFieldSource } from 'src/service/SystemValues'
import { filterType } from '../../../ui-elements/list/ListTypes'

export const SystemTypeFieldListColumns = () => {
  const { t } = useTranslation()
  return [
    {
      name: 'name',
      size: '200',
      id: 'name',
      dataField: 'name',
      sortingField: 'name',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'field_type',
      size: '150',
      id: 'data_type',
      sortingField: 'data_type',
      dataField: 'data_type',
      filterType: filterType.DEFAULT,
      filter: [
        {
          name: 'string',
          value: 'string',
          active: false,
        },
        {
          name: 'boolean',
          value: 'boolean',
          active: false,
        },
        {
          name: 'time',
          value: 'time',
          active: false,
        },
        {
          name: 'enum',
          value: 'enum',
          active: false,
        },
        {
          name: 'multi_enum',
          value: 'multi_enum',
          active: false,
        },
      ],
      cell: (dataType: any) => <span>{t(dataType)}</span>,
    },
    {
      name: 'default_value',
      size: '150',
      id: 'default_value',
      sortingField: 'default_value',
      dataField: 'default_value||default_value_list||data_type||pattern',
      filter: [],
      filterType: filterType.TEXT,
      cell: (data: {
        data_type: string
        default_value: string
        default_value_list: string
        pattern: string[]
      }) => {
        const renderContent = () => {
          switch (data.data_type) {
            case 'boolean':
              return data.default_value === 'true'
                ? t('yes')
                : data.default_value === 'false'
                  ? t('no')
                  : ''

            case 'multi_enum':
              return `${data.default_value_list}`

            case 'time':
              const format = `${moment.localeData().longDateFormat('L')} ${
                data.pattern[0].split(' ')[1]
                  ? moment.localeData().longDateFormat('LT')
                  : ''
              }`
              return data.default_value
                ? moment(data.default_value).format(format)
                : ''

            default:
              return `${data.default_value ?? ''}`
          }
        }

        return <span>{renderContent()}</span>
      },
    },
    {
      name: 'domain',
      size: '130',
      id: 'domain',
      sortingField: 'domain',
      dataField: 'domain',
      filterDataField: 'domain',
      filter: userDefinedFieldSource(t).map((source) => ({
        ...source,
        active: true,
      })),
      filterType: filterType.DEFAULT,
      cell: (domain: string) => (
        <span>
          {userDefinedFieldSource(t)
            .filter((pt) => pt.value === domain)
            .map((pt) => pt.name)
            .pop()}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      sortingField: 'created_at',
      dataField: 'created_at',
      filter: [],
      filterType: filterType.RANGEDATE,
      cell: (created: string) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      sortingField: 'updated_at',
      dataField: 'updated_at',
      filter: [],
      filterType: filterType.RANGEDATE,
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]
}
