import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getDocument,
  getFileContainersWithPagination,
} from 'src/service/FileContainerService'

export const fileContainerKey = 'file-container'
export const fileContainersKey = 'file-containers'

export const useFileContainer = (
  documentId: number,
  options?: { enabled?: boolean },
) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: [fileContainerKey, projectId, documentId],
    queryFn: () => getDocument(projectId, documentId),
  })
}

export const useFileContainersWithPagination = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = false,
}: {
  sort: Parameters<typeof getFileContainersWithPagination>[3]['sort']
  filters: Omit<Parameters<typeof getFileContainersWithPagination>[3], 'sort'>
  page: number
  pageSize: number
  enabled: boolean
}) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [fileContainersKey, { projectId, page, pageSize, filters, sort }],
    queryFn: () =>
      getFileContainersWithPagination(projectId, page, pageSize, {
        ...filters,
        sort,
      }),
    placeholderData: keepPreviousData,
    enabled,
  })
}
