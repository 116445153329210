export const trainImportTemplate = {
  name: 'train_import_template',
  displayName: 'TrainImport',
  columns: ['title', 'start_date', 'control_area', 'color'],
  templateColumns: ['name', 'required', 'value', 'description', 'example'],
  template: [
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_imported_item',
      example: 'Tittel til tog',
    },
    {
      name: 'start_date',
      required: true,
      value: 'date_mm_dd_yyyy',
      description: 'start_date',
      example: '05.04.2021',
    },
    {
      name: 'control_area',
      required: true,
      value: 'text',
      description: 'control_area',
      example: 'CA 1',
    },
    {
      name: 'color',
      required: true,
      value: 'text',
      description: 'color',
      example: 'deep-orange',
    },
  ],
}
