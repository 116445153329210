import { ISystemGroupFields } from 'src/components/system/system-type-group/SystemTypeGroupForm'
import { IMetaField } from '../components/system/system-type-fields/SystemTypeFieldsForm'
import { ISystemTypeGroup } from './OrgTypes'
import { request } from './service-utils'

export const createSystemTypeGroup = (
  projectId: number,
  systemTypeGroup: ISystemGroupFields,
): Promise<ISystemTypeGroup> => {
  return request(
    `projects/${projectId}/system_type_groups`,
    'POST',
    systemTypeGroup,
  )
}

export const editSystemTypeGroup = (
  systemTypeGroup: Partial<ISystemGroupFields>,
): Promise<ISystemTypeGroup> => {
  return request(
    `system_type_groups/${systemTypeGroup.id}`,
    'PUT',
    systemTypeGroup,
  )
}

export const getProjectSystemTypeGroups = (
  projectId: number,
): Promise<ISystemTypeGroup[]> => {
  return request(`projects/${projectId}/system_type_groups`, 'GET')
}

export const getProjectSystemSettingSystemTypeGroup = (
  projectId: number,
  systemSettingId: number,
): Promise<IMetaField[]> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/user_defined_fields`,
    'GET',
  )
}

export const getDomainSystemTypeGroupUserDefinedFields = (
  projectId: number,
  domain: string,
): Promise<IMetaField[]> => {
  return request(
    `projects/${projectId}/system_type_groups/domain/${domain}/user_defined_fields`,
    'GET',
  )
}

export const getSystemTypeGroup = (id: number): Promise<ISystemTypeGroup> => {
  return request(`system_type_groups/${id}`, 'GET')
}

export const swapSystemTypeGroup = (
  projectId: number,
  systemTypeGroupId: number,
  sequence: number,
): Promise<ISystemTypeGroup> => {
  return request(
    `projects/${projectId}/system_type_groups/${systemTypeGroupId}/sequence`,
    'PUT',
    {
      sequence: sequence,
    },
  )
}

export const deleteSystemTypeGroup = (systemTypeGroupId: number) => {
  return request(`/system_type_groups/${systemTypeGroupId}`, 'DELETE', {}, true)
}

export const getProjectSystemTypeGroupsPerDomain = (
  projectId: number,
  domainName: string,
): Promise<ISystemTypeGroup[]> => {
  return request(
    `projects/${projectId}/domain/${domainName}/system_type_groups`,
    'GET',
  )
}
