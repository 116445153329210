import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import {
  getProjectDisciplines,
  getUserDisciplines,
} from 'src/service/DisciplineService'
import { deleteFile } from 'src/service/DocumentService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  IDocument,
  IInputErrorMessage,
  IRoom,
  ITaskData,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectTaskTypes } from 'src/service/TaskService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'
import { UserContext } from '../../context/UserContextProvider/UserContext'
import { getProjectRooms } from '../../service/RoomService'
import { getSystems } from '../../service/SystemService'
import { capFirstLetter, classNames } from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import useDidMountEffect from '../hooks/UseDidMountEffect'
import Selector from '../selectors/Selector'

export interface IConstructionChecklistTaskFormProps {
  projectId: number
  closeModal: () => void
  updateList: () => void
  parentId: number
  parentType: string
  submitTask: (task: ITaskData) => void
}

const styleClass = {
  root: classNames('md_w-full', 'flex', 'flex-col', 'pr-2'),
  inputGroup: classNames('w-full', 'flex', 'row', 'my-2'),
  closedDate: (disabled: boolean) =>
    classNames(
      disabled ? 'border-grey-dark' : 'border-blue-one',
      disabled ? 'bg-grey-light' : '',
      'border-1',
      'py-px',
      'border',
      'rounded',
    ),
  errorMessage: classNames('text-red-600', 'my-2', 'ml-2', 'text-sm'),
}

const ConstructionIssueForm = ({
  projectId,
  parentId,
  parentType,
  closeModal,
  submitTask,
}: IConstructionChecklistTaskFormProps) => {
  const { t } = useTranslation()
  const [task, setTask] = useState<ITaskData>({} as ITaskData)
  const [loading, setLoading] = useState<boolean>(false)
  const [focus, setFocus] = useState<string>('')
  const [selectLoading, setSelectLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any>({
    disciplines: [],
    users: [],
    rooms: [],
    systems: [],
  })
  const [errorMessages, setErrorMessages] = useState<IInputErrorMessage[]>([])
  const [documents, setDocuments] = useState<IDocument[]>([])

  const userContext = useContext(UserContext)

  useEffect(() => {
    loadUserDiscipline()
  }, [])

  const loadUserDiscipline = async () => {
    const tk = {} as ITaskData
    const dis = await getUserDisciplines(projectId, userContext.state.user.id)
    const cloneItem = {
      ...items,
      users: await getProjectUsersWithDisciplines(projectId),
      disciplines: await getProjectDisciplines(projectId),
      systems: parentType === 'System' ? await getSystems(projectId) : [],
    }
    setItems(cloneItem)
    tk.responsible_id = userContext.state.user.id
    tk.discipline_id = dis[0].id
    tk.contract_id = dis[0].contract_id
    tk.system_id = parentType === 'System' ? parentId : undefined
    tk.location_id = parentType === 'Room' ? parentId : undefined
    tk.construction_locomotive_id =
      parentType === 'ConstructionLocomotive' ? parentId : undefined
    tk.control_area_id = parentType === 'ControlArea' ? parentId : undefined
    setItems(cloneItem)
    setTask(tk)
  }

  useDidMountEffect(() => {
    if (task.responsible_id && task.responsible_id > 0) {
      const selectedUser = items.users.find(
        (user: any) => user.id === task.responsible_id,
      ) as IUserData
      const disciplinesVal =
        selectedUser && selectedUser.disciplines
          ? selectedUser.disciplines.filter((d) => d.project_id === projectId)
          : undefined
      const discipline = disciplinesVal
        ? disciplinesVal.find((v) => v.id === task.discipline_id)
        : undefined
      const selectedDispline = discipline
        ? discipline
        : disciplinesVal
          ? disciplinesVal[0]
          : undefined
      if (selectedDispline) {
        if (items.disciplines.length === 0) {
          getItems('disciplines')
        }
        onChangeInput('discipline_id', selectedDispline.id)
        onChangeInput('contract_id', selectedDispline.contract_id)
      }
    }
  }, [task.responsible_id])

  const getItems = async (itemName: string, disciplineId?: number) => {
    let it: any[] = []
    const copyItem = { ...items }
    setSelectLoading(true)
    switch (itemName) {
      case 'task_types':
        it = await getProjectTaskTypes(projectId)
        break
      case 'disciplines':
        it = await getProjectDisciplines(projectId)
        break
      case 'rooms':
        it = await getProjectRooms(projectId)
        break
      case 'systems':
        it = await getSystems(projectId)
        break
      case 'users':
        const discId = disciplineId
          ? disciplineId
          : task.discipline_id && task.discipline_id > 0
            ? task.discipline_id
            : undefined
        it = discId
          ? await getDisplineUsers(discId)
          : await getProjectUsersWithDisciplines(projectId)
        break
    }
    copyItem[itemName] = it
    setItems(copyItem)
    setSelectLoading(false)
  }

  const onChangeInput = (field: string, value: string | number) => {
    const taskTemp = { ...task }
    taskTemp[field] = value
    setTask(taskTemp)
    if (field === 'discipline_id') {
      taskTemp['responsible_id'] = 0
      getItems('users', Number(value))
    }

    if (field === 'location_id') {
      const room = items.rooms.find((i: IRoom) => i.id === value)
      taskTemp['control_area_id'] = room.control_area_id
    }

    clearAnErrorMessage(field)
  }

  const clearAnErrorMessage = (input: string) => {
    const copyErrMesg = [...errorMessages]
    const index = copyErrMesg.findIndex((er) => er.for === input)
    if (index > -1) {
      copyErrMesg.splice(index, 1)
    }
    setErrorMessages(copyErrMesg)
  }

  const getErrorMessage = (input: string): string => {
    const error = errorMessages.find((err) => err.for === input)
    return error ? error.errorMessage : ''
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const onCloseModal = () => {
    documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    setDocuments([])
    closeModal()
  }

  const makeErrorMessage = (
    errorMessage: string,
    input: string,
  ): IInputErrorMessage => {
    return { for: input, errorMessage }
  }

  const onSubmit = async (e: any) => {
    let errorVal = false
    e.preventDefault()
    setLoading(true)
    setErrorMessages([])
    const errors = []

    const { deadline, title, discipline_id, responsible_id } = task

    if (!deadline) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('deadline') }),
          'deadline',
        ),
      )
      errorVal = true
    }

    if (!discipline_id) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('discipline') }),
          'discipline_id',
        ),
      )
      errorVal = true
    }

    if (!responsible_id) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('responsible') }),
          'responsible_id',
        ),
      )
      errorVal = true
    }

    if (!title) {
      errors.push(
        makeErrorMessage(t('fill_out_w_param', { param: t('title') }), 'title'),
      )
      errorVal = true
    }

    if (!errorVal) {
      const aTask = {
        ...task,
        project_id: projectId,
        status: 'open',
        parent_id: parentId,
        parent_type: parentType,
        taskType: 'Aksjon',
        documents,
      }
      submitTask(aTask)
      closeModal()
    } else {
      setErrorMessages(errors)
      setLoading(false)
    }
    e.stopPropagation()
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div>
        <label className={'pl-2 my-2 text-sm font-medium text-gray-700'}>
          {capFirstLetter(t('deadline'))} *
        </label>
        {getErrorMessage('deadline') ? (
          <label className={styleClass.errorMessage}>
            {getErrorMessage('deadline')}
          </label>
        ) : null}
        <div className={'w-full md:w-1/2 flex flex-col'}>
          <div className={'px-2 w-full flex'}>
            <SingleDatePicker
              firstDayOfWeek={1}
              date={task.deadline ? moment(task.deadline) : null}
              onDateChange={(date) =>
                onChangeInput(
                  'deadline',
                  date && date.isValid() ? date.format() : '',
                )
              }
              renderDayContents={renderDayContents}
              focused={focus === 'deadline'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'deadline' : '')
              }
              id="Starttidspunkt"
              small={true}
              isOutsideRange={(data) => data.isBefore(moment().startOf('day'))}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <Input
            label={t('title')}
            block={true}
            value={task.title}
            onChange={(e) => onChangeInput('title', e.target.value)}
            errorMessage={getErrorMessage('title')}
            focus={focus === 'title'}
            required={true}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Selector
            items={items.disciplines}
            selectedItemId={task.discipline_id}
            onOpenSelectFunction={() => getItems('disciplines')}
            loading={selectLoading}
            onSelect={(id) => onChangeInput('discipline_id', id)}
            label={t('discipline')}
            dataFields={['shortName', 'name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={getErrorMessage('discipline_id')}
          />
          <Selector
            items={items.users}
            selectedItemId={task.responsible_id}
            onOpenSelectFunction={() => getItems('users')}
            loading={selectLoading}
            onSelect={(id) => onChangeInput('responsible_id', id)}
            label={t('responsible')}
            dataFields={['firstName', 'lastName']}
            required={true}
            fontSize={'sm'}
            userSelector={true}
            fontWeight={'bold'}
            errorMessage={getErrorMessage('responsible_id')}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Selector
            items={items.rooms}
            selectedItemId={task.location_id ? task.location_id : 0}
            onOpenSelectFunction={() => getItems('rooms')}
            loading={selectLoading}
            onSelect={(id) => onChangeInput('location_id', id)}
            label={t('room')}
            dataFields={['functional_room_number', 'room_name']}
            fontSize={'sm'}
            fontWeight={'bold'}
          />

          <Selector
            items={items.systems}
            selectedItemId={task.system_id ? task.system_id : 0}
            onOpenSelectFunction={() => getItems('systems')}
            loading={selectLoading}
            onSelect={(id) => onChangeInput('system_id', id)}
            label={t('system')}
            dataFields={['record_id', 'name']}
            fontSize={'sm'}
            fontWeight={'bold'}
          />
        </div>
        <div className={'w-full md:w-1/2'}>
          <Selector
            items={items.task_types ?? []}
            selectedItemId={task.task_type_id ? task.task_type_id : 0}
            onOpenSelectFunction={() => getItems('task_types')}
            onSelect={(id) => onChangeInput('task_type_id', id)}
            label={t('task_type')}
            dataFields={['taskTypeName']}
            fontSize={'sm'}
            fontWeight={'bold'}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Textarea
            label={t('description')}
            value={task.description}
            isValid={false}
            autoFocus={false}
            onChange={(e) => onChangeInput('description', e.target.value)}
            block={true}
          />
        </div>
      </div>

      {documents && documents.length > 0 && (
        <UploadFileList
          documents={documents}
          updateDocuments={updateUploadedFiles}
        />
      )}

      <UploadFile
        uploadUrl={getUploadDocURl(projectId, 'Task')}
        uploadedDocuments={filesUploaded}
      />

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={onCloseModal}>
          {t('cancel')}
        </Button>
        <Button
          type={Button.ButtonType.PRIMARY}
          onClick={onSubmit}
          disabled={loading}
        >
          {t('add')}
        </Button>
      </ModalFooter>
    </form>
  )
}

export default ConstructionIssueForm
