import { IFolderSyntaxItem } from 'src/document/types/IFolderSyntaxItem'

export enum BASE_METADATA_TYPES {
  string = 'string',
  boolean = 'boolean',
  time = 'time',
  other = 'other',
  enum = 'enum',
  multiEnum = 'multi_enum',
}

export interface IMetadataBaseType {
  type: BASE_METADATA_TYPES
  readableNameKey?: string
  pattern?: string
  hide?: boolean
  children: Record<string, IMetaDataType>
}

export interface IMetaDataType {
  readableNameKey?: string
  data_type: BASE_METADATA_TYPES
  pattern: string | string[]
  isPatternArray?: boolean
  exclude?: boolean
}

export const metadataStringTypes: Record<string, IMetaDataType> = {
  string: {
    data_type: BASE_METADATA_TYPES.string,
    pattern: ['string'],
    readableNameKey: 'string',
  },
}

export const metadataBooleanTypes: Record<string, IMetaDataType> = {
  boolean: {
    data_type: BASE_METADATA_TYPES.boolean,
    pattern: ['boolean'],
    readableNameKey: 'true_false',
  },
}

const metadataChoiceTypes: Record<string, IMetaDataType> = {
  enum: {
    data_type: BASE_METADATA_TYPES.enum,
    pattern: [],
    readableNameKey: 'enum',
  },
}

const metadataMultiSelectTypes: Record<string, IMetaDataType> = {
  multiSelector: {
    data_type: BASE_METADATA_TYPES.multiEnum,
    pattern: [],
    readableNameKey: 'multi_enum',
  },
}

export const metadataTimeTypes: Record<string, IMetaDataType> = {
  dateTime: {
    data_type: BASE_METADATA_TYPES.time,
    pattern: ['DD.MM.YYYY HH:mm'],
  },
  date: {
    data_type: BASE_METADATA_TYPES.time,
    pattern: ['DD.MM.YYYY'],
  },
}

export const metaDataTypes: {
  [key in BASE_METADATA_TYPES]: IMetadataBaseType
} = {
  [BASE_METADATA_TYPES.string]: {
    type: BASE_METADATA_TYPES.string,
    readableNameKey: 'text',
    children: metadataStringTypes,
  },
  [BASE_METADATA_TYPES.time]: {
    type: BASE_METADATA_TYPES.time,
    readableNameKey: 'time',
    children: metadataTimeTypes,
  },
  [BASE_METADATA_TYPES.boolean]: {
    type: BASE_METADATA_TYPES.boolean,
    readableNameKey: 'boolean',
    children: metadataBooleanTypes,
  },
  [BASE_METADATA_TYPES.other]: {
    type: BASE_METADATA_TYPES.other,
    readableNameKey: 'others',
    hide: true,
    children: {},
  },
  [BASE_METADATA_TYPES.enum]: {
    type: BASE_METADATA_TYPES.enum,
    readableNameKey: 'enum',
    children: metadataChoiceTypes,
  },
  [BASE_METADATA_TYPES.multiEnum]: {
    type: BASE_METADATA_TYPES.multiEnum,
    readableNameKey: 'multi_enum',
    children: metadataMultiSelectTypes,
  },
}

export const documentAttributes: IFolderSyntaxItem[] = [
  {
    draggableId: 'document_type',
    name: 'document_type',
    data_type: BASE_METADATA_TYPES.other,
    pattern: 'document_type',
    readableNameKey: 'document_type',
    is_required: true,
    syntaxType: 'document_attribute',
  },
  {
    draggableId: 'name',
    name: 'name',
    data_type: BASE_METADATA_TYPES.string,
    pattern: 'name',
    readableNameKey: 'name',
    is_required: false,
    syntaxType: 'document_attribute',
  },
]

export const documentCounter: IFolderSyntaxItem[] = [
  {
    draggableId: 'counter',
    name: 'counter',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '1',
    is_required: false,
    syntaxType: 'document_counter',
  },
  {
    draggableId: 'counter',
    name: 'counter',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '2',
    is_required: false,
    syntaxType: 'document_counter',
  },
  {
    draggableId: 'counter',
    name: 'counter',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '3',
    readableNameKey: 'counter',
    is_required: false,
    syntaxType: 'document_counter',
  },
  {
    draggableId: 'counter',
    name: 'counter',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '4',
    readableNameKey: 'counter',
    is_required: false,
    syntaxType: 'document_counter',
  },
]

export const spacers: IFolderSyntaxItem[] = [
  {
    draggableId: '',
    syntaxType: 'sep',
    name: 'dash (-)',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '-',
    is_required: false,
  },
  {
    draggableId: '',
    syntaxType: 'sep',
    name: 'space',
    data_type: BASE_METADATA_TYPES.string,
    pattern: ' ',
    is_required: false,
  },
  {
    draggableId: '',
    syntaxType: 'sep',
    name: 'underline (_)',
    data_type: BASE_METADATA_TYPES.string,
    pattern: '_',
    is_required: false,
  },
]
