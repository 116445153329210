import { PropsWithChildren, useContext } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import ConfirmModalContext from 'src/context/ConfirmModalContext'

interface ICloseClickOutsideProps {
  onClose: (e: React.MouseEvent | MouseEvent) => void
  className?: string
  onClick?: (e: React.MouseEvent) => void
  display?: 'flex' | 'inline-block' | 'block' | 'inline' | 'contents'
}

const CloseClickOutside = ({
  onClose,
  children,
  className,
  onClick,
  display,
}: PropsWithChildren<ICloseClickOutsideProps>) => {
  const confirmDialogContext = useContext(ConfirmModalContext)

  return (
    <OutsideClickHandler
      onOutsideClick={onClose}
      display={display}
      disabled={confirmDialogContext.isOpen}
    >
      <div onClick={onClick} className={className}>
        {children}
      </div>
    </OutsideClickHandler>
  )
}

export default CloseClickOutside
