import * as React from 'react'
import { useEffect, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import { useSubcontractorFileContainers } from 'src/query/fdvu/subcontractor'
import AssignedFileContainerList from 'src/subcontractor/components/requirements/AssignedFileContainerList'
import FileSubmissionPanel from 'src/subcontractor/components/requirements/FileSubmissionPanel'

const SubcontractorPage: FC = () => {
  const { t } = useTranslation()
  const [hideInReview, setHideInReview] = useState<boolean>(true)

  const splashScreenUtil = SplashScreenUtil()

  const [selectedFileContainer, setSelectedFileContainer] =
    useState<IFdvuFileContainer>()

  const { data = [], isLoading } = useSubcontractorFileContainers(hideInReview)

  useEffect(() => {
    const updatedFileContainer = data.find(
      (fc) => fc.id === selectedFileContainer?.id,
    )
    setSelectedFileContainer(updatedFileContainer ?? data[0])
  }, [data])

  useEffect(() => {
    if (!isLoading) splashScreenUtil.removeSplashScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className="flex flex-col justify-between text-gray-700 h-full">
      <div className="flex justify-between items-end m-4">
        <div className="flex items-center gap-1 text-sm">
          {t('hide_documents_in_review')}
          <SwitchHOC valueProp={hideInReview} onChange={setHideInReview} />
        </div>
      </div>
      <div className={'flex m-2 gap-2 h-full'}>
        <div className={'w-2/3'}>
          <p
            className={
              'flex px-2 py-1 font-bold bg-grey-light rounded-sm text-sm first-capitalize'
            }
          >
            {t('documents')}
          </p>
          <AssignedFileContainerList
            selectedFileContainerId={selectedFileContainer?.id}
            setSelectedFileContainer={setSelectedFileContainer}
            hideInReview={hideInReview}
          />
        </div>
        {selectedFileContainer && (
          <FileSubmissionPanel
            selectedFileContainer={selectedFileContainer}
            hideInReview={hideInReview}
          />
        )}
      </div>
    </div>
  )
}

export default SubcontractorPage
