import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useInterval from 'src/components/hooks/UseInterval'
import useProjectId from 'src/components/hooks/useProjectId'
import { IMPORT_TYPE } from 'src/components/notifications/import/ImportNotificationItem'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { ImportContext } from 'src/context/ImportContext/ImportContext'
import { ImportStatus, ImportTask } from 'src/context/ImportContext/ImportTypes'
import FolderService from 'src/document/services/FolderService'
import BackgroundJobService from 'src/service/BackgroundJobService'
import {
  getProjectSystemSettingSystemTypeGroup,
  getProjectSystemTypeGroupsPerDomain,
} from 'src/service/SystemTypeGroupService'
import ImportToast from 'src/ui-elements/toast/ImportToast'
import useAlert from 'src/ui-elements/toast/useAlert'

const ImportContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const PULL_INTERVAL = 5000
  const [importTasks, setImportTasks] = useState<ImportTask[]>([])
  const [pullInterval, setPullInterval] = useState<number | null>(null)
  const [selectedItem, _setSelectedItem] = useState<ImportTask>()
  const [metaData, setMetadata] = useState<IMetaField[]>([])

  const projectId = useProjectId()

  const { addAlert } = useAlert()
  const { t } = useTranslation()

  const getDomainId = (fieldName: string, task?: ImportTask) => {
    const id = task?.data?.[fieldName]
    return id ? Number(id) : undefined
  }

  const setSelectedItem = async (
    importType?: IMPORT_TYPE,
    item?: ImportTask,
  ) => {
    if (item && importType) {
      const res = await getUserDefinedFields(importType, item)
      setMetadata(res)
      _setSelectedItem(item)
    } else _setSelectedItem(item)
  }

  const getUserDefinedFields = async (
    importType: IMPORT_TYPE,
    item: ImportTask,
  ): Promise<IMetaField[]> => {
    switch (importType) {
      case IMPORT_TYPE.SYSTEM_IMPORT:
        const syntaxId = getDomainId('syntax_id', item)
        if (!syntaxId) return []
        return await getProjectSystemSettingSystemTypeGroup(projectId, syntaxId)
      case IMPORT_TYPE.SYSTEM_TYPE_IMPORT:
        return []
      case IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT:
        const tsg = await getProjectSystemTypeGroupsPerDomain(
          projectId,
          'TestSystemGroup',
        )
        return tsg?.pop()?.optional_fields ?? []
      case IMPORT_TYPE.TEST_WORK_GROUP_IMPORT:
        const twg = await getProjectSystemTypeGroupsPerDomain(
          projectId,
          'TestWorkGroup',
        )
        return twg?.pop()?.optional_fields ?? []
      case IMPORT_TYPE.DOCUMENT_IMPORT:
        const folderId = getDomainId('folder_id', item)
        if (!folderId) return []
        const folder = await FolderService.getSingleFolder(folderId)
        return folder.required_fields.concat(folder.optional_fields)
      case IMPORT_TYPE.SHAREPOINT_SYNC:
      case IMPORT_TYPE.UNKNOWN:
        return []
    }
  }

  const pullData = useCallback(async () => {
    if (projectId) {
      const _res = await BackgroundJobService.getBGJobs(projectId)
      if (_res.findIndex((a) => a.status === ImportStatus.IN_PROGRESS) > -1) {
        setPullInterval(PULL_INTERVAL)
      } else {
        setPullInterval(null)
      }
      setImportTasks(_res)
    }
  }, [projectId])

  useEffect(() => {
    pullData()
  }, [pullData])

  const errorAlert = (file: string) => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: t('import_failed', {
        file: file,
      }),
    })
  }

  const updateImportTask = (importTask: ImportTask) => {
    if (importTask.status === 'in_progress') {
      setPullInterval(PULL_INTERVAL)
    }
    const replacedTasks = importTasks.map((task) => {
      if (task.id === importTask.id) {
        return importTask
      }
      return task
    })
    setImportTasks(replacedTasks)
  }

  const poll = () => {
    importTasks.map((task) => {
      if (task.status === 'in_progress') {
        BackgroundJobService.getBackgroundJobStatus(projectId, task.id).then(
          (res) => {
            updateImportTask(res)
            if (res.status === 'failed') {
              errorAlert(res?.job_name)
            }
          },
        )
      }

      const inProgress = importTasks.find(
        (task) => task.status === 'in_progress',
      )

      if (!inProgress) {
        setPullInterval(null)
      }
    })
  }

  const addImportTask = (res: ImportTask) => {
    if (importTasks.find((task) => task.id === res.id)) {
      updateImportTask(res)
    } else {
      setImportTasks([...importTasks, res])
    }
    setPullInterval(PULL_INTERVAL)
  }

  useInterval(async () => {
    poll()
    const inProgress = importTasks.find((task) => task.status === 'in_progress')

    if (!inProgress) {
      setPullInterval(null)
    }
  }, pullInterval)

  const getImportTask = (jobId: number) => {
    return importTasks.find((task) => task.id === jobId)
  }

  const removeImportTask = (jobId: number) => {
    const _res = importTasks.filter((task) => task.id !== jobId)
    setImportTasks(_res)
    BackgroundJobService.deleteBackgroundJob(projectId, jobId)
  }

  const context = {
    actions: {
      addImportTask,
      updateImportTask,
      removeImportTask,
      getImportTask: getImportTask,
      reload: poll,
      setSelectedItem,
    },
    state: {
      importTasks,
      pullInterval,
      selectedItem,
      metaData,
    },
  }

  return (
    <ImportContext.Provider value={context}>
      <>
        {children}
        <ImportToast />
      </>
    </ImportContext.Provider>
  )
}

export default ImportContextProvider
