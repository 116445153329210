export const actionListExportTemplate = (taskType?: string) => ({
  geniusApiRequest: {
    url: `{organizationApiBaseUrl}projects/{projectId}/filter_tasks/${
      taskType ?? 'Aksjon'
    }/pages/0/0`,
    method: 'POST',
  },
  name: 'Actions',
  displayName: taskType ?? 'Aksjoner',
  columns: [
    'id',
    'title',
    'type',
    'status',
    'description',
    'improvements',
    'deadline',
    'duration',
    'closed_date',
    'responsible',
    'discipline',
    'contract',
    'case_type',
    'reporter',
    'main_process',
    'parent',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'title',
    'tag.name',
    'status',
    'description',
    'improvement.title',
    'deadline',
    'duration',
    'closed_date',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'taskType',
    'reporter.firstName||reporter.lastName',
    'main_process.name',
    'parent_type',
    'created_at',
    'updated_at',
  ],
})
