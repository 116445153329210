export const wagonListExportTemplate = (url: string) => {
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}${url}/construction_locomotives/pages/1/1000`,
      method: 'POST',
    },
    name: 'Wagon',
    displayName: 'WagonExport',
    columns: [
      'Id',
      'Tittel',
      'Beskrivelse',
      'Startdato',
      'Tog',
      'Disiplin',
      'Kontrakt',
      'Satt varighet (dager)',
      'Beregnet arbeidstimer',
      'Beregnet arbeidsdager',
      'Beregnet Bemanning',
      'Opprettet',
      'Sist endret',
    ],
    rows: [
      'record_id',
      'title',
      'subject',
      'startTime',
      'construction_train.title',
      'discipline.shortName||discipline.name',
      'contract.contractNumber||contract.contractName',
      'duration',
      'planned_execution_hours',
      'planned_execution_days',
      'planned_staffing',
      'created_at',
      'updated_at',
    ],
  }
}
