import { FC, useState } from 'react'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { IImage } from '../../service/OrgTypes'
import { updateWallToken } from '../../service/TheWallService'
import { downloadSingleFile } from '../../utility/download'

interface IAttachmentIconProps {
  attachments: IImage[]
}

const AttachmentIcon: FC<IAttachmentIconProps> = ({
  attachments,
}: IAttachmentIconProps) => {
  const [expandMore, setExpandMore] = useState<boolean>(false)

  const downloadAttachments = async () => {
    const token = await updateWallToken()
    for (const file of attachments) {
      await downloadSingleAttachment(file.name ?? '', file.url, token)
    }
  }

  const downloadAttachment = async (name: string, url: string) => {
    const token = await updateWallToken()
    await downloadSingleAttachment(name, url, token)
  }

  const downloadSingleAttachment = async (
    name: string,
    url: string,
    token: string,
  ) => {
    if (url.includes('bygg.io')) await downloadSingleFile(url, name, token)
    else open(url, '_blank')
  }

  const onChangeExpanded = () => {
    setExpandMore(!expandMore)
  }

  return attachments?.length > 0 ? (
    <CloseClickOutside onClose={() => setExpandMore(false)}>
      <div
        className="flex text-blue-root items-center relative"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <a
          className="bg-d-lightblue w-12 h-5 text-xs flex px-2 items-center justify-center rounded-md"
          onClick={() => {
            downloadAttachments()
          }}
        >
          <span>{attachments.length}</span>
          <MaterialIcon className=" mx-1 text-xs font-thin" icon="download" />
        </a>
        {attachments.length > 1 && (
          <MaterialIcon
            className=" mx-1 text-sm"
            icon={expandMore ? 'expand_less' : 'expand_more'}
            onClick={() => onChangeExpanded()}
          />
        )}
        {expandMore && (
          <div
            className={
              'absolute w-max top-[20px] left-0 z-50 rounded-sm bg-white border border-b-0 boder-gray-100'
            }
          >
            {attachments.map((attachment, index) => (
              <a
                className="flex flex-row items-center px-4 py-1 border-b   hover:bg-blue-light-100"
                key={index}
                onClick={() =>
                  downloadAttachment(attachment.name ?? '', attachment.url)
                }
              >
                <span className="pr-2 max-w-xs truncate">
                  {attachment?.name}
                </span>
                <MaterialIcon
                  className=" mx-1 text-xs font-thin"
                  icon="download"
                />
              </a>
            ))}
          </div>
        )}
      </div>
    </CloseClickOutside>
  ) : (
    <span>{''}</span>
  )
}

export default AttachmentIcon
