import { PropsWithChildren, FC } from 'react'
import FdvuSubcontractorTopMenu from './top-menu/FdvuSubcontractorTopMenu'

const SubcontractorWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={'h-full'}>
      <div className="overflow-auto h-screen">
        <FdvuSubcontractorTopMenu />
        <div id="navigation-row" />
        {children}
      </div>
    </div>
  )
}

export default SubcontractorWrapper
