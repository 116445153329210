import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'moment/dist/locale/nb'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FlagProvider } from '@unleash/proxy-client-react'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { browserHandler } from 'src'
import history from 'src/history'
import ConditionalWrap from 'src/utility/ConditionalWrap'
import Login from './components/login/Login'
import NetworkInterceptor from './components/ntw/NetworkInterceptor'
import VersionAlert from './components/version-alert/VersionAlert'
import { ConfirmModalContextProvider } from './context/ConfirmModalContext'
import useBrowserDetector, { BROWSER_ENUM } from './hooks/useBrowserDetector'
import MLogin from './mobile/pages/MLogin'
import ErrorBoundary from './page/dashboard/error/ErrorBoundary'
import { queryClient } from './query/client'
import FdvuSubcontractorRouter from './routers/FdvuSubcontractorRouter'
import MainRouter from './routers/MainRouter'
import SharedPagesRouter from './routers/SharedPagesRouter'
import EnvironmentLoader from './service/EnvironmentLoader'
import ResolutionHandler from './service/ResolutionHandler'
import { checkAuthentication } from './service/TheWallService'
import TokenService from './service/TokenService'
import FdvuSubcontractorService from './subcontractor/service/SubcontractorService'
import { AlertContainer } from './ui-elements/toast/AlertContainer'
import AlertContextProvider from './ui-elements/toast/AlertContext'

Sentry.init({
  dsn: 'https://61b3ed3a8b870f20c08d1c181b88b313@o4506545850941440.ingest.sentry.io/4506545854939136',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        EnvironmentLoader.getConfig().organizationBaseUrl,
      ],
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.4, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: EnvironmentLoader.environmentName(),
  enabled:
    !['test'].includes(EnvironmentLoader.environmentName()) &&
    import.meta.env.PROD,
})

const unleashConfig: React.ComponentProps<typeof FlagProvider>['config'] = {
  url: 'https://unleash.bygg.io/api/frontend',
  clientKey: EnvironmentLoader.isTest()
    ? '*:development.067d8b757f60ac7376c2e8cab18859059c9765472947d6cb0af37f4e'
    : '*:production.29945854f9385cc638d46217ac319061a58b4e666c9fecc37417e7c0',
  refreshInterval: 60, // seconds
  appName: 'react-app',
  environment: EnvironmentLoader.environmentName(),
}

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isFdvuSubcontractor, setIsFdvuSubcontractor] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [
    isLoadingSubcontractorAuthentication,
    setIsLoadingSubcontractorAuthentication,
  ] = useState(false)

  const { i18n } = useTranslation()

  const isSharedPage = window?.location.pathname.startsWith('/share')

  useEffect(() => {
    moment.locale(i18n.language === 'no' ? 'nb' : i18n.language)
  }, [i18n.language])

  const loadAuthentication = useCallback(async () => {
    try {
      const result = await checkAuthentication()
      if (result) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    } catch (e) {
      setIsAuthenticated(false)
      setIsLoading(false)
    }
  }, [])

  const loadSubcontractorAuthentication = useCallback(async () => {
    setIsLoadingSubcontractorAuthentication(true)
    try {
      const authenticated = await FdvuSubcontractorService.authenticate()
      setIsFdvuSubcontractor(authenticated)
    } catch (e) {
      setIsFdvuSubcontractor(false)
    }
    setIsLoadingSubcontractorAuthentication(false)
  }, [])

  useEffect(() => {
    if (window?.location.pathname.startsWith('/subcontractor')) {
      loadSubcontractorAuthentication()
    }
  }, [loadSubcontractorAuthentication])

  useEffect(() => {
    const param = new URLSearchParams(location.search)

    const refreshToken = param.get('refresh_token')
    const email = param.get('email')
    const password = param.get('password')

    if (email && password) {
      // password reset requested
      localStorage.clear()
    } else if (refreshToken) {
      TokenService.setRefreshToken = refreshToken
    }
    const token = TokenService.getRefreshToken

    if (!token) {
      setIsAuthenticated(false)
      setIsLoading(false)
    } else {
      loadAuthentication()
    }
  }, [loadAuthentication])

  const isIE = useBrowserDetector() === BROWSER_ENUM.INTERNET_EXPLORER
  return (
    <React.Fragment>
      {isIE && browserHandler.ie()}
      <ConditionalWrap
        condition={!!import.meta.env.PROD}
        wrap={(children) => <ErrorBoundary>{children}</ErrorBoundary>}
      >
        <FlagProvider config={unleashConfig}>
          <AlertContextProvider>
            <VersionAlert>
              <ConfirmModalContextProvider>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  {isSharedPage ? (
                    <NetworkInterceptor>
                      <Router history={history}>
                        <SharedPagesRouter />
                      </Router>
                    </NetworkInterceptor>
                  ) : isFdvuSubcontractor ? (
                    <NetworkInterceptor>
                      <Router history={history}>
                        <FdvuSubcontractorRouter />
                      </Router>
                    </NetworkInterceptor>
                  ) : isAuthenticated ? (
                    <NetworkInterceptor>
                      <Router history={history}>
                        <QueryParamProvider
                          adapter={ReactRouter5Adapter}
                          options={{
                            enableBatching: true,
                          }}
                        >
                          <MainRouter />
                        </QueryParamProvider>
                      </Router>
                    </NetworkInterceptor>
                  ) : (
                    !isLoading &&
                    !isLoadingSubcontractorAuthentication && (
                      <>
                        {ResolutionHandler.shouldShowMobileVersion() ? (
                          <MLogin />
                        ) : (
                          <Login />
                        )}
                      </>
                    )
                  )}
                </QueryClientProvider>
              </ConfirmModalContextProvider>
            </VersionAlert>
            <AlertContainer />
          </AlertContextProvider>
        </FlagProvider>
      </ConditionalWrap>
    </React.Fragment>
  )
}

export default App
