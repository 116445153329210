import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import { capitalize, noop } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WagonTypeColumn } from 'src/components/TableColumns/Columns'
import { DisciplineColumnWithOutEditing } from 'src/components/TableColumns/DisciplineResponsibleColumns'
import ConstructionTaskTypeForm from 'src/components/construction/task_types/ConstructionTaskTypeForm'
import ConstructionTaskTypeInspectorPanel from 'src/components/construction/task_types/ConstructionTaskTypeInspectorPanel'
import useProjectId from 'src/components/hooks/useProjectId'
import { constructionTaskTypeImports } from 'src/export-templates/ConstructionTaskTypeImports'
import { queryClient } from 'src/query/client'
import {
  useBulkDeleteConstructiontTaskTypes,
  useFilteredConstructionTaskTypes,
} from 'src/query/construction/queries'
import { IConstructionTaskType, IImportItemList } from 'src/service/OrgTypes'
import { taskUnits } from 'src/service/SystemValues'
import {
  dateColumn,
  numberColumn,
  textColumn,
} from 'src/ui-elements/Table/Columns'
import TableBody from 'src/ui-elements/Table/TableBody'
import TableButtons from 'src/ui-elements/Table/TableButtons'
import useTable from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { filterType } from 'src/ui-elements/list/ListTypes'

const ConstructionTaskTypeTable = ({
  onSelect,
  selectionState,
  highlightedRows,
}: {
  onSelect: (state: RowSelectionState) => void
  selectionState?: RowSelectionState
  highlightedRows?: number[]
}) => {
  const { t } = useTranslation()
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState<
    IConstructionTaskType | undefined
  >(undefined)
  const [tasks, setTasks] = useState<IConstructionTaskType[]>([])

  const projectId = useProjectId()
  const bulkDelete = useBulkDeleteConstructiontTaskTypes()

  const legacyColumns = [
    DisciplineColumnWithOutEditing(),
    {
      name: 'unit',
      size: '150',
      id: 'unit',
      dataField: 'unit',
      sortingField: 'unit',
      filterType: filterType.DEFAULT,
      filter: [
        {
          value: 'm_2',
          name: 'm2',
          active: false,
        },
        {
          value: 'm_3',
          name: 'm3',
          active: false,
        },
        {
          value: 'M',
          name: 'm',
          active: false,
        },
        {
          value: 'count',
          name: 'Stk',
          active: false,
        },
      ],
      cell: (unit: string) => {
        const taskunit = taskUnits(t).find((tu) => tu.id === unit)
        return <span className="px-1">{taskunit ? taskunit.name : ''}</span>
      },
    },
    WagonTypeColumn(),
  ]

  const newColumns: ColumnDef<any>[] = [
    textColumn('title', { name: capitalize(t('title')) }),
    numberColumn('total_units', { name: t('total_units') }),
    numberColumn('hours_per_unit', { name: t('hours_per_unit') }),
    numberColumn('productivity', { name: t('productivity') }),
    numberColumn('total_working_hours', { name: t('total_working_hours') }),
    numberColumn('effective_working_hours', {
      name: t('effective_working_hours'),
    }),
    numberColumn('duration_in_hours', { name: t('duration_in_hours') }),
    numberColumn('number_of_workers', { name: t('number_of_workers') }),
    dateColumn('created_at', { name: capitalize(t('created_at')) }),
    dateColumn('updated_at', { name: capitalize(t('updated_at')) }),
  ]

  const { page, pageSize, filters, sorting, table } = useTable({
    name: 'projectConstructionTaskTypes',
    columns: newColumns,
    data: tasks,
    legacyColumns,
    onRowClick: noop,
    onPreviewClick: (row) => setSelectedActivity(row),
    onSelect,
    selectionState,
    highlightedRows,
  })
  const { data } = useFilteredConstructionTaskTypes({
    sort: sorting,
    filters,
    page,
    pageSize,
  })
  useEffect(() => {
    if (data) setTasks(data.tasks)
  }, [data])

  const constructionTaskTypeImport: IImportItemList = {
    title: t('upload_activity_template'),
    templateJson: constructionTaskTypeImports,
    type: 'construction_task_type',
    reload: () =>
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskType'],
      }),
  }

  return (
    <>
      <TableButtons
        filters={filters}
        table={table}
        onBulkDelete={(ids) => bulkDelete.mutate(ids as number[])}
        importItem={constructionTaskTypeImport}
        customButtons={[
          <Button
            key="create"
            onClick={() => setShowCreateForm(true)}
            type={ButtonType.PRIMARY}
          >
            {capitalize(t('create_new_activity'))}
          </Button>,
        ]}
      />
      <TableBody table={table} pageCount={data?.pages ?? 0} totalItems={100} />
      {showCreateForm && (
        <ConstructionTaskTypeForm
          open={showCreateForm}
          isEditing={false}
          closeModal={() => setShowCreateForm(false)}
          updateList={noop}
        />
      )}
      {selectedActivity && (
        <ConstructionTaskTypeInspectorPanel
          open={!!selectedActivity}
          onClose={() => {
            setSelectedActivity(undefined)
          }}
          data={selectedActivity}
          projectId={projectId}
          onUpdate={noop}
        />
      )}
    </>
  )
}

export default ConstructionTaskTypeTable
