import { useState, useEffect, BaseSyntheticEvent } from 'react'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import Selector from 'src/components/selectors/Selector'
import SystemTypeFieldsGroup from 'src/components/system/system-type-group/SystemTypeFieldsGroup'
import { useSystemTypeGroups } from 'src/query/systemTypeGroups'
import { useSystemType } from 'src/query/systems/systemTypes'
import { ISystemType } from 'src/service/OrgTypes'
import {
  createSystemType,
  editSystemType,
  massEditSystemType,
  validateSystemType,
} from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'

export interface ISystemTypeFormProps {
  projectId?: number
  systemSettingId: number
  categoryId?: number
  categoryCode?: string
  systemTypeCodeRegex?: string
  closeModal: (success?: boolean) => void
  show: boolean
  systemTypeId?: number
  systemTypeIds?: number[]
  onSubmitSelect?: (data: number[]) => void
  typeCode?: string
}

const SystemTypeForm = ({
  systemSettingId,
  categoryId,
  categoryCode,
  closeModal,
  show,
  systemTypeId,
  systemTypeIds,
  typeCode,
}: ISystemTypeFormProps) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const [createMultiple, setCreateMultiple] = useState(false)

  const { data: systemType } = useSystemType(systemTypeId)
  const { data: systemTypeGroups = [] } = useSystemTypeGroups('System')

  const { control, handleSubmit, reset, setValue } = useForm<FieldValues>({
    defaultValues: { type_code: typeCode },
  })

  useEffect(() => {
    if (!systemType) return
    reset({ ...systemType })
  }, [reset, systemType])

  const submit = (systemType: ISystemType, e: BaseSyntheticEvent) => {
    e.preventDefault()
    if (systemTypeId)
      editSystemType({ ...systemType, id: systemTypeId }).then(() => {
        closeModal()
      })
    else if (systemTypeIds)
      massEditSystemType(projectId, systemTypeIds, systemType).then(() => {
        closeModal()
      })
    else
      createSystemType(projectId, systemSettingId, {
        ...systemType,
        system_setting_id: systemSettingId,
        project_id: projectId,
        system_syntax_category_id: categoryId,
      }).then(() => {
        if (createMultiple) {
          reset()
        } else {
          closeModal(true)
        }
      })
  }

  const validate = async (_: string, formValues: FieldValues) => {
    if (!categoryId) return
    const { type_code, name } = formValues
    if (type_code === '' || name === '') return false
    const systemType = {
      name,
      type_code,
      system_syntax_category_id: categoryId,
    }
    const res = await validateSystemType(projectId, systemSettingId, systemType)
    if (!res.system_type_valid) {
      return t('invalid_object_type_code_for_category', {
        category: categoryCode,
      })
    }
    if (res.system_type_exists) {
      return t('already_exists')
    }
    return undefined
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  return (
    <Modal
      show={show}
      title={
        systemTypeIds
          ? t('edit_multiple_system_type')
          : systemTypeId
            ? t('edit_system_type')
            : t('add_system_type')
      }
      closeModal={() => closeModal()}
      size={'w-3/4'}
      maxWidth={800}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          name={'name'}
          control={control}
          rules={
            !systemTypeIds?.length
              ? {
                  required: t('fill_out_w_param', {
                    param: t('title').toLowerCase(),
                  }),
                }
              : undefined
          }
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              block
              required={!systemTypeIds?.length}
              label={t('name')}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              errorMessage={error?.message}
            />
          )}
        />
        <>
          <div className={'w-full flex row pt-1'}>
            {!systemTypeIds && (
              <Controller
                name={'type_code'}
                control={control}
                disabled={!!systemTypeId}
                rules={{
                  required: t('fill_out_w_param', {
                    param: t('type_code').toLowerCase(),
                  }),
                  validate: {
                    validateSystemType: validate,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    block
                    required
                    label={t('type_code')}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    errorMessage={error?.message}
                    disabled={!!systemTypeId || !!typeCode}
                  />
                )}
              />
            )}
            <Controller
              name={'system_type_group_id'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Selector
                  items={systemTypeGroups}
                  selectedItemId={value}
                  onSelect={(id) => {
                    onChange(id)
                    const systemTypeGroup = systemTypeGroups.find(
                      (group) => group.id === id,
                    )
                    setValue(
                      'optional_fields',
                      systemTypeGroup?.optional_fields,
                    )
                  }}
                  label={t('system_type_metadata')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  cancelButton
                  onCancel={() => {
                    onChange(null)
                    setValue('optional_fields', [])
                  }}
                />
              )}
            />
          </div>
          <Controller
            name={'description'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Textarea
                block
                label={t('description')}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                errorMessage={error?.message}
              />
            )}
          />
          <SystemTypeFieldsGroup control={control} field={'optional_fields'} />
        </>

        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={() => closeModal()}>
            {t('cancel')}
          </Button>
          {systemTypeId || systemTypeIds ? (
            <Button type={Button.ButtonType.PRIMARY}>{t('edit')}</Button>
          ) : (
            <>
              {!typeCode && (
                <Button
                  type={Button.ButtonType.SECONDARY}
                  onClick={onMultipleCreate}
                >
                  {t('add_multiple')}
                </Button>
              )}
              <Button type={Button.ButtonType.PRIMARY} onClick={onSingleCreate}>
                {t('add')}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SystemTypeForm
