import * as React from 'react'
import { useEffect, useMemo, useState, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CheckBox from 'src/components/switchHoc/CheckBox'
import FolderMetadataRow from 'src/document/components/Folders/CreateFolderModal/FolderMetadataRow'
import { BASE_METADATA_TYPES } from 'src/document/types/FolderMetadataTypes'
import { IMetaData } from 'src/document/types/IMetaData'
import { validateUserDefinedFieldsName } from 'src/service/SystemService'
import {
  createSystemTypeField,
  editSystemTypeField,
  getSystemTypeField,
} from 'src/service/SystemTypeFieldService'
import Button from 'src/ui-elements/button/Button'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { getRandomId } from 'src/utility/getRandomId'

interface ISystemTypeFieldProps {
  projectId: number
  open: boolean
  closeModal: () => void
  editingMode: boolean
  systemTypeFieldId?: number
  domain: string
}

export interface IMetaField {
  id?: number
  name: string
  data_type: BASE_METADATA_TYPES
  pattern: string[] | string
  is_required: boolean
  project_id?: number
  sequence?: number
  domain?: string
  parent_type?: string
  parent_id?: number
  default_value?: string | number | boolean | null
  default_value_list?: string[] | null
  data_value_list?: string[] | null
  set_default_values_on_all?: boolean
  set_default_values_on_empty_values?: boolean
}

const SystemTypeFieldForm: React.FC<ISystemTypeFieldProps> = ({
  projectId,
  open,
  closeModal,
  editingMode,
  systemTypeFieldId,
  domain,
}) => {
  const { t } = useTranslation()
  const [fieldData, setFieldData] = useState<IMetaData>({
    id: getRandomId(),
    name: '',
    pattern: [BASE_METADATA_TYPES.string],
    data_type: BASE_METADATA_TYPES.string,
    is_required: false,
    readableNameKey: t(BASE_METADATA_TYPES.string),
    default_value: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [initialName, setInitialName] = useState('')
  const [loading, setLoading] = useState(false)
  const [createMultiple, setCreateMultiple] = useState(false)
  const [resetAllFieldValues, setResetAllFieldValues] = useState(false)
  const [resetEmptyFieldValues, setResetEmptyFieldValues] = useState(false)

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  useEffect(() => {
    if (systemTypeFieldId) {
      getSystemTypeField(systemTypeFieldId).then((res: IMetaField) => {
        setFieldData({
          id: getRandomId(),
          name: res.name,
          pattern: res.pattern,
          isPatternArray: res.data_type === BASE_METADATA_TYPES.enum,
          data_type: res.data_type,
          is_required: res.is_required ?? false,
          readableNameKey: t(res.data_type),
          default_value: res.default_value,
          default_value_list: res.default_value_list,
          domain: res.domain,
        })
        setInitialName(res.name ?? '')
        setLoading(false)
      })
    }
  }, [projectId, systemTypeFieldId, t])

  const updateMetaData = (id: string, newMetaData: Partial<IMetaData>) => {
    if (
      newMetaData.name &&
      (!systemTypeFieldId ||
        (newMetaData.name || fieldData.name).trimEnd() !== initialName)
    )
      validateUserDefinedFieldsName(
        projectId,
        newMetaData.name || fieldData.name,
      ).then((res) => {
        if (res.user_defined_field !== null) {
          setErrorMessage(t('name_is_already_in_use'))
        } else {
          setErrorMessage('')
        }
      })

    const mergedMetadata: IMetaData = {
      name: fieldData.name,
      data_type: fieldData.data_type,
      pattern: fieldData.pattern,
      is_required: false,
      default_value: fieldData.default_value,
      domain,
      ...newMetaData,
      id,
    }
    setFieldData(mergedMetadata)
  }

  const submitSystemTypeField = async () => {
    if (fieldData) {
      const data: Omit<IMetaField, 'id'> = {
        name: fieldData.name,
        pattern: fieldData.pattern,
        data_type: fieldData.data_type,
        is_required: fieldData.is_required,
        project_id: projectId,
        default_value:
          fieldData.default_value != null ? `${fieldData.default_value}` : null,
        default_value_list: fieldData.default_value_list,
        domain: fieldData.domain,
      }

      if (systemTypeFieldId) {
        const updateData: IMetaField = {
          ...data,
          id: systemTypeFieldId,
          set_default_values_on_all: resetAllFieldValues,
          set_default_values_on_empty_values: resetEmptyFieldValues,
        }
        editSystemTypeField(updateData).then(() => {
          closeModal()
        })
      } else {
        createSystemTypeField(projectId, data).then(() => {
          if (!createMultiple) {
            closeModal()
          } else {
            updateMetaData(fieldData.id, { name: '' })
          }
        })
      }
    }
  }

  const onSubmit = (e: FormEvent) => {
    let error = false
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)

    if (!fieldData?.name?.length) {
      setErrorMessage(t('name_is_required'))
      error = true
    }

    if (fieldData?.name.match(/[\[\]:]/g)) {
      setErrorMessage(t('a_meta_data_field_can_not_contain_[]_or_:'))
      error = true
    }

    if (!error && !errorMessage) {
      submitSystemTypeField()
    }

    setLoading(false)
  }

  const ModalRowStyleClass = useMemo(() => {
    const largerMargin =
      fieldData.data_type === BASE_METADATA_TYPES.enum ||
      fieldData.data_type === BASE_METADATA_TYPES.multiEnum
    return largerMargin ? 'mt-10' : 'mt-4'
  }, [fieldData])

  return (
    <Modal
      show={open}
      closeModal={closeModal}
      title={
        editingMode ? t('edit_user_defined_field') : t('add_user_defined_field')
      }
      height={'h-auto'}
      maxWidth={800}
    >
      <form className={'w-full'} onSubmit={onSubmit}>
        <div className="flex">
          {errorMessage && (
            <span
              className={twMerge(
                'items-center',
                'text-red-600',
                'ml-2',
                'text-sm',
                'font-normal',
              )}
            >
              {errorMessage}
            </span>
          )}
        </div>

        <FolderMetadataRow
          field={fieldData}
          updateMetadata={updateMetaData}
          metaFieldMenuOnTheLeft={true}
          disabled={false}
        />

        {editingMode && (
          <div className={'flex w-full mt-2'}>
            <div className={'w-1/2 pl-2 flex items-center'}>
              <CheckBox
                disable={resetAllFieldValues}
                onChange={(item) => setResetEmptyFieldValues(item)}
                valueProp={resetAllFieldValues}
                id={'set_default_values_on_empty_values'}
              />
              <label
                className={
                  'ml-2 text-black font-normal text-sm first-capitalize'
                }
                htmlFor={'set_default_values_on_empty_values'}
              >
                {t('set_default_values_on_empty_values')}
              </label>
            </div>

            <div className={'w-1/2 pl-2 flex items-center'}>
              <CheckBox
                onChange={(item) => setResetAllFieldValues(item)}
                valueProp={resetAllFieldValues}
                id={'set_default_values_for_all'}
              />
              <label
                className={
                  'ml-2 text-black font-normal text-sm first-capitalize'
                }
                htmlFor={'set_default_values_for_all'}
              >
                {t('set_default_values_on_all')}
              </label>
            </div>
          </div>
        )}
        {resetAllFieldValues && (
          <p className="text-red text-sm p-2 mt-2">
            {t('are_you_sure_you_want_to_reset_all_values')}
          </p>
        )}

        <ModalFooter className={ModalRowStyleClass}>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={closeModal}
            disabled={loading}
          >
            {loading ? <Spinner /> : t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={onMultipleCreate}
            disabled={loading}
          >
            {loading ? <Spinner /> : t('add_multiple')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={onSingleCreate}
            disabled={loading}
          >
            {loading ? <Spinner /> : editingMode ? t('update') : t('add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SystemTypeFieldForm
