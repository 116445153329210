import Delete from '@icons/delete.svg'
import Download from '@icons/download.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IProjectFile } from 'src/document/types/IFileContainer'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import FdvuSubcontractorService from 'src/subcontractor/service/SubcontractorService'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface IFileList {
  refreshFileContainer: () => void
  files: IProjectFile[]
  disableDeleteFile?: boolean
}

const FileList: FC<IFileList> = ({
  refreshFileContainer,
  files,
  disableDeleteFile = false,
}) => {
  const { t } = useTranslation()

  const downloadFile = async (projectFileId: number, fileName: string) => {
    const data = await FdvuSubcontractorService.downloadFile(projectFileId)
    const binaryData = []
    binaryData.push(data)
    const blobUrl = window.URL.createObjectURL(new Blob(binaryData))
    const link = document.createElement('a')
    link.onclick = (e) => {
      e.stopImmediatePropagation()
    }
    link.href = blobUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const { confirmDelete } = useDeleteModal()

  const deleteFile = async (file: IProjectFile) => {
    const deleteConfirmed = await confirmDelete({
      itemIdnType: t('file'),
      itemName: file.name,
    })
    if (deleteConfirmed) {
      await FdvuSubcontractorService.deleteFile(file.id)
      refreshFileContainer()
    }
  }

  return (
    <div className="p-2 m-4 flex flex-col text-sm">
      <div className={'font-semibold mb-2'}>{`${t('files').toUpperCase()} (${
        files.length
      })`}</div>
      <div>
        {files.map((file) => {
          const fileType =
            file.name?.split('.').pop() || file.lastest_version?.content_type
          return (
            <div key={file.id} className={'flex justify-between items-center'}>
              <p className="text-black mb-1 truncate">{file.name}</p>
              <div className="flex my-1">
                <Button
                  type={ButtonType.SECONDARY}
                  size={ButtonSize.NARROW}
                  toolTipText={t('download_file')}
                  onClick={() => downloadFile(file.id, file.name)}
                >
                  <div className="flex items-center gap-1 text-xs leading-3">
                    <Download className={'fill-blue-root'} />
                    {fileType}
                  </div>
                </Button>
                <Button
                  type={ButtonType.SECONDARY}
                  size={ButtonSize.NARROW}
                  toolTipText={t('delete_file')}
                  disabled={disableDeleteFile}
                  onClick={() => deleteFile(file)}
                >
                  <Delete className={'fill-red'} />
                </Button>
              </div>
            </div>
          )
        })}
      </div>
      {files.length === 0 && (
        <div className="text-sm italic flex justify-center">
          <p>{t('there_are_no_files_uploaded_for_this_requirement')}</p>
        </div>
      )}
    </div>
  )
}

export default FileList
