import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectUserDefinedField } from 'src/service/SystemTypeFieldService'

export const useProjectUserDefinedFields = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['userDefinedFields', projectId],
    queryFn: () => getProjectUserDefinedField(projectId),
  })
}
