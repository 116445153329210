import { useQuery } from '@tanstack/react-query'
import { getSingleSystemSyntaxCategories } from '../../service/SystemSyntaxService'

export const useSystemSetting = (systemSettingId?: number) => {
  return useQuery({
    queryKey: ['systemSetting', systemSettingId],
    queryFn: async () =>
      systemSettingId
        ? getSingleSystemSyntaxCategories(systemSettingId)
        : undefined,
  })
}
