import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportContext } from 'src/context/ExportContext/ExportContext'
import { ExportTemplate } from 'src/context/ExportContext/ExportTypes'
import usePlanExport from 'src/export-templates/PlanningMilestoneExport'
import ExportService from 'src/service/ExportService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

const PlanningExport = () => {
  const { t } = useTranslation()
  const { actions: exportActions } = useContext(ExportContext)

  const { isLoading, milestoneExport, keypointExport } = usePlanExport()

  const reloadExport = exportActions.addPendingExport

  const exportExcel = async (milestone: boolean) => {
    const res = await ExportService.exportToExcel(
      t,
      (milestone ? milestoneExport : keypointExport) as ExportTemplate,
      [],
      {},
    )
    reloadExport(res.value.uuId)
  }

  return (
    <div className="flex flex-col gap-4 w-1/2 p-4">
      <Button
        onClick={() => exportExcel(true)}
        disabled={isLoading}
        className="w-1/3 h-10"
        type={ButtonType.PRIMARY}
      >
        {t('milestone_export')}
      </Button>
      <Button
        onClick={() => exportExcel(false)}
        disabled={isLoading}
        className="w-1/3 h-10"
        type={ButtonType.PRIMARY}
      >
        {t('keypoint_export')}
      </Button>
    </div>
  )
}

export default PlanningExport
