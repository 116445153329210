import * as React from 'react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from '../../../../components/click-outside/CloseClickOutside'
import { useMultipleKeysTranslation } from '../../../../service/useMultipleKeysTranslation'
import {
  IMetadataBaseType,
  IMetaDataType,
} from '../../../types/FolderMetadataTypes'
import FolderMetadataFieldDropdown from './FolderMetadataFieldDropdown'

interface IFolderMetadataField {
  value: IMetaDataType
  disabled?: boolean
  onTypeChange?: (newType: IMetaDataType) => void
  possibleMetadataTypes: Record<string, IMetadataBaseType>
  menuOnTheLeft?: boolean
}

const FolderMetadataField: React.FC<IFolderMetadataField> = ({
  value,
  disabled,
  onTypeChange,
  menuOnTheLeft,
  possibleMetadataTypes,
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const { t } = useMultipleKeysTranslation()

  const onClick = () => {
    if (disabled) return
    setShowMenu(true)
  }

  const onTypeSelected = (newType: IMetaDataType) => {
    if (onTypeChange) onTypeChange(newType)
    setShowMenu(false)
  }

  return (
    <>
      <div
        onClick={!disabled ? onClick : undefined}
        className={twMerge(
          !disabled && 'cursor-pointer',
          !!disabled && 'bg-[#efefef] cursor-not-allowed',
          'text-sm leading-4',
          'border border-gray-300 rounded',
          'p-2',
          'mr-2',
          'content-center',
        )}
      >
        {t(value.data_type)}
      </div>
      {showMenu && (
        <CloseClickOutside onClose={() => setShowMenu(false)}>
          <div className="relative ml-10">
            <FolderMetadataFieldDropdown
              possibleMetadataTypes={possibleMetadataTypes}
              menuOnTheLeft={menuOnTheLeft}
              value={value}
              onClick={onTypeSelected}
            />
          </div>
        </CloseClickOutside>
      )}
    </>
  )
}

export default FolderMetadataField
