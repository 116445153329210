import { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'

interface ISyntaxSearchFilterSelector {
  data: string[]
  isLoading: boolean
  onSearch: (value: string) => void
  onSelect: (value: string) => void
}
const AsyncSelector: FC<ISyntaxSearchFilterSelector> = ({
  data,
  isLoading,
  onSearch,
  onSelect,
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <CloseClickOutside onClose={() => setIsOpen(false)} className={'relative'}>
      <input
        onChange={(e) => onSearch(e.target.value)}
        className="w-28 py-1 px-2 border border-grey-300 rounded"
        placeholder={`${t('search')}...`}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen && (
        <div className="bg-white border border-grey-300 overflow-y-auto overflow-x-hidden absolute z-30 max-h-48">
          {data.length === 0 || isLoading ? (
            <div className={'w-28 pl-2 my-2 text-text-grey text-xs'}>
              {isLoading ? t('loading...') : t('no_results')}
            </div>
          ) : (
            data.map((item) => (
              <div
                key={item}
                className={
                  'w-28 pl-2 py-2 z-50 cursor-pointer hover:bg-gray-100'
                }
                onClick={() => {
                  onSelect(item)
                  setIsOpen(false)
                }}
              >
                {item}
              </div>
            ))
          )}
        </div>
      )}
    </CloseClickOutside>
  )
}
export default AsyncSelector
