import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import Button, { IButtonProps } from 'src/ui-elements/button/Button'
import { classNames } from 'src/utility/utils'

export interface IItemWithTwoActions {
  icon?: JSX.Element
  title: string
  active: boolean
  onClick?: () => void
  onClickIcon?: () => void
}

interface ButtonWithDropdownMenuProps extends Omit<IButtonProps, 'onClick'> {
  items: IItemWithTwoActions[]
  title: string
  showOnLeft?: boolean
  customStyling?: boolean
}
const ButtonWithDropdownMenu = ({
  items,
  showOnLeft,
  title,
  children,
  disabled,
}: ButtonWithDropdownMenuProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  return (
    <div>
      <Button
        disabled={disabled}
        title={title}
        type={Button.ButtonType.SECONDARY}
        className={'p-1 w-8 h-8'}
        onClick={(e) => {
          e.preventDefault()
          setMenuIsOpen(true)
        }}
      >
        {children}
      </Button>
      <CloseClickOutside onClose={() => setMenuIsOpen(false)}>
        <div
          className="relative text-gray-600"
          onClick={(e) => e.stopPropagation()}
        >
          {menuIsOpen && items.length !== 0 && (
            <div
              className={classNames(
                'z-40 absolute top-0 p-1 shadow-md rounded-md bg-white m-1',
                showOnLeft ? 'right-0' : 'left-0',
              )}
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  className={'flex flex-row items-center justify-between'}
                >
                  <div
                    className="hover:bg-blue-light-100 p-1 text-sm rounded-md my-0 pr-6 cursor-pointer whitespace-nowrap flex-1"
                    onClick={() => {
                      item.onClick && item.onClick()
                      setMenuIsOpen(false)
                    }}
                  >
                    <p
                      className={twMerge(
                        'first-capitalize',
                        item.active && 'text-task-blue',
                      )}
                    >
                      {item.title}
                    </p>
                  </div>
                  {item.icon && (
                    <div
                      onClick={() => {
                        item.onClickIcon && item.onClickIcon()
                      }}
                      className="p-1 flex-2 hover:bg-blue-light-100 rounded-md cursor-pointer"
                    >
                      {item.icon}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </CloseClickOutside>
    </div>
  )
}

export default ButtonWithDropdownMenu
