import { BASE_METADATA_TYPES } from 'src/document/types/FolderMetadataTypes'
import { ISingleFilter } from './ListContextProvider'

export type FilterValue = string | number | string[] | number[]
type UserDefinedFilter = {
  data_type: BASE_METADATA_TYPES
  name: string
  search_param: string[]
}[]

type ColumnFilter = {
  [key: string]: FilterValue | UserDefinedFilter | undefined
  user_defined_values?: UserDefinedFilter
}

export interface IListColumns {
  name: string
  size?: string
  id: string
  dataField: string
  filterFiled?: string
  dateFormat?: string
  cell?: (row: any, id?: number, key?: number) => JSX.Element
  sortingField?: string
  comparator?: (a: any, b: any, direction: boolean) => number
  filterType?: filterType
  filterCase?: filterCase
  filterDataField?: string
  filterDataValue?: string
  defaultFilter?: IDefaultFilter
  initialSorting?: boolean
  doNotExport?: boolean
  nestedDataField?: string
  filter?: ISingleFilter[]
  getFilter?: () => Promise<ISingleFilter[]>
  customIcon?: string | undefined
  onCustomIconClick?: (id: string) => void
  disabled?: boolean
  userDefinedFiled?: string
  userDefinedType?: string
  hidden?: boolean
  enableColumnFilter?: boolean
  enableSorting?: boolean

  /*
   * Specify this function to return an object that will be merged with
   * the filter struct before it is sent to the filter backend
   */
  processFilter?: (val: any) => ColumnFilter
}

export interface IDefaultFilter {
  filterType: filterType
  filterDataField: string
  filterDataValue: string
  activeFilter: string[]
}

export interface ISorting {
  field: string
  direction: boolean
  active: boolean
  comparator?: (a: any, b: any, direction: boolean) => number
  is_user_defined_field?: boolean
  data_type?: string
}

export enum filterType {
  DEFAULT = 'DEFAULT',
  RANGEDATE = 'RANGEDATE',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
}

export enum filterCase {
  ACTION_STATUS = 'ACTION_STATUS',
  ACTION_SOURCE = 'ACTION_SOURCE',
  ACTION_YES_NO = 'ACTION_YES_NO',
}

export interface IPaginatedData {
  currentPage: number
  perPage: number
  total: number
  totalPages: number
  data: any[]
}

export interface IListActionMenuItem {
  name: string
  action: (row: any, index?: number) => void
  type?: string
  icon?: string
}

export interface IColumnData {
  name: string
  size: number
  dataField: string
  sortingField: string
  sorting: boolean
  filterType?: filterType
  comparator?: (a: any, b: any, direction: boolean) => number
}

export interface ISelectType {
  rowSelected: boolean
  isHeader: boolean
  rowData: any
}
