import { useState, useEffect, useRef } from 'react'
import useKeyPress from './UseKeyEvent'

const useShortcut = (keys: string[], callback: () => void) => {
  const [pressedKeys, setPressedKeys] = useState<string[]>([])
  const called = useRef(false)

  useKeyPress((key) => {
    setPressedKeys((prevKeys) => {
      if (!prevKeys.includes(key)) {
        return [...prevKeys, key]
      }
      return prevKeys
    })
  })

  useEffect(() => {
    const upHandler = ({ key }: KeyboardEvent) => {
      setPressedKeys((prevKeys) => prevKeys.filter((k) => k !== key))
    }

    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  useEffect(() => {
    if (keys.every((key) => pressedKeys.includes(key)) && !called.current) {
      callback()
      called.current = true
    }

    if (pressedKeys.length === 0) {
      called.current = false
    }
  }, [pressedKeys, keys, callback])
}

export default useShortcut
